import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#2A2044",
    },
  },
  typography: {
    h1: {
      fontFamily: ["PlusJakartaSans-Bold", "serif"].join(","),
      fontSize: "40px",
      color: "#000000",
      fontWeight: "700",
      fontStyle: "normal",
    },
    h2: {
      fontFamily: ["PlusJakartaSans-Medium", "serif"].join(","),
      color: "#000000",
      fontSize: "24px",
      fontWeight: "550",
      fontStyle: "normal",
      textTransform: "none",
    },
    h3: {
      fontFamily: ["PlusJakartaSans-Bold", "serif"].join(","),
      color: "#000000",
      fontSize: "20px",
      fontStyle: "normal",
      textTransform: "none",
    },
    body1: {
      color: "#000000",
      fontSize: "18px",
      fontWeight: "400",
      fontStyle: "normal",
      textTransform: "none",
    },

    button: {
      fontFamily: ["PlusJakartaSans-bold", "serif"].join(","),
      fontSize: "18px",
      color: "white",
    },
    fontFamily: ["PlusJakartaSans-Regular", "serif"].join(","),
  },
});

theme = responsiveFontSizes(theme);

export default theme;
