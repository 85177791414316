import {
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import Footer from "../Footer/Footer";
import theme from "./Theme";

function TermsOfUse() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ paddingTop: "150px", pb: 6 }}>
        <Container align="left">
          <Typography variant="h1" gutterBottom>
            Terms of Use
          </Typography>
          <Typography sx={{ pb: 3 }} variant="body1" gutterBottom>
            Welcome to Coligou!
          </Typography>
          <Typography sx={{ pb: 4 }} variant="h2" gutterBottom>
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING
            COLIGOU
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            What’s in these terms
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            These terms tell you the rules for using all services provided by
            Coligou, on behalf of our partner universities, either hosted on our
            website at www.Coligou.com, integrated via widgets on a university
            website or provided via the Coligou Mobile App.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Who we are and how to contact us
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            Coligou is a service operated by Unibuddy Ltd (“We”). We are a
            limited company registered in England and Wales under company number
            09790742 and have our registered office at 5 New Street Square,
            London, United Kingdom, EC4A 3TW. Our VAT number is 233436034. To
            contact us, please email{" "}
            <a href="mailto:hello@coligou.com">hello@coligou.com</a>.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            By using Coligou you accept these terms
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            By using Coligou, you confirm that you accept these terms of use and
            that you agree to comply with them. If you do not agree to these
            terms, you must not use Coligou. We recommend that you print a copy
            of these terms for future reference.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            There are other terms that may apply to you
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            These terms of use refer to the following additional terms, which
            also apply to your use of Coligou: Our{" "}
            <a href="/privacypolicy">Privacy Policy</a>, which sets out the
            terms on which we process any personal data we collect from you, or
            that you provide to us. By using Coligou, you consent to such
            processing and you warrant that all data provided by you is
            accurate. Our <a href="/usepolicy">Acceptable Use Policy</a>, which
            sets out the permitted uses and prohibited uses of Coligou. When
            using Coligou, you must comply with this Acceptable Use Policy.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            We may make changes to these terms
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            We amend these terms from time to time. Every time you wish to use
            Coligou, please check these terms to ensure you understand the terms
            that apply at that time.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            We may make changes to Coligou
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            We may update and change Coligou from time to time to reflect
            changes to Coligou, our users’ needs, our university partners’
            needs, and our business priorities. We will try to give you
            reasonable notice of any major changes that affect you.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            We may suspend or withdraw Coligou
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            Our site is made available free of charge.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We do not guarantee that Coligou, or any content on it, will always
            be available or be uninterrupted. We may suspend or withdraw or
            restrict the availability of all or any part of Coligou for business
            and operational reasons. We will try to give you reasonable notice
            of any suspension or withdrawal.
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            You are also responsible for ensuring that all persons who access
            Coligou through your internet connection are aware of these terms of
            use and other applicable terms and conditions, and that they comply
            with them.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Minimum User Age
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            Our Platform is only intended for use by persons above the age of
            consent in their respective jurisdiction. End Users are required to
            submit their date of birth for age verification purposes. Where
            there is a justifiable reason to use the Platform below the relevant
            age of consent, parental consent will be required for such use.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            You must keep your account details safe
          </Typography>
          <Typography variant="body1" gutterBottom>
            You will be asked to choose a username and password as part of our
            security procedures. You must treat that information as
            confidential. You must not disclose it to any third party.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We have the right to disable any user identification code or
            password, whether chosen by you or allocated by us, at any time, if
            in our reasonable opinion, you have failed to comply with any of the
            provisions of these terms of use.
          </Typography>

          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            If you know or suspect that anyone other than you knows your user
            identification code or password, you must promptly notify us at{" "}
            <a href="mailto:hello@coligou.com">hello@coligou.com</a>
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            How you may use material on Coligou
          </Typography>
          <Typography variant="body1" gutterBottom>
            We are the owner or the licensee of all intellectual property rights
            in Coligou, and in the material published on it (except for user’s
            contributions – intellectual property rights in those belongs to the
            user). Those works are protected by copyright laws and treaties
            around the world. All such rights are reserved.
          </Typography>
          <Typography variant="body1" gutterBottom>
            You must not use any part of the content on Coligou for commercial
            purposes without obtaining a licence to do so from us or our
            licensors.
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            If you copy or download any part of Coligou in breach of these terms
            of use, your right to use Coligou will cease immediately and you
            must, at our option, return or destroy any copies of the materials
            you have made.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Do not rely on information on this site
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            The information on Coligou is provided for general information only.
            It is not intended to amount to advice on which you should rely. You
            must develop your own views regarding a university, an undergraduate
            or graduate course of study, before taking or refraining from any
            action on the basis of information obtained from use of Coligou.
            Although we make reasonable efforts to update the information on
            Coligou, we make no representations, warranties or guarantees,
            whether express or implied, that the content on Coligou is accurate,
            complete or up to date.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            We are not responsible for other people’s websites
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            Where Coligou contains links to other sites and resources provided
            by third parties, these links are provided for your information
            only. Such links should not be interpreted as approval by us of
            those linked websites or information you may obtain from them. We
            have no control over the contents of those sites or resources. The
            applies to all services provided by Coligou, either hosted on our
            website at www.coligou.com, or integrated via widgets on a
            university website.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            User-generated content is not approved by us
          </Typography>
          <Typography variant="body1" gutterBottom>
            Coligou allows you to interact with other users. This information
            and these materials have not been verified or approved by us. The
            views expressed by other users on Coligou do not represent our views
            or values.
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            If you wish to complain about information and materials uploaded by
            other users please email{" "}
            <a href="mailto:hello@coligou.com">hello@coligou.com</a>.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Our responsibility for loss or damage suffered by you
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • We do not exclude or limit in any way our liability to you where
            it would be unlawful to do so. This includes liability for death or
            personal injury caused by our negligence or the negligence of our
            employees, agents or subcontractors and for fraud or fraudulent
            misrepresentation.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            • Please note that we only provide Coligou for domestic and private
            use. You agree not to use Coligou for any commercial or business
            purposes, and we have no liability to you for any loss of profit,
            loss of business, business interruption, or loss of business
            opportunity.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Interacting on Coligou
          </Typography>
          <Typography variant="body1" gutterBottom>
            Whenever you make use of a feature that allows you to post content
            to Coligou, or interact with other users of Coligou, you must comply
            with the content standards set out in our{" "}
            <a href="/usepolicy">Acceptable Use Policy</a>.
          </Typography>
          <Typography variant="body1" gutterBottom>
            You promise that any such contribution does comply with those
            standards, and you will be liable to us and compensate us for any
            loss we suffer if you break that promise.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Any content you upload to Coligou will be considered
            non-confidential and non-proprietary. You retain all of your
            ownership rights in your content, but you agree for us to use, store
            and copy that content and to distribute and make it available on
            behalf of our university partners.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We also have the right to disclose your identity to any third party
            who is claiming that any interaction or content posted or uploaded
            by you to Coligou constitutes a violation of their intellectual
            property rights, or of their right to privacy.
          </Typography>

          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            We have the right to remove any posting you make on Coligou if, in
            our opinion, your post does not comply with the content standards
            set out in our <a href="#/">Acceptable Use Policy</a>.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Rights you are giving us to use comments or contributions you make
            on Coligou
          </Typography>
          <Typography variant="body1" gutterBottom>
            When you interact with other users, or post comments or any other
            content to Coligou, you promise that your contribution is your own
            original work, and you grant us the following rights to use that
            contribution:
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • The University whose widget you have signed up to shall be
            entitled to disclose your name with any contribution.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • The University whose widget you signed up to will have access to
            your contribution and may use it for their own purposes including
            (if you have consented to it) sending you information about the
            university or course that may be of interest to you.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • We shall be entitled to undertake research and statistical
            analysis on your contributions, either for our own purposes or those
            of others.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • You agree that you waive all moral rights you may have in the
            contribution (including, the right to be identified as the author)
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            • Any personal data you supply with your contribution may be used as
            described in our Privacy Policy.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            You must not introduce malware onto Coligou
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            You must not misuse Coligou by knowingly introducing viruses,
            trojans, worms, logic bombs or other material that is malicious or
            technologically harmful. You must not attempt to gain unauthorised
            access to Coligou, the server on which Coligou is stored or any
            server, computer or database connected to Coligou. You must not
            attack Coligou via a denial-of-service attack or a distributed
            denial-of service attack. By breaching this provision, you would
            commit a criminal offence. We will report any such breach to the
            relevant law enforcement authorities and we will co-operate with
            those authorities by disclosing your identity to them. In the event
            of such a breach, your right to use Coligou will cease immediately.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Rules about linking to Coligou
          </Typography>
          <Typography variant="body1" gutterBottom>
            You may link to our home page, provided you do so in a way that is
            fair and legal and does not damage our reputation or take advantage
            of it. You must not establish a link in such a way as to suggest any
            form of association, approval or endorsement on our part where none
            exists.
          </Typography>
          <Typography variant="body1" gutterBottom>
            You must not establish a link to Coligou in any website that is not
            owned by you. Our site must not be framed on any other site, nor may
            you create a link to any part of Coligou other than the home page.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We reserve the right to withdraw linking permission without notice.
            The website in which you are linking must comply in all respects
            with the content standards set out in our{" "}
            <a href="/usepolicy">Acceptable Use Policy</a>.
          </Typography>

          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            If you wish to link to or make any use of content on Coligou other
            than that set out above, please email{" "}
            <a href="mailto:hello@coligou.com">hello@coligou.com</a>.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Which country’s laws apply to any disputes?
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you are a resident of a territory outside the United Kingdom,
            nothing in these terms will prevent you being entitled to any
            mandatory consumer protections applicable in the country where you
            live.
          </Typography>

          <Typography variant="body1" gutterBottom>
            If you are a consumer, please note that these terms of use, their
            subject matter and their formation, are governed by English law. You
            and we both agree that the courts of England and Wales will have
            exclusive jurisdiction except that if you are a resident of Northern
            Ireland you may also bring proceedings in Northern Ireland, and if
            you are resident of Scotland, you may also bring proceedings in
            Scotland.
          </Typography>

          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            If you are a business, these terms of use, their subject matter and
            their formation (and any non-contractual disputes or claims) are
            governed by English law. We both agree to the exclusive jurisdiction
            of the courts of England and Wales.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Our trade marks are registered
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            “Coligou” and the Coligou “C” logo are trade marks of Coligou. You
            are not authorised to use them without our approval.
          </Typography>
        </Container>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default TermsOfUse;
