import {
  Box,
  Container,
  CssBaseline,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import theme from "./Theme";

function createData(category, desc, resp, reso) {
  return { category, desc, resp, reso };
}

const rows = [
  createData(
    "For an incident logged outside of Normal Business Hours:",
    "",
    "",
    ""
  ),
  createData(
    "Priority 1",
    "Showstopper, significant business or user impact",
    "1 hour within next Business Day",
    "4 hours in next Business Day"
  ),
  createData(
    "Priority 2",
    "High priority, impacting effective use of the Platform for a significant number of users",
    "1 hour in next Business Day",
    "1 Business Day"
  ),
  createData("For an incident logged in Normal Business Hours:", "", "", ""),
  createData(
    "Priority 1",
    "Showstopper, significant business or user impact",
    "1 Normal Business Hour",
    "4 Normal Business Hours"
  ),
  createData(
    "Priority 2",
    "High priority, impacting effective use of the Platform for a significant number of users",
    "1 Normal Business Hour",
    "1 Business Day"
  ),
  createData(
    "Priority 3",
    "Low priority, Platform not functioning as expected, but not significantly affecting use",
    "2 Normal Business Hours",
    "5 Business Day"
  ),
];

function PlatformLicenceAgree() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ paddingTop: "150px", pb: 6 }}>
        <Container align="left">
          <Typography textAlign="center" variant="h1" gutterBottom>
            Coligou Platform Licence Agreement
          </Typography>
          <Typography sx={{ pt: 4, pb: 3 }} variant="h3" gutterBottom>
            BACKGROUND
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            A. The Company carries on the business of, amongst other things,
            developing and providing access to its engagement Platform (which
            enables potential university applicants to get matched to courses
            most suitable to them, check their eligibility for courses, browse
            content, shortlist and apply to courses, and communicate directly
            with current university students and university staff).
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            B. The Customer is a higher education provider and wishes to offer
            the Company’s engagement Platform to potential applicants on its
            website.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            C. The Customer wishes to obtain, and the Company has agreed to
            grant the Customer, a non-exclusive right to use the Company’s
            engagement Platform on and subject to the terms and conditions of
            this agreement
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            1. DEFINITIONS AND INTERPRETATION
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            1.1. The definitions and rules of interpretation in this clause
            apply in this agreement.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Acceptable Use Policy:</b> the Company’s acceptable use policy:
            <a href=" https://coligou.com/acceptable-use-policy/">
              Click here
            </a>{" "}
            (as amended from time to time).
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Aggregate Data</b>: means the anonymised usage data, which is
            derived from the Student Counsellors’ and/or End Users’ use of the
            Platform, including questions and answers derived from application
            forms, browsing history, conversation history, and expressly
            excluding any Student Ambassadors’ and/or End Users’ personal data.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Ambassador</b>: means a person acting on behalf of a Coligou
            Customer as its representative and interacts with End Users, this
            could include Customer Staff.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>API</b>: the Company’s application programming interface that
            enables the Customer to integrate the Company’s cloud hosted
            engagement platform with the Customer Website.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Business Day</b>: a day other than a Saturday, Sunday or public
            holiday in England when banks in London are open for business.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Chat</b>: an application which embeds directly into the
            Customer’s website via an iframe, enabling End Users to get matched
            and browse for Courses, Video Content, Counsellors, Ambassadors
            or/and Customer Staff, only in the instances where applicable, and
            contact them via direct messaging.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Claims</b>: means all third party claims, actions, liabilities,
            losses, expenses, damages and costs (including, without limitation,
            reasonable attorneys’ fees).
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Cookie Policy</b>: the Company’s cookie policy:
            <a href="https://coligou.com/cookie-policy/">Click here</a>(as
            amended from time to time).
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Coligou Spotlight</b>: a tool that is designed to show students
            the content including articles, videos and posts based on course
            match and personality.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Coligou Match</b>: student engagement tool that matches students
            to courses based on their individual characteristics and interests.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Company Trademarks</b>: the trademarks and trade names owned by
            and/or licensed to the Company from time to time.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            Company Website:{" "}
            <a href="https://coligou.com/">https://coligou.com/</a>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Confidential Information</b>: information that is proprietary or
            confidential and is: (a) clearly labelled as such; (b) otherwise
            identified as Confidential Information; or (c) information that
            should be reasonably understood to be confidential given the nature
            of the information and the circumstances surrounding its disclosure.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Counsellor</b>: means any assigned staff team from Coligou’s team
            acting on behalf of a Coligou Customer as its representative and
            interacts with End Users
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Courses</b>: means all academic courses and programs provided by
            the Customer to the Company
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Customer Content</b>: means all content created by the Customer,
            including Customer Staff, in the course of its use of the Platform.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Customer Staff</b>: means any employee or authorised
            representative of the Customer who has access to the Coligou
            Platform.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b> Customer Trademarks</b>: the Trademarks and trade names owned by
            and/or licensed to the Customer from time to time.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Customer Website</b>: means that specific website that the
            Company has expressly agreed can integrate the Platform via the API
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Dashboard</b>: the online portal which displays, among other
            things, data about the End User’s use of the platform, to which the
            Customer may login. Additional licensable features include a
            multi-Instance view which enables visibility of data across multiple
            dashboards.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>End User</b>: means any category of individual that has access to
            or engages with the Customer via the Coligou Platform including but
            not limited to prospective students, Ambassadors, administrators and
            other Customer Staff
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Extended Term</b>: means the 12 month period(s) subsequent to the
            expiration of the Initial Term.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Feedback</b>: means all suggestions, solutions, improvements,
            corrections and other contributions provided by the Customer to the
            Company regarding the Company Intellectual Property.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Fees</b>: the fees for use of the Platform and Products
            calculated in accordance with the Order Form.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Initial Term</b>: means the term commencing on the Effective Date
            and continuing until the Renewal Date specified within the Order
            Form.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Instance</b>: means a single licensed asset location of the
            Platform licensed to the Customer where usage statistics including
            Aggregate Data are displayed.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Intellectual Property Rights</b>: patents, rights to inventions,
            copyright and related rights, trademarks and service marks, business
            names and domain names, trade dress, rights in designs, database
            rights, concepts, designs, logos, names, techniques, technologies,
            processes, methods, inventions, products, works of authorship,
            discoveries, developments, source code, object code, technical
            information, other programming code, algorithms, innovations,
            improvements, and other proprietary property of a party of any kind,
            whether tangible or intangible, whether in written or other form,
            rights in know-hows and trade secrets and all other intellectual
            property rights, in each case whether registered or unregistered and
            including all applications renewals or extensions of, and rights to
            claim priority from, such rights and all similar or equivalent
            rights or forms of protection which subsist or will subsist now or
            in the future in any part of the world.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Legislation</b>: any law, rule, regulation, ordinance, statute,
            or any mandatory rules or guidance issued by any regulatory body
            having jurisdiction over the applicable party.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Normal Business Hours</b>: 9.00am – 6.00pm (UK time) on a
            Business Day.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Order Form</b>: means the document setting out the licensed
            subscription usage of the Customer including features subscribed to,
            licence term, and additional terms.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Platform</b>: the Company’s cloud hosted engagement platform,
            including the API, the Dashboard, the mobile application (where
            applicable), the Chat and Coligou Events, licensed to the Customer
            under the terms of this Agreement including future features which
            may be licensed subject to additional licence fees.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Privacy Policy</b>: means the Company’s privacy policy:{" "}
            <a href="https://coligou.com/privacy-policy/">Click here</a> (as
            amended from time to time).
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Products</b>: means those products delivered by the Company to
            the Customer via the Platform. The Products purchased by the
            Customer will be identified on the Order Form.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Prospective Student(s)</b>: an individual who is interested in
            and/or applies for a place at the Customer.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Smart Recruit</b>: admin portal for university to manage student
            communication, engagement and recruitment (the “Service(s)”).
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Term</b>: together the Initial Term and any Extended Term.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            <b>Terms of Use</b>: the Company’s terms of use:{" "}
            <a href="https://coligou.com/terms-of-use/">Click here</a> (as
            amended from time to time).
          </Typography>
          <Typography sx={{ pb: 4, pl: 6 }} variant="body1" gutterBottom>
            <b>Coligou Events</b>: means a Platform feature which enables the
            Customer to stream video content over the internet to recipients
            that have a Coligou account and the ability to host live streams
            with or without multiple chat channels; and, when applicable, stream
            live events in a conference configuration.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            1.2. Interpretation:
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            1.2.1. A reference to legislation or a legislative provision:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            1.2.1.1. is a reference to it as amended, extended or re-enacted
            from time to time; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            1.2.1.2. shall include all subordinate legislation made from time to
            time under that legislation or legislative provision.
          </Typography>
          <Typography sx={{ pb: 4, pl: 6 }} variant="body1" gutterBottom>
            1.2.2. Any words following the terms including, include, in
            particular, for example or any similar expression, shall be
            construed as illustrative and shall not limit the sense of the
            words, description, definition, phrase or term preceding those
            terms.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            2. INTEGRATION OF THE PLATFORM AND DASHBOARD ACCESS
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            2.1 Subject to the terms and conditions of this Agreement, the
            Company hereby grants to the Customer, solely for its internal
            business operations:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            1. a non-exclusive, non-transferable, and limited licence (without
            the right to grant sub-licences unless given prior written consent
            to do so by the Company) during the Term to use the API:
          </Typography>
          <Typography sx={{ pb: 1, pl: 9 }} variant="body1" gutterBottom>
            1. to integrate the Platform into the Customer Website in order to
            provide access to End Users; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 9 }} variant="body1" gutterBottom>
            2. to permit End Users to access and use the Platform, in accordance
            with this Agreement and the Acceptable Use Policy, as integrated
            into the Customer Website.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            2. a non-exclusive, non-transferable, and limited licence (without
            the right to grant sub-licences unless given prior written consent
            to do so by the Company) during the Term to permit certain End Users
            to access the Dashboard.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            2.2 The Customer shall describe the Platform (as integrated into the
            Customer Website) as “Powered by Coligou” but shall not represent
            itself as an agent of the Company for any purpose, give any
            condition or warranty or make any representation on the Company’s
            behalf or commit the Company to any contracts. Further, the Customer
            shall not without the Company’s prior written consent make any
            representations, warranties, guarantees or other commitments with
            respect to the specifications, features or capabilities of the
            Platform which are inconsistent with those specified by Company in
            writing or otherwise incur any liability on behalf of the Company
            whatsoever.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            2.3 The parties acknowledge that the Platform will include Courses
            profiles, Counsellors profiles, and/or Ambassador profiles, which
            will be accessible on the Customer Website and the Company Website.
            The Customer shall ensure that such Counsellors and Ambassadors have
            given their freely informed consent to the use of their profiles in
            this manner.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            2.4 The Customer shall use all commercially reasonable efforts to
            prevent any unauthorised access to, or use of, the Dashboard and, in
            the event of any such unauthorised access or use, promptly notify
            the Company.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            2.5 The rights provided under this clause 2 are granted to the
            Customer only and only for the Customer Website and shall not be
            considered granted to any other site, subsidiary or holding company
            of the Customer unless expressly agreed.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            3.0 THE UNIVERSITY’S OBLIGATIONS AND UNDERTAKINGS
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            3.1 The Customer undertakes that it shall not, and shall procure
            that its employees, consultants, agents and subcontractors shall
            not:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.1.1 other than as expressly permitted by this Agreement, attempt
            to obtain, or assist third parties in obtaining, access to the
            Platform;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.1.2 attempt to decompile, disassemble, reverse engineer, make
            derivative works, or otherwise reduce to practice all or any part of
            the Platform;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.1.3 use the Platform, other than as expressly permitted by this
            Agreement, the Acceptable Use Policy and the Terms of Use;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.1.4 licence, sell, rent, lease, transfer, assign, distribute,
            display, modify, copy, disclose, or otherwise commercially exploit,
            or otherwise make the Platform available to any third party; or
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.1.5 interfere in any matter with the operations of the Platform,
            or any hardware, software, or network used to operate the Platform.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            3.2 The Customer shall ensure that the Acceptable Use Policy and the
            Terms of Use are brought sufficiently to the attention of all End
            Users prior to and throughout their use of the Platform.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            3.3 If the Customer becomes aware that any End User’s use of the
            Platform breaches the Acceptable Use Policy and/or Terms of Use, the
            Customer shall immediately inform the Company.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            3.4 The Company reserves the right, without liability or prejudice
            to its other rights, to disable the Customer’s and/or End Users’
            access to the Platform if the Company reasonably believes there has
            been (or might be) a breach of the provisions of this clause 3.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            3.5 The Customer shall obtain and maintain all necessary licences,
            permissions and consents which may be required for their use of the
            Platform and/or Product prior to their use of the Platform.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            4.0 THE COMPANY’S OBLIGATIONS AND UNDERTAKINGS
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            4.1 The Company shall, during the Term:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.1.1 provide Platform access to the Customer for the Coligou
            products it has subscribed to (as set out on the Order Form),
            subject to the terms of this Agreement; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.1.2 enable End Users to use the Platform.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            4.2 The Company may, during the Term, provide such updates or new
            releases to the Platform as such updates or new releases become
            generally available, subject to any applicable additional licence
            fee for substantial new features.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            4.3 The Company shall use commercially reasonable endeavours to meet
            the Service Levels set out in Schedule 2.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            5.0 PRODUCT LIMITATIONS AND FAIR USE POLICY
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            5.1 Usage limits will apply to certain elements of the Coligou
            Platform, in particular but not limited to the use of video
            streaming as part of the Coligou Events features if purchased. These
            usage limits will include:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.1 a limit on the number of individuals able to view any enabled
            video streaming at any given time (concurrent views). This will be
            set according to the size of the package the Customer has subscribed
            to.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            5.2 The availability of certain functionality (such as, but not
            limited to, sharing video content) is contingent on the Customer
            gaining the relevant permissions and consents from any persons
            (including third parties) who have been engaged by it to create,
            deliver or curate content and the Company accepts no responsibility
            where any necessary permissions or consents are not obtained by the
            Customer which may limit usage of the Platform.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            5.3 The Company reserves the right to suspend access of the Customer
            and/or End Users to the Platform at any time where the Customer is
            found (or is suspected) to have exceeded usage limits and/or
            breached the Company’s Acceptable Use policy.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            6.0 PRICES AND PAYMENT
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            6.1 The widget and all Coligou’ s services and products will be
            offered in accordance with the Order Form{" "}
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            6.2 The Fees (subject to clause 14.4 and clause 15.5) are
            non-cancellable and non-refundable.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            6.3 The Company will invoice the Customer for the Fees annually in
            advance. The Customer shall pay the full amount invoiced to it by
            the Company in the currency specified on the Order Form, within 30
            days of the date of invoice.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            6.4 All amounts due under this Agreement shall be paid by the
            Customer to the Company in full without any set-off, counterclaim,
            deduction or withholding (other than any deduction or withholding of
            tax as required by law).{" "}
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            6.5 If the Customer fails to make any payment due to the Company
            under this Agreement by the due date for payment, then, without
            prejudicing any other rights or remedies available to the Company,
            the Company shall be entitled to suspend access to the Platform for
            the Customer and End Users without any liability and shall be under
            no obligation to provide access to the Platform and/or any of the
            Products while any such invoice remains unpaid.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            6.6 Any payment not received by Company within 60 days of the date
            of invoice will accrue interest each day at 4% a year above the Bank
            of England’s base rate from time to time, but at 4% a year for any
            period when that base rate is below 0%. The Customer will be liable
            to pay the Company’s costs in receiving outstanding monies from it.{" "}
          </Typography>{" "}
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            6.7 Except for sales taxes which may be due and set forth on an
            applicable invoice, and as explicitly set forth in this Agreement,
            each party will be solely responsible for all taxes, charges, and
            assessments applicable to its own activities, including without
            limitation taxes on its net income, taxes relating to its employees,
            and taxes on any property it owns or leases. If Customer is exempt
            from applicable taxes, Customer shall, upon execution of this
            Agreement, provide Company with the applicable exemption
            certificate(s).{" "}
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            6.8 The Fees shall be fixed for the first 12 months from the
            Effective Date. Thereafter the Company shall have the right to
            increase the Fees up to a maximum increase of 10% of the current Fee
            for the remainder of the respective term or Extended Term (as
            applicable).
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            7.0 INTELLECTUAL PROPERTY RIGHTS
          </Typography>{" "}
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            7.1 All Intellectual Property Rights in the Platform and the
            Products belong to the Company and/or its licensors.
          </Typography>{" "}
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            7.2 The Customer shall not do or authorise any third party to do any
            act which would or might invalidate or be inconsistent with any
            Intellectual Property Rights of the Company and shall not omit or
            authorise any third party to omit to do any act which, by its
            omission, would have that effect or character.
          </Typography>{" "}
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            7.3 The Customer acknowledges that the use of certain features of
            the Platform and/or Products, including but not limited to Coligou
            Events and Coligou Spotlight may enable the Customer’s End Users to
            communicate content which may include the Intellectual Property
            Rights of third parties and shall ensure that such use of the
            Platform does not infringe such rights.
          </Typography>{" "}
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            7.4 Other than the licences expressly granted under this Agreement,
            neither party grants any licence of, right in or makes any
            assignment of any of its Intellectual Property Rights. In
            particular, except as expressly provided in this Agreement, the
            Customer shall have no rights in respect of any trade names, Company
            Trademarks and/or Company Intellectual Property or the associated
            goodwill therein, and the Customer hereby acknowledges that all such
            rights and goodwill shall inure for the benefit of and are (and
            shall remain) vested in, the Company. All Feedback, including any
            and all intellectual property rights and goodwill adhering thereto,
            shall be Company Intellectual Property. The Customer hereby agrees
            to assign any and all rights, title and interest to such Feedback to
            Company. The Customer further agrees to execute any documents
            reasonably necessary to effect such assignment.{" "}
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            7.5 All Customer Content shall remain the Customer’s Intellectual
            Property Rights and such rights shall vest in the Customer.
          </Typography>{" "}
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            7.6 The Customer hereby grants to the Company a non-exclusive,
            irrevocable, perpetual, non-transferable licence to use the
            Intellectual Property Rights in all Customer Content for:
          </Typography>{" "}
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            7.6.1 analytical purposes, including but not limited to providing
            year-on-year trend statistics to the Customer as part of the
            Platform features;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            7.6.2 to improve security in the Platform, including but not limited
            to detection systems seeking to identify End Users and/or
            Ambassadors that breach the Acceptable Use Policy and/or the Terms
            of Use; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            7.6.3 to improve the performance and functionality of the Platform;
            save that Personal Data may be processed under clause 8.6.2 where
            necessary to address breaches of the Acceptable Use Policy and/or
            Terms of Use and/or any Legislation. Any such processing shall be
            undertaken in compliance with the Data Processing Addendum in
            Schedule 1.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            7.7 Any new or derivative works created from such Aggregate Data
            shall vest in the Company and remain the Company’s Intellectual
            Property Rights.
          </Typography>{" "}
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            7.8 The Customer shall promptly give notice in writing to the
            Company in the event that it becomes aware of:
          </Typography>{" "}
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            1. any infringement or suspected infringement of the Intellectual
            Property Rights in or relating to the Platform and/or any Product;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            2. any claim that Platform and/or any Product infringes the rights
            of any third party;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3. any infringement or suspected infringement of Intellectual
            Property Rights in or relating to any Customer Content; or
          </Typography>
          <Typography sx={{ pb: 4, pl: 6 }} variant="body1" gutterBottom>
            4. any claim that the Customer Content infringes the rights of any
            third party.
          </Typography>{" "}
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            8.0 BRANDING
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            8.1 Subject to the terms and conditions of this Agreement, the
            Company hereby grants to the Customer a non-exclusive, revocable,
            non-transferable licence to use the Company name on the Customer
            Website, unless otherwise agreed to in writing.
          </Typography>{" "}
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            8.2 Subject to the terms and conditions of this Agreement, the
            Customer hereby grants to the Company a non-exclusive, revocable,
            non-transferable licence to use the Customer marks and refer to this
            Agreement on the Company Website and in the Company’s marketing
            materials.{" "}
          </Typography>{" "}
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            8.3 The Customer shall ensure that each reference to, and use of,
            any of the Company Trademarks by the Customer is in a manner
            approved from time to time by the Company and accompanied by an
            acknowledgement in a form approved by the Company that the same is a
            trademark (or registered trademark) of the Company.
          </Typography>{" "}
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            {" "}
            9.0 CONFIDENTIALITY
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            9.1 Each party may have access to Confidential Information of the
            other party under this Agreement. A party’s Confidential Information
            shall explicitly include, but is not limited to, the Fees, the terms
            and conditions of this Agreement and the other party’s Intellectual
            Property.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            9.2 A party’s Confidential Information shall not include information
            that:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            9.2.1 is or becomes publicly known through no act or omission of the
            receiving party;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            9.2.2 was in the receiving party’s lawful possession prior to the
            disclosure; or
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            9.2.3 lawfully disclosed to the receiving party by a third party
            without restriction on disclosure; or
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            9.2.4 is independently developed by the receiving party, which
            independent development can be shown by written evidence.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            9.3 Each party shall hold the other party’s Confidential Information
            in confidence and, unless required by law, by any court of competent
            jurisdiction or by any regulatory or administrative body, not make
            the other’s Confidential Information available to any third party or
            use the other’s Confidential Information for any purpose other than
            the implementation of this Agreement.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            9.4 Each party agrees to take all reasonable steps to ensure that
            the other’s Confidential Information to which it has access is not
            disclosed or distributed by its employees or agents in violation of
            the terms of this Agreement. Notwithstanding the foregoing, each
            party may provide a copy of this Agreement to its financial, legal,
            or professional advisors, or potential financing sources, in
            connection with a bona fide due diligence request for a potential
            financing, acquisition, or similar transaction and where a party has
            done so it shall remain fully liable for the acts and omissions of
            any such advisors to whom it has disclosed this Agreement. These
            restrictions will not prevent either party from complying with any
            law, regulation, court order or other legal requirement that compels
            disclosure of any Confidential Information provided that the
            receiving party will promptly notify the disclosing party upon
            learning of any such legal requirement, and cooperate with the
            disclosing party in the exercise of its right to protect the
            confidentiality of the Confidential Information.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            9.5 The obligations of confidentiality contained in this clause 9
            shall persist for the duration of this Agreement and three (3) years
            post-termination.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            10.0 PROTECTION AND PROCESSING OF PERSONAL DATA{" "}
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            10.1 Each party shall process Personal Data (as defined in Schedule
            1) in accordance with the Data Processing Addendum attached at
            Schedule 1 .
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            11.0 WEB ACCESSIBILITY
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            11.1 The Company shall make all reasonable efforts to ensure that
            the API, as embedded into the Customer Website, will be accessible
            to individuals with disabilities as measured by conformance with the
            W3C’s Web Content Accessibility Guidelines (WCAG) 2.1 Level AA, with
            the exception of audio description.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            12.0 WARRANTIES{" "}
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            12.1 Each party represents, warrants and undertakes that:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            12.1.1 it has full capacity and authority and all necessary consents
            to enter into and to perform this Agreement and to grant the rights
            and licences referred to in this Agreement and that this Agreement
            is executed by its duly authorised representative and represents a
            binding commitment on it; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            12.1.2 it shall comply with all applicable Legislation in the
            performance of its obligations under this Agreement.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            12.1.3 The Company warrants to the Customer that the Platform and
            the Products licensed by it under this Agreement will operate
            substantially in accordance with, and perform, the material
            functions and features as set out in the applicable part(s) of the
            specification, if requested.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            12.1.4 Notwithstanding the foregoing in clause 13.2, the Company:
            does not warrant that the Platform and/or the Products will be
            uninterrupted or error-free; or that the Platform or the Products
            will meet the Customer’s or any End User’s expectations or
            requirements; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            12.1.5 is not responsible for any delays, delivery failures, or any
            other loss or damage resulting from the transfer of data over
            communications networks and facilities, including the internet, and
            the Customer acknowledges that the Platform and/or the Products may
            be subject to limitations, delays and other problems inherent in the
            use of such communications facilities.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            12.2 The parties acknowledge that the platform and the products are,
            except as set forth herein, provided to the customer “as is”. Other
            than as set out in clause 12.2 all warranties, whether express or
            implied, including but not limited to the warranties of
            merchantability or fitness for a particular purpose are to the
            fullest extent permitted by law, excluded from this agreement.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            13.0 INDEMNIFICATION, INFRINGEMENT
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            13.1 The Customer hereby agrees to indemnify, defend, and hold
            harmless the Company and its officers, directors, employees and
            agents from and against all Claims arising from, or related to:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.1.1 a breach of the confidentiality provisions set forth herein;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.1.2 the Customer’s and/or End Users use of the Platform and/or
            any Product(s);
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.1.3 a breach of Schedule 1 (Data Processing Addendum) by the
            Customer;{" "}
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.1.4 a claim alleging that the Customer’s use of the Platform, the
            Customer Content or any other Customer Intellectual Property Rights
            or Trademark infringes a third party’s intellectual property rights;
            or
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.1.5 Customer Content.{" "}
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            13.2 The Company shall defend the Customer, its officers, directors
            and employees against any claim that the Customer’s use of the
            Platform and/or Product(s) in accordance with this Agreement
            infringes any patent effective as of the Effective Date, copyright,
            trademark, database right or right of confidentiality, and shall
            indemnify the Customer for any amounts awarded against the Customer
            in judgment or settlement of such claims, provided that:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.2.1 the Company is given prompt notice of any such claim;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.2.2 the Customer does not make any admission, or otherwise
            attempt to compromise or settle the claim and provides reasonable
            co-operation to the Company in the defence and settlement of such
            claim, at the Company’s expense; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.2.3 the Company is given sole authority to defend or settle the
            claim.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            13.3 In the defence or settlement of any claim, the Company may
            procure the right for the Customer to continue using the Platform
            and/or Product(s), replace or modify the Platform and/or Product(s)
            so that they become non-infringing or, if such remedies are not
            reasonably available, terminate this Agreement on 2 Business Days’
            notice to the Customer without any additional liability or
            obligation to pay liquidated damages or other additional costs to
            the Customer.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            13.4 In no event shall the Company, its employees, agents and
            subcontractors be liable to the Customer to the extent that the
            alleged infringement is based on:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.4.1 a modification of the Platform and/or Product(s) by anyone
            other than the Company;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.4.2 the Customer’s use of the Platform and/or Product(s) in a
            manner contrary to the instructions given to the Customer by the
            Company; or
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.4.3 the Customer’s use of the Platform and/or Product(s) after
            notice of the alleged or actual infringement from the Company or any
            appropriate authority.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            13.5 The foregoing states the Customer’s sole and exclusive rights
            and remedies, and the Company’s (including the Company’s employees’,
            agents’ and subcontractors’) entire obligations and liability, for
            infringement of any patent, copyright, trade mark, database right or
            right of confidentiality.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            13.6 The indemnified party shall provide the indemnitor with:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.6.1 prompt written notification of any Claims;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            13.6.2 sole control and authority over the defence or settlement of
            the Claims; and
          </Typography>
          <Typography sx={{ pb: 4, pl: 6 }} variant="body1" gutterBottom>
            13.6.3 all available information and reasonable assistance necessary
            to settle and/or defend any such Claims, provided that if any
            settlement requires any action or admission of liability by the
            indemnified party, then the settlement will require the indemnified
            party’s written consent.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            {" "}
            14.0 LIMITATION OF LIABILITY
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            14.1 The following provisions set out the entire financial liability
            of each party (including any liability for the acts or omissions of
            its employees, agents and subcontractors) to the other party in
            respect of every kind of liability arising under or in connection
            with this Agreement including liability in contract, tort (including
            negligence), misrepresentation, restitution or otherwise.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            14.2 Nothing in this clause 14 shall limit the Customer’s payment
            obligations under the Agreement.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            14.3 The Company shall have the right to participate in the defence
            of any Claim and be represented by counsel at its expense.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            14.4 Nothing in this Agreement excludes the liability of either
            party:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.4.1 for death or personal injury caused by its negligence; or
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.4.2 for fraud or fraudulent misrepresentation.{" "}
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            14.5 Subject to clause 14.4, neither party shall in any
            circumstances be liable, whether in tort (including for negligence
            or breach of statutory duty howsoever arising), contract,
            misrepresentation (whether innocent or negligent) or otherwise for:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.5.1 loss of profits;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.5.2 loss of business;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.5.3 depletion of goodwill or similar losses;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.5.4 loss of anticipated savings;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.5.5 loss of goods;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.5.6 loss of use;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.5.7 loss or corruption of data or information; or
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.5.8 any special, incidental, consequential or other indirect
            loss, costs, damages, charges or expenses.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            14.6 Subject to clause 14.5 the Company’s total aggregate liability
            in contract, tort (including without limitation negligence or breach
            of statutory duty howsoever arising), misrepresentation (whether
            innocent or negligent), restitution or otherwise, arising under
            clause 14.2 and/or in connection with the performance or
            contemplated performance of this Agreement shall in all
            circumstances be limited to the equivalent amount of fees actually
            paid under this Agreement in the 12 months preceding the date on
            which the claim arose.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.6.1 Subject to Clause 14.4, the Customer’s liability to the
            Company under clause 13.1.3 (breaches of Schedule 1 Data Processing
            Addendum) shall not exceed £2 million per Claim.
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            14.6.2 Subject to Clause 14.4, the Customer’s total liability to the
            Company:
          </Typography>
          <Typography sx={{ pb: 1, pl: 9 }} variant="body1" gutterBottom>
            14.6.2.1 arising under clauses 14.1.1 (breach of confidentiality
            provisions), 14.1.2 (Customer or End User’s use of the Platform
            and/or any Products), 14.1.4 (breach of third party’s intellectual
            property rights) and/or 13.1.5 (Customer Content) shall not exceed
            £1 million per Claim;
          </Typography>
          <Typography sx={{ pb: 1, pl: 9 }} variant="body1" gutterBottom>
            14.6.2.2 for all other loss or damage which does not fall within
            14.8.1 or within clause 14.7 shall not exceed 200% of the Fees paid
            or payable under this Agreement.
          </Typography>
          <Typography sx={{ pb: 4, pl: 12 }} variant="body1" gutterBottom>
            14.6.2.2.1 For the avoidance of doubt, any unpaid fees owed to the
            Company under this Agreement shall be in addition to the Customer’s
            total aggregate liability.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            15.0 TERM AND TERMINATION
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            15.1 This Agreement shall commence on the Effective Date. Unless
            terminated earlier in accordance with clause 15.3, this Agreement
            shall continue for the Initial Term and shall automatically extend
            for the Extended Term at the end of the Initial Term and at the end
            of each Extended Term unless written notice is provided by either
            party to terminate this Agreement at least thirty (30) days prior to
            the end of the Initial Term or any Extended Term (as applicable).
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            15.2 The Company will notify the Customer of the Fees that shall
            apply on renewal not later than 60 days before the end of the
            Initial Term and each and any Extended Term (as applicable).
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            15.3 Without affecting any other right or remedy available to it,
            either party may terminate this Agreement with immediate effect by
            giving written notice to the other party if:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            15.3.1 the other party fails to pay any amount due under this
            Agreement on the due date for payment;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            15.3.2 the other party commits a material breach of any term of this
            Agreement which breach is irremediable or (if such breach is
            remediable) fails to remedy that breach within a period of 14 days
            after being notified in writing to do so; or
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            15.3.3 the other party is unable to pay its debts as they fall due,
            admits inability to pay its debts, is deemed unable to pay its debts
            within the meaning of section 123 of the Insolvency Act 1986, or any
            event occurs, or proceeding is taken, with respect to the other
            party in any jurisdiction to which it is subject that has an effect
            equivalent to similar to any of the events mentioned in this clause
            15.3.3.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            15.4 Without prejudice to any other rights or remedies to which the
            Company may be entitled, the Company may terminate the Agreement
            without liability to the Customer if:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            15.4.1 the Customer challenges or disputes the validity of any of
            the Company’s Intellectual Property Rights; or
          </Typography>
          <Typography sx={{ pb: 4, pl: 6 }} variant="body1" gutterBottom>
            15.4.2 the Customer purports to assign any of its rights or
            obligations under this Agreement.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            16.0 EFFECTS OF TERMINATION
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            16.1 Upon termination or expiry of this Agreement for any reason the
            accrued rights of the parties as at termination or the continuation
            after termination of any provision expressly stated to survive or
            implicitly surviving termination shall not be affected or
            prejudiced.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            16.2 Subject to the foregoing provisions of clause 16.1, all rights
            and licences granted under this Agreement (other than irrevocable
            and perpetual licences) shall terminate and the Customer shall
            immediately remove its integration with the Platform and its use of
            the Products.{" "}
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            16.3 The termination of this Agreement shall not of itself give rise
            to any liability on the part of the Company to pay any compensation
            to the Customer for loss of profits or goodwill, to reimburse the
            Customer for any costs relating to or resulting from such
            termination, or for any other loss or damage.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            17.0 FORCE MAJEURE
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            Neither party shall in any circumstances be in breach of this
            Agreement nor liable for delay in performing, or failure to perform,
            any of its obligations under this agreement if such delay or failure
            results from events, circumstances or causes beyond its reasonable
            control, including, without limitation, strikes, lock-outs or other
            industrial disputes (whether involving the workforce of the Company
            or any other party), act of God, war, riot, civil commotion,
            malicious damage, compliance with any law or governmental order,
            rule, regulation or direction, accident, breakdown of plant or
            machinery, fire, flood, storm or default of suppliers or
            subcontractors. In such circumstances the affected party shall be
            entitled to a reasonable extension of the time for performing such
            obligations, provided that if the period of delay or non-performance
            continues for three months, the party not affected may terminate
            this Agreement by giving 30 days’ written notice to the other party.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            18.0 GENERAL
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            18.1 No failure or delay by a party to exercise any right or remedy
            provided under this Agreement or by law shall constitute a waiver of
            that or any other right or remedy, nor shall it prevent or restrict
            the further exercise of that or any other right or remedy. No single
            or partial exercise of such right or remedy shall prevent or
            restrict the further exercise of that or any other right or remedy.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            18.2 If any provision or part-provision of this Agreement is or
            becomes invalid, illegal or unenforceable, it shall be deemed
            modified to the minimum extent necessary to make it valid, legal and
            enforceable. If such modification is not possible, the relevant
            provision or part-provision shall be deemed deleted. Any
            modification to or deletion of a provision or part-provision under
            this clause shall not affect the validity and enforceability of the
            rest of this Agreement.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            18.3 This Agreement, together with the Order Form, Schedules and
            incorporated documents, constitutes the entire agreement between the
            parties and supersedes and extinguishes all previous agreements,
            promises, assurances, warranties, representations and understandings
            between them, whether written or oral, relating to its subject
            matter. In the event of a conflict of terms the documents shall take
            the following order of precedence: the Order Form, the Master
            Licence Terms (if applicable), any terms incorporated by reference,
            and the Coligou Platform Licence Agreement. In the event of a
            conflict between these Master Licence Terms and the Schedules
            hereto, the Schedules shall take precedence.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            18.4 Each party acknowledges that in entering into this Agreement it
            does not rely on, and shall have no remedies in respect of, any
            statement, representation, assurance or warranty (whether made
            innocently or negligently) that is not set out in this Agreement.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            18.5 Each party agrees that it shall have no claim for innocent or
            negligent misrepresentation or negligent misstatement based on any
            statement in this agreement.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            18.6 No variation of this Agreement shall be effective unless it is
            in writing and signed by the parties (or their authorised
            representatives).
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            18.7 Nothing in this Agreement is intended to, or shall be deemed
            to, establish any partnership or joint venture between any of the
            parties, constitute any party the agent of another party, nor
            authorise any party to make or enter into any commitments for or on
            behalf of any other party. Each party confirms it is acting on its
            own behalf and not for the benefit of any other person.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            18.8 Neither party shall assign this Agreement, in whole or in part,
            to any entity without the other party’s prior written consent. Any
            attempt to assign this Agreement, in whole or part, in contravention
            of this section, shall be void, except that, either party may assign
            this Agreement and such party’s rights and delegate its duties
            hereunder pursuant to a transfer of all or substantially all of its
            business, whether by merger (statutory or otherwise), sale of assets
            or stock or otherwise. This Agreement shall be binding upon and
            shall inure to the benefit of the parties hereto and their
            successors and permitted assigns.{" "}
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            18.9 Each party shall comply with all applicable laws, regulations,
            codes and sanctions relating to anti-bribery and anti-corruption
            including but not limited to the Bribery Act 2010.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            18.10 The Customer shall ensure that any person associated with the
            Customer who is performing services or providing goods in connection
            with this agreement does so only on the basis of a written contract
            which imposes on and secures from such person terms equivalent to
            those imposed on the Customer in this Agreement (“
            <b>Relevant Terms</b>”). The Customer shall in all circumstances be
            responsible for the observance and performance by such persons of
            the Relevant Terms and shall in all circumstances be directly liable
            to the Company for any breach by such persons of any of the Relevant
            Terms howsoever arising.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            18.11 A breach of clause 18.8 and/or clause 18.9 shall be deemed a
            material breach of this Agreement.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            18.12 This Agreement does not confer any rights on any person or
            party (other than the parties to this Agreement and, where
            applicable, their successors and permitted assigns) pursuant to the
            Contracts (Rights of Third Parties) Act 1999.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            19.0 GOVERNING LAW
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            19.1 This Agreement and any disputes or claims arising out of or in
            connection with it or its subject matter or formation (including
            non-contractual disputes or claims) are governed by and construed in
            accordance with the laws of England and Wales.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            19.2 The parties irrevocably agree that the courts of England have
            exclusive jurisdiction to settle any disputes or claims arising out
            of or in connection with this agreement, its subject matter or its
            formation (including non-contractual disputes or claims).
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            20.0 SURVIVAL
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            Each party’s obligations under this Agreement which by their nature
            (irrespective of whether the provisions specifically states that it
            will survive the termination or expiration of this Agreement) are
            intended to survive termination or expiration of this Agreement will
            so survive.
          </Typography>
          <Typography
            textAlign="center"
            sx={{ pb: 3 }}
            variant="h1"
            gutterBottom
          >
            Schedule 1 – Data Processing Addendum
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            1.0 DEFINITIONS AND INTERPRETATION
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            The following definitions are used in this Agreement:
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Data Controller</b>: shall mean the entity which alone or jointly
            with others determines the purposes and means of the Processing of
            Personal Data. For the purposes of the CCPA, Data Controller shall
            be construed as a “Business”.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Data Processor</b>: shall mean the entity which Processes
            Personal Data on behalf of the Data Controller. For the purposes of
            the CCPA, Data Processor shall be construed as a “Service Provider”.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Data Discloser and Data Receiver</b>: shall have the meaning set
            out in clause 2.2.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            End User: means any category of individual that has access to or
            engages with the Coligou Platform including but not limited to
            Prospective Students, students of the Customer, Student Ambassadors,
            administrators and other Customer employees.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Joint Data Controllers</b>: shall mean two or more Data
            Controllers who jointly determine the purposes and means of the
            Processing of Personal Data.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Personal Data</b>: shall mean all information related to an
            identified or identifiable natural person (each, a “Data Subject”)
            created, collected or received pursuant to the services performed
            under this Agreement. For the purposes of the CCPA and FERPA,
            Personal Data may be construed as Personal Information or Education
            Records and Data Subject shall be construed as a “Consumer” where
            applicable.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Personal Data Breach</b>: shall mean a breach of security leading
            to the accidental or unlawful destruction, loss, alteration,
            unauthorised disclosure of, or access to, Personal Data transmitted,
            stored or otherwise Processed.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Processing</b>: shall mean any operation or set of operations
            which is performed upon Personal Data, whether or not by automatic
            means, such as collection, recording, organisation, storage,
            adaptation or alteration, retrieval, consultation, use, disclosure
            by transmission, dissemination or otherwise making available,
            alignment or combination, blocking, erasure or destruction.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Privacy Laws</b>: means any applicable law, regulation, or other
            legal requirement governing the relationship between the Customer
            and the Company and the services provided under the Agreement
            including but not limited to, the General Data Protection Regulation
            ((EU) 2016/679) (“GDPR”), the California Consumer Privacy Act 2018
            (“CCPA”), the Family Educational and Privacy Rights Act (“FERPA”)
            and/or any applicable local implementation.
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            <b>Supervisory Authority</b>: shall have the meaning given to it in
            the Privacy Laws.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            {" "}
            2.0 BACKGROUND
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            2.1 The Customer shall be the Data Controller and the Company shall
            be the Data Processor where End Users are using Coligou’s Platform
            on behalf of the Customer.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            2.2 The Customer and the Company shall act as Joint Data Controllers
            where the Customer has requested the Company to collect additional
            Personal Data from the End User.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            2.3 Both the Customer and the Company shall comply with their
            obligations under the Privacy Laws in relation to any Personal Data
            made available by the Customer which the Company Processes under or
            for the purposes of this Agreement and in relation to any Personal
            Data that both the Customer and the Company jointly control.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            2.4 Where the parties have determined that they are Joint- Data
            Controllers in relation to the shared Personal Data, this Agreement
            sets out the arrangements between them for the purposes of Article
            26 of the GDPR.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            2.5 The Data Discloser agrees to share the Personal Data with the
            Data Receiver in the European Economic Area (EEA), or outside the
            EEA, on terms set out in the Agreement.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            2.6 The Data Receiver agrees to use the Personal Data within the
            EEA, or outside the EEA, on the terms set out in this Agreement.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            3.0 PURPOSE OF PROCESSING
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <i>Coligou as a Processor</i>
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            3.1 The Company shall Process the Personal Data in order to provide
            the following services as part of the Platform:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.1.1 enable prospective students to get matched with and browse
            university courses and video content and/or shortlist, apply to
            university courses
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.1.2 enable direct communications between prospective
            students/applicants and current Ambassadors and university staff;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.1.3 provide an administration dashboard for the Platform to the
            Customer, to include analytics and monitoring of activity on the
            Platform; to include applications dashboard to manage applications
            of prospective students
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.1.4 enable the collection of supplementary information and
            feedback on the use of the Platform by End Users;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.1.5 the Platform and communications will be available via a web
            API, the Coligou.com website, a mobile app, and other online
            communications mechanisms, and will include email and SMS
            notifications; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.1.6 Personal Data, including supplementary data and conversational
            data will be collected via web-based sign-up forms, and via the chat
            facility, embedded within the Platform on the web API and the mobile
            app.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <i>Joint Data Controllers</i>
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            3.2 The Company and the Customer shall act as Joint Data Controllers
            when one party (the Data Discloser) discloses Personal Data to the
            other (the Data Receiver).
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            3.3 The parties consider this data sharing initiative is necessary
            to provide the Customer with additional necessary information they
            require from their End Users. The purpose of the data sharing
            initiative is to provide improved service to the Customer and
            provide the Customer with the requested data.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            3.4 The Company shall share Personal Data with the Customer as
            follows:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.4.1 the Company shall collect additional End User specific data,
            such as phone numbers, field of preferred study and anything else
            reasonably requested by the Customer;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.4.2 the Company shall provide an administration dashboard for the
            Platform to the Customer, to include analytics and monitoring of
            activity on the Platform; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            3.4.3 Personal Data, including supplementary data and conversational
            data will be collected via web-based sign-up forms, and via the chat
            facility, embedded within the Platform on the web API and the mobile
            app.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            3.5 The parties shall not Process Shared Personal Data in a way that
            is incompatible with the purposes described in this clause.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            3.6 Each party shall appoint a single point of contact (SPoC) for
            Data Subjects who will work together to reach an agreement with
            regards to any issues arising from the data sharing and to actively
            improve the effectiveness of the data sharing initiative. The point
            of contact for Coligou is Head of Legal, legal.team@Coligou.com and
            the point of contact for the other party shall be as specified in an
            executed Order Form.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            4. DATA SUBJECTS AND DATA COLLECTED
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            4.1. Personal Data will be collected from and Processed for the
            following categories of Data Subjects:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.1.1. End User (enquirers) and applicants of the Customer;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.1.2. current Ambassadors;{" "}
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.1.3. Customer Staff; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.1.4. if applicable, Customer Staff, End Users and Ambassadors of
            affiliated universities.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            4.2. Personal Data collected from the Data Subjects identified above
            will include:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.2.1. End User: essential data – first name, last name, email
            address, encrypted password, country; additional data – the degree
            subject/level of interest (undergraduate/postgraduate), phone
            number, whether they have already applied to the university, and
            chat conversations and interactions with Counsellors and/or
            Ambassadors;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.2.2. Ambassadors: first name, last name, photo, phone number,
            email address, encrypted password, country/city/location, languages
            spoken, academic history (and previous high school;
            degree/university degree; degree level (undergraduate/postgraduate);
            university year (1st, 2nd etc.), an ‘about me’ free text selection
            and chat conversations and interactions with Prospective Students;
            and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.2.3. End Users and Customer Staff: first name, last name, email
            address, encrypted password.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            4.3. Personal Data collected about the data subjects in respect of
            all End Users includes:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.3.1. device-specific information, such as their hardware model,
            operating system version, unique device identifiers, and mobile
            network information;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.3.2. technical information about their computer or mobile device,
            including where available, their IP address, operating system and
            browser type, for system administration and analytical purposes; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            4.3.3. details of their visits to our Website, including the full
            Uniform Resource Locators (URL) clickstream to, through and from our
            Website (including date and time), length of visits to certain
            pages, and page interaction information (such as scrolling, clicks,
            and mouse-overs), details of whether you are using the Website or
            the Coligou widget.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Special categories of personal data (as such term is defined in the
            Privacy Laws) will not be Processed under this Agreement.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            4.4. Personal Data will be processed or controlled only for the time
            necessary to enable the service to be provided and in accordance
            with the Privacy Policies of the Company and the Customer. Customer
            Staff and Ambassador data will be deleted or irreversibly anonymised
            within 14 days of an instruction by the Customer.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            4.5. The default setting for the retention of Personal Data will be
            two years after the last interaction by the Data Subject. Variation
            of this setting will require a written instruction from the Customer
            and may be subject to the technical limitations of the Platform at
            the time of request.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            4.6. The Platform is under continual development, and new features
            may include additional Data Subjects (such as Alumni) and additional
            Personal Data collected (such as further details about
            administrative and academic staff using the Platform). The Customer
            will be asked for written consent to add such additional features,
            and any such additional Processing will be accompanied by a revised
            End User Privacy Policy detailing the types and uses of the Personal
            Data.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            5. DATA PROCESSOR AND DATA CONTROLLER OBLIGATION
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            5.1. In its capacity as a Data Processor, the Company shall, and
            shall procure that any sub-contractors who Process Personal Data on
            its behalf shall:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.1. not Process Personal Data except as necessary to provide the
            Services, and shall only Process such Personal Data in accordance
            with this Agreement and only on the Customer’s written instructions;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.2. implement appropriate technical and organisational measures
            (as such term is defined in the Privacy Laws) to protect Personal
            Data against unauthorised or unlawful Processing and accidental
            loss, disclosure, access or damage;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.3. comply with its Privacy Policy;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.4. assist the Customer to meet its obligations as Data
            Controller to enable Data Subjects to exercise their rights, such as
            subject access requests, requests for rectification or erasure, or
            making objections to Processing;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.5. assist the Customer in its obligation to carry out data
            protection impact assessments (DPIAs) and in consulting with the
            relevant authority if the DPIA indicates an unmitigated high risk to
            Processing;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.6. notify the Customer immediately if it believes it has been
            given an instruction that doesn’t comply with Privacy Laws;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.7. delete or return to the Customer all Personal Data upon
            request or on termination or expiry of this Agreement, unless
            otherwise required under applicable laws,
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.8. ensure that persons authorised to access Personal Data are
            subject to confidentiality obligations, whether by contract or
            statute;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.9. as soon as reasonably practicable, within the next 24 hours
            and no later than 72 hours, notify the Customer in writing of any
            actual or potential Personal Data Breach. The notice will specify
            (i) the categories and number of Data Subjects concerned, (ii) the
            categories and number of records involved, (iii) the likely
            consequences of the Personal Data Breach and (iv) any steps taken to
            mitigate and address the Personal Data Breach;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.10. not transfer Personal Data to any country outside of the EEA
            without prior written consent from the Customer, except for
            transfers to and from: (i) the United Kingdom; (ii) any country
            which has a valid adequacy decision from the European Commission; or
            (iii) any organisation which ensures an adequate level of protection
            in accordance with the Privacy Laws; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            5.1.11. not subcontract any Processing of Personal Data under less
            protective terms and security standards than those secured under
            this Agreement. The Customer hereby authorises the Company to use
            cloud service providers and hosting services, including but not
            limited to those detailed in the Company’s privacy policy.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            5.2. The Customer shall ensure that it has all necessary consents
            and notices in place to enable the lawful transfer of the Personal
            Data to the Company and the Processing of the Personal Data by the
            Company in the manner described in this Agreement.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            5.3. The Customer shall be solely responsible for any automated
            decision making it makes through use of the Services and shall
            ensure it implements suitable measures to safeguard the Data
            Subjects’ rights and freedoms.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            5.4. The Customer must promptly notify the Company in the event of
            any withdrawal of any relevant consent by any Data Subject whose
            Personal Data is Processed pursuant to this Agreement, giving
            sufficient details of the withdrawal to enable the Company to comply
            with its obligations under the Privacy Laws.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            5.5. Each party must immediately notify the other if it becomes
            aware of a complaint or allegation of breach of the Privacy Laws by
            any person or an investigation or enforcement action by a regulatory
            authority, in connection with this Agreement.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            5.6. Subject to reasonable notice, the Company shall permit the
            Customer to monitor, inspect, interview and audit the staff,
            facilities, data, documentation, systems, records, internal policies
            and controls and materials of the Company for the purpose of
            reviewing the Company’s compliance and ability to comply with the
            Privacy Laws and promptly give all access, copies of records,
            information and explanations to the Customer to undertake any such
            monitoring, inspection, interviews or audits. If an audit requires
            the equivalent of more than one business day of time expended by one
            or more of the Company’s employees (or other personnel), the
            Customer agrees to reimburse Company for any additional time
            expended at Company’s then current professional services rates.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            6. JOINT DATA CONTROLLER OBLIGATIONS AND RESPONSIBILITIES
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            6.1. In circumstances where the parties are acting as Joint Data
            Controllers, each party shall:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            6.1.1. ensure that it has legitimate grounds under the Privacy Laws
            for the processing of Personal Data;
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            6.1.2. comply with its obligations under Article 26 of the GDPR and
            in particular
          </Typography>
          <Typography sx={{ pb: 1, pl: 9 }} variant="body1" gutterBottom>
            6.1.2.1. shall make available to Data Subjects the essence of the
            arrangements contemplated by this Agreement as is required by
            Article 26(2) of the GDPR;
          </Typography>
          <Typography sx={{ pb: 1, pl: 9 }} variant="body1" gutterBottom>
            6.1.2.2. acknowledges that Data Subjects may exercise their rights
            under the GDPR in respect of and against each party in accordance
            with Article 26(3) of the GDPR; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 9 }} variant="body1" gutterBottom>
            6.1.2.3. agrees to provide to the other party such cooperation as
            may reasonably be required to assist that other party in compliance
            with its obligations under Article 26 of the GDPR.
          </Typography>
          <Typography sx={{ pb: 4, pl: 6 }} variant="body1" gutterBottom>
            6.1.3 be responsible for its adherence to the Privacy Laws and its
            obligations to Data Subjects. Each party is severally liable for its
            own compliance with the Privacy Laws and neither party shall be
            liable to the other for any breach or failure to comply with the
            Privacy Laws. Neither party shall be liable to the other party for
            any claims brought by a data subject as a result of any breach or
            unauthorised access to personal data.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            7. TRANSFERS
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            7.1 For the purposes of this clause, transfers of Personal Data
            shall mean any sharing of Personal Data with a third party, and
            shall include, but is not limited to, the following:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            7.1.1 subcontracting the Processing of Personal Data; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            7.1.2 granting a third party Data Controller access to the Personal
            Data.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            7.2 The Data Receiver will consult with the Data Discloser before
            any transfer of Personal Data and if the Data Receiver appoints a
            third party processor to process the Shared Personal Data it shall
            comply with Article 28 and Article 30 of the GDPR and shall remain
            liable to the Data Discloser for the acts and/or omissions of the
            processor.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            7.3 The Data Receiver may not make a transfer of Personal Data
            belonging to UK or EU Data Subjects to a third party located outside
            the UK or EEA unless it:
          </Typography>
          <Typography sx={{ pb: 1, pl: 6 }} variant="body1" gutterBottom>
            7.3.1 complies with the provisions of Articles 26 of the GDPR (in
            the event the third party is also a Joint Data Controller); and
          </Typography>
          <Typography sx={{ pb: 4, pl: 6 }} variant="body1" gutterBottom>
            7.3.2 ensures that (i) the transfer is to a country approved by the
            European Commission as providing adequate protection pursuant to
            Article 45 of the GDPR; (ii) there are appropriate safeguards in
            place pursuant to Article 46 of the GDPR; or (iii) one of the
            derogations for specific situations in Article 49 of the GDPR
            applies to the transfer.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            8 CCPA and FERPA
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            8.1 For the purposes of the CCPA, the Company does not sell Personal
            Data.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            8.2 The Company discloses its third party service providers
            (referred to as sub-processors), on its Website.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            8.3 For the purposes of FERPA, the Company only processes minimal
            Educational Records such as current degree for current Student
            Ambassadors and study areas of interest for Prospective Students.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            9 PERSONAL DATA BREACHES AND REPORTING PROCEDURES
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            9.1 Each party shall comply with its obligation to report a Personal
            Data Breach to the appropriate Supervisory Authority and (where
            applicable) the affected Data Subjects under Article 33 of the GDPR
            and shall each inform the other party of any Personal Data Breach
            irrespective of whether there is a requirement to notify any
            Supervisory Authority or Data Subject(s).
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            9.2 The parties agree to provide reasonable assistance as is
            necessary to each other to facilitate the handling of any Personal
            Data Breach in an expeditious and compliant manner.
          </Typography>
          <Typography
            textAlign="center"
            sx={{ pb: 4 }}
            variant="h1"
            gutterBottom
          >
            Schedule 2 – Service Levels{" "}
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            1 SERVICE AVAILABILITY
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            The Company shall achieve Quarterly Platform Availability Level of
            at least 99.5%.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Downtime</b>: means the time in which any service listed below is
            not capable of being accessed or used by the Customer, as monitored
            by the Company.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Exclusion from Downtime</b>: The following are not counted as
            Downtime for the purpose of calculating Quarterly Platform
            Availability Level:
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            a. Service unavailability caused by Routine and/or Emergency
            Maintenance Events (as defined in paragraph 4 of this Schedule 2) ;
            or
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            b. Service unavailability caused by events outside of the direct
            control of the Company or its subcontractor(s), including any force
            majeure event (as per the definition of force majeure set out in
            clause 17), the failure or unavailability of Customer’s systems, the
            Internet, and the failure of any other technology or equipment used
            to connect to or access the service.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Platform Availability</b>: refers to the core components of the
            Coligou chat platform, specifically:
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            a. the availability of the main Coligou widget to End Users
            prospective students,
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            b. the Chat Dashboard used by the, university staff, counsellors
            and/or student ambassadors,
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            c. the Admin Dashboard used by the university administrators, and
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            d. refers to an access point on the backbone network of the Platform
            and not the portion of the circuit that does not transit the
            backbone network, as the Customer is responsible for its own
            internet access.
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            <b>Quarterly Platform Availability Level</b>: means the total number
            of minutes in a quarter minus the number of minutes of Downtime
            suffered in a quarter, divided by the total number of minutes in a
            quarter.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            2 RESPONSE TIMES
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Where the Platform fails to meet the service availability criteria
            set out in paragraph 1 above, the Company shall use its reasonable
            endeavours to achieve the response outlined in the table below for
            each category of incident (as each event is categorised by the
            Company at its sole discretion). In this table the following terms
            are used:
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Response time: means the time period commencing from when the
            Company has accepted the notification of the incident and agreed
            that the incident is genuine and requires investigation and
            resolution (“real”).
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Resolution time: means the time period commencing from the Response
            time until the incident has been diagnosed, any underlying problem
            identified and, if possible, rectified, and the interruption to use
            minimised.
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Categories</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Response time</TableCell>
                  <TableCell align="left">Resolution time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.category}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.category}</TableCell>
                    <TableCell align="left">{row.desc}</TableCell>
                    <TableCell align="left">{row.resp}</TableCell>
                    <TableCell align="left">{row.reso}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography sx={{ pt: 4, pb: 1 }} variant="h3" gutterBottom>
            3 RECOVERY POINT OBJECTIVE
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            Where the failure has resulted in a loss of any data (including,
            without limitation, Customer Content), the Company shall endeavour
            to restore the data to the latest 6 hour incremental back-up
            available before the data was lost. The recovery time objective
            shall be as per the resolution time set out above depending upon the
            priority of the incident.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            4 MAINTENANCE EVENTS
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Maintenance of the Company’s equipment, facilities, the Platform,
            the Products or other aspects of the services that may require
            interruption of the platform (“Routine Maintenance Events”) shall be
            performed during the hours of 10.00pm to 2.00am local UK time and at
            least seven days notice will be given.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            The Company may also interrupt the Platform and/or the Products
            delivered through the Platform to perform emergency maintenance
            during the daily window of 10.00 pm to 2.00 am local UK time,
            provided at least three days’ notice is given (“Emergency
            Maintenance Events”).
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            Any Routine or Emergency Maintenance Events which occur outside of
            these hours and which were not requested or caused by the Customer,
            shall be considered downtime for the purpose of service availability
            measurement under paragraph 1 of this Schedule 2. The Company shall
            at all times endeavour to keep any service interruptions to a
            minimum.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            5 MAINTENANCE
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            The Company shall provide regular maintenance services to the
            Platform. Maintenance includes all regularly scheduled error
            corrections, software updates and upgrades.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            The Company shall maintain and update the Platform. Should the
            Customer determine that the Platform includes a defect, the Customer
            may at any time file error reports. During Routine or Emergency
            Maintenance Events, the Company may, at its discretion, upgrade
            versions, install error corrections and apply patches to the
            Platform. The Company shall use all reasonable endeavours to avoid
            unscheduled downtime for Platform maintenance.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Before the Company or the Customer makes changes to integration
            interfaces between the API and the Customer’s internal data stores
            or systems, the Company or the Customer shall provide notice to the
            other in order to ensure the continued operation of any integration
            interfaces affected by such changes. Each party shall provide the
            other party with at least 60 days’ advance notice of such changes.
            Such notice shall include at least the new interface specifications
            and a technical contact to answer questions on these changes. The
            Company or the Customer (as applicable) shall also provide up to 5
            days of integration testing availability to ensure smooth transition
            from the previous interfaces to the new interfaces.
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            In the event that the Company wishes to deprecate any API they will
            provide the Customer with six months prior written notice.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            6 CUSTOMER SUPPORT
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Service desk support is available for admin users and ambassadors
            via the Chat facility available from within the Admin dashboard or
            the Coligou.com website.
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            Any member of staff with a Coligou administrator account, or any
            Counsellors and/or Ambassador with an ambassador account is able to
            contact the help service via the Chat facility during Normal
            Business Hours. Outside these hours it is possible to leave a
            message. Initial responses will typically be within a few minutes
            during Normal Business Hours, and in any case within one Business
            Day.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            7 REQUESTS FOR ADDITIONAL FUNCTIONALITY
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Customers are encouraged to provide both formal and informal
            feedback on the platform and suggest ideas and areas for
            development. The platform is developed on a continuous basis.
            Decisions and priorities for development are made at the Company’s
            sole discretion on the basis of feedback from Customers and End
            Users, as well as the commercial and non-commercial imperatives of
            Coligou. Developments will be announced on an ongoing basis via
            regular email newsletters and occasional client events.
          </Typography>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default PlatformLicenceAgree;
