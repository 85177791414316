import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
  Menu,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./Header.module.css";
import { Link, useNavigate } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFEF00",
    },
  },
  typography: {
    body1: {
      fontFamily: ["CircularStd-book", "serif"].join(","),
      color: "white",
      textDecoration: "none",
      textTransform: "capitalize",
      fontWeight: 200,
      fontStyle: "normal",
      fontSize: "14px",
    },
    h3: {
      fontFamily: ["CircularStd-book", "serif"].join(","),
      fontSize: "18px",
    },
    Button: {
      fontFamily: ["CircularStd-book", "serif"].join(","),
      fontSize: "18px",
      color: "white",
      textTransform: "capitalize",
    },
  },
});

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //const [open, setOpen] = useState(false);
  //const handleOpen = () => setOpen(true);
  //const handleClose = () => setOpen(false);

  if (window.innerWidth <= 550) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/*<Box sx={{ backgroundColor: "#2A2044" }}>
          <Container align="right">
            <Stack
              direction="row"
              spacing={3}
              justifyContent="right"
              maxWidth="md"
            >
              <a
                href="https://www.facebook.com/Coligou/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                  fontSize="small"
                />
              </a>
              <a
                href="https://www.instagram.com/Coligou/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                  fontSize="small"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/coligou/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                  fontSize="small"
                />
              </a>
            </Stack>
          </Container>
        </Box> */}
        <AppBar
          sx={{
            backgroundColor: "#7640E7",
            height: "61px",
          }}
          position="initial"
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box
                sx={{
                  marginLeft: "40px",
                  marginRight: "38px",
                  display: { xs: "none", md: "flex" },
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <Link to="/">
                  <img
                    className={styles.logo}
                    src={require("../../assets/LOGO.svg")}
                    alt="logo"
                  />
                </Link>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <Link to="/">
                  <img
                    className={styles.logomobile}
                    src={require("../../assets/LOGO.svg")}
                    alt="logo"
                  />
                </Link>
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  display: { xs: "flex", md: "none" },
                  alignitems: "center",
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  align="right"
                >
                  <img src={require("../../assets/menu-icon.svg")} alt="menu" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  // PaperProps={{
                  //   style: {
                  //     position: "initial",
                  //     width: "100%",
                  //   },
                  // }}
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                  <Container
                    style={{
                      width: "335px",
                      backgroundColor: "rgba(118, 64, 231, 1)",
                      marginTop: "-8px",
                      marginBottom: "-8px",
                      paddingTop: "5px",
                      paddingBottom: "25px",
                    }}
                  >
                    <MenuItem
                      sx={{ justifyContent: "right" }}
                      onClick={handleCloseNavMenu}
                    >
                      <img
                        src={require("../../assets/x-icon.svg")}
                        alt="close"
                        style={{
                          width: "18px",
                        }}
                      />
                    </MenuItem>
                    <MenuItem
                      sx={{ justifyContent: "center" }}
                      onClick={handleCloseNavMenu}
                    >
                      <Typography variant="h3" textAlign="center">
                        <Link className={styles.menuItem} to="/">
                          Home
                        </Link>
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      sx={{ justifyContent: "center", paddingLeft: "20px" }}
                      onClick={handleCloseNavMenu}
                    >
                      <Typography variant="h3" textAlign="center">
                        <Link className={styles.menuItem} to="/uni">
                          Universities
                        </Link>
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{ justifyContent: "center", paddingLeft: "20px" }}
                      onClick={handleCloseNavMenu}
                    >
                      <Typography variant="h3" textAlign="center">
                        <Link className={styles.menuItem} to="/plans">
                          Universities Plans
                        </Link>
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{ justifyContent: "center" }}
                      onClick={handleCloseNavMenu}
                    >
                      <Typography variant="h3" textAlign="center">
                        <Link className={styles.menuItem} to="/students">
                          Students
                        </Link>
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{ justifyContent: "center" }}
                      onClick={handleCloseNavMenu}
                    >
                      <Typography variant="h3" textAlign="center">
                        <Link className={styles.menuItem} to="/elevate">
                          Schools & Colleges
                        </Link>
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      sx={{ justifyContent: "center" }}
                      onClick={handleCloseNavMenu}
                    >
                      <Typography variant="h3" textAlign="center">
                        <Link className={styles.menuItem} to="/aboutus">
                          About Us
                        </Link>
                      </Typography>
                    </MenuItem>
                  </Container>
                </Menu>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                }}
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ display: "block", marginLeft: "30px" }}
                  className={styles.tab}
                >
                  <Link to="/" className={styles.tabs}>
                    Home
                  </Link>
                </Button>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ display: "block", marginLeft: "30px" }}
                  className={styles.tab}
                >
                  <Link to="/uni" className={styles.tabs}>
                    Universities
                  </Link>
                </Button>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ display: "block", marginLeft: "30px" }}
                  className={styles.tab}
                >
                  <Link to="/students" className={styles.tabs}>
                    Students
                  </Link>
                </Button>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ display: "block" }}
                  className={styles.tab}
                >
                  <Link to="/elevate" className={styles.tabs}>
                    Schools & Colleges
                  </Link>
                </Button>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ display: "block", marginLeft: "30px" }}
                  className={styles.tab}
                >
                  <Link to="/aboutus" className={styles.tabs}>
                    About Us
                  </Link>
                </Button>

                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", md: "flex" },
                    alignitems: "right",
                  }}
                ></Box>
              </Box>

              <Box sx={{ flexGrow: 0 }}></Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/*<Box sx={{ backgroundColor: "#2A2044" }}>
          <Container align="right">
            <Stack
              direction="row"
              spacing={3}
              justifyContent="right"
              maxWidth="md"
            >
              <a
                href="https://www.facebook.com/Coligou/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                  fontSize="small"
                />
              </a>
              <a
                href="https://www.instagram.com/Coligou/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                  fontSize="small"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/coligou/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                  fontSize="small"
                />
              </a>
            </Stack>
          </Container>
        </Box> */}

        <AppBar
          sx={{
            backgroundColor: "#7640E7",
            maxHeight: "50px",
            marginTop: "-10px",
          }}
          position="fixed"
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box
                sx={{
                  marginLeft: "40px",
                  marginRight: "38px",
                  display: { xs: "none", md: "flex" },
                }}
              >
                <Link to="/">
                  <img
                    className={styles.logo}
                    src={require("../../assets/LOGO.svg")}
                    alt="logo"
                  />
                </Link>
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  display: { xs: "flex", md: "none" },
                  alignitems: "right",
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  align="right"
                >
                  <img src={require("../../assets/menu-icon.svg")} alt="menu" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                  <Container>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography variant="h3" textAlign="center">
                        <Link to="/">Home</Link>
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography variant="h3" textAlign="center">
                        <Link to="/uni">Universities</Link>
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography variant="h3" textAlign="center">
                        <Link to="/students">Students</Link>
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography variant="h3" textAlign="center">
                        <Link to="/elevate">Schools & Colleges</Link>
                      </Typography>
                    </MenuItem>

                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography variant="h3" textAlign="center">
                        <Link to="/aboutus">About Us</Link>
                      </Typography>
                    </MenuItem>
                  </Container>
                </Menu>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "none" },
                  alignitems: "center",
                }}
              >
                <Link to="/">
                  <img
                    className={styles.logomobile}
                    src={require("../../assets/LOGO.svg")}
                    alt="logo"
                  />
                </Link>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  alignitems: "right",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                ></Box>
                <Box
                  sx={{
                    flexGrow: 0,
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <Button
                    sx={{ display: "block" }}
                    className={styles.tab}
                    onClick={(e) => {
                      e.preventDefault();
                      setAnchorElNav(null);
                      navigate("/");
                    }}
                  >
                    <Typography variant="body1">Home</Typography>
                  </Button>
                  <Button
                    sx={{ display: "block", marginLeft: "20px" }}
                    className={styles.tab}
                    aria-controls={open ? "uni-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <Typography variant="body1">Universities</Typography>
                  </Button>
                  <Menu
                    id="uni-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem className={styles.submenu} onClick={handleClose}>
                      <Typography variant="h3" textAlign="center">
                        <Link className={styles.submenus} to="/uni">
                          Universities
                        </Link>
                      </Typography>
                    </MenuItem>
                    <MenuItem className={styles.submenu} onClick={handleClose}>
                      <Typography variant="h3" textAlign="center">
                        <Link className={styles.submenus} to="/plans">
                          Plans
                        </Link>
                      </Typography>
                    </MenuItem>
                  </Menu>
                  <Button
                    sx={{ display: "block", marginLeft: "20px" }}
                    className={styles.tab}
                    onClick={(e) => {
                      e.preventDefault();
                      setAnchorElNav(null);
                      navigate("/students");
                    }}
                  >
                    <Typography variant="body1">Students</Typography>
                  </Button>
                  <Button
                    sx={{ display: "block", marginLeft: "20px" }}
                    className={styles.tab}
                    onClick={(e) => {
                      e.preventDefault();
                      setAnchorElNav(null);
                      navigate("/elevate");
                    }}
                  >
                    <Typography variant="body1">Schools & Colleges</Typography>
                  </Button>
                  <Button
                    sx={{ display: "block", marginLeft: "20px" }}
                    className={styles.tab}
                    onClick={(e) => {
                      e.preventDefault();
                      setAnchorElNav(null);
                      navigate("/aboutus");
                    }}
                  >
                    <Typography variant="body1">About us</Typography>
                  </Button>
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
    );
  }
};

export default Header;
