import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#7640E7",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            borderRadius: "10px",
            border: "none",
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: ["CircularStd-black", "serif"].join(","),
      fontSize: "45px",
      color: "#000000",
      fontWeight: "900",
      fontStyle: "normal",
    },
    h2: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "40px",
      color: "#000000",
      fontWeight: "900",
      fontStyle: "normal",
    },
    h3: {
      color: "#000000",
      fontSize: "24px",
      fontWeight: "700",
      fontStyle: "normal",
    },
    h4: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "40px",
      color: "white",
      fontWeight: "900",
      fontStyle: "normal",
    },

    body1: {
      color: "#797979",
      fontSize: "18px",
      fontWeight: "450",
      fontStyle: "normal",
      textTransform: "none",
    },
    body2: {
      color: "white",
      fontSize: "20px",
      fontWeight: "900",
      fontStyle: "normal",
      textTransform: "none",
    },
    subtitle1: {
      color: "#000000",
      fontSize: "20px",
      fontWeight: "450",
      fontStyle: "normal",
    },
    subtitle2: {
      color: "#000000",
      fontSize: "16px",
      fontWeight: "450",
      fontStyle: "normal",
    },

    button: {
      fontFamily: ["CircularStd-book", "serif"].join(","),
      fontSize: "14px",
      color: "white",
      textDecoration: "none",
      fontWeight: "500",
      lineHeight: "20px",
      textTransform: "none",
    },
    fontFamily: ["CircularStd-book", "serif"].join(","),
  },
});

theme = responsiveFontSizes(theme);

export default theme;
