import React from "react";
import {
  Container,
  Grid,
  Stack,
  Typography,
  Box,
  Tabs,
  Tab,
  Card,
  CardContent,
  ThemeProvider,
  Button,
  Modal,
} from "@mui/material";
import PropTypes from "prop-types";
import ContactUs from "../../ContactForm/ContactForm";
import styles from "../LandingPageContent.module.css";
import { Link } from "react-router-dom";
import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#7640E7",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "white",
            color: "black",
            borderRadius: "10px",
            border: "none",
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: ["CircularStd-black", "serif"].join(","),
      fontSize: "35px",
      color: "#2a2044",
      fontWeight: "900",
      fontStyle: "normal",
    },
    h2: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "30px",
      color: "#2a2044",
      fontWeight: "900",
      fontStyle: "normal",
    },

    body1: {
      color: "#797979",
      fontSize: "16px",
      fontWeight: "450",
      fontStyle: "normal",
      textTransform: "none",
    },
    subtitle1: {
      color: "#000000",
      fontSize: "18px",
      fontWeight: "700",
      fontStyle: "normal",
    },

    button: {
      fontFamily: ["CircularStd-book", "serif"].join(","),
      fontSize: "14px",
      color: "white",
      textDecoration: "none",
      fontWeight: "500",
      lineHeight: "20px",
      textTransform: "none",
    },
    fontFamily: ["CircularStd-book", "serif"].join(","),
  },
});

let themeMobile = createTheme({
  palette: {
    primary: {
      main: "#7640E7",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "white",
            color: "black",
            borderRadius: "10px",
            width: "315px",
            border: "none",
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: ["CircularStd-black", "serif"].join(","),
      fontSize: "28px",
      color: "#000000",
      fontWeight: "900",
      fontStyle: "normal",
    },
    h2: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "22px",
      color: "#2a2044",
      fontWeight: "900",
      fontStyle: "normal",
    },

    body1: {
      color: "#797979",
      fontSize: "14px",
      fontWeight: "450",
      fontStyle: "normal",
    },
    subtitle1: {
      color: "#000000",
      fontSize: "16px",
      fontWeight: "700",
      fontStyle: "normal",
    },

    button: {
      fontFamily: ["CircularStd-book", "serif"].join(","),
      fontSize: "14px",
      color: "white",
      textDecoration: "none",
      fontWeight: "500",
      lineHeight: "20px",
      textTransform: "capitalize",
    },
    fontFamily: ["CircularStd-book", "serif"].join(","),
  },
});

theme = responsiveFontSizes(theme);
themeMobile = responsiveFontSizes(themeMobile);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabPanelContent = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (window.innerWidth <= 550) {
    return (
      <ThemeProvider theme={themeMobile}>
        <Box sx={{ width: "100%", paddingTop: "20px" }}>
          <Container align="center">
            <TabPanel value={value} index={0}>
              <Typography
                sx={{ fontSize: "28px", paddingBottom: "20px" }}
                variant="h1"
              >
                AI Personalised Engagement and Advanced Recruitment
              </Typography>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Typography
                sx={{ fontSize: "28px", paddingBottom: "20px" }}
                variant="h1"
              >
                AI Matching to Best-Fit Courses and Simple University Admissions
              </Typography>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Typography
                sx={{ fontSize: "28px", paddingBottom: "20px" }}
                variant="h1"
              >
                Enabling Schools & Colleges to Empower Young Students
              </Typography>
            </TabPanel>
          </Container>

          <Container align="center">
            <Box
              sx={{
                backgroundColor: "#7640E7",
                borderRadius: "10px",
                py: 2,
                width: "335px",
              }}
            >
              <Tabs
                orientation="vertical"
                alignItems="center"
                value={value}
                onChange={handleChange}
                indicatorColor="none"
                textColor="white"
                sx={{ marginLeft: "10px" }}
              >
                <Tab label="Universities" {...a11yProps(0)} />
                <Tab label="Students" {...a11yProps(1)} />
                <Tab label="Schools & Colleges" {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Container>
          <TabPanel value={value} index={0}>
            <Typography
              sx={{ paddingTop: "20px" }}
              variant="h2"
              color="#7640E7"
              textAlign="center"
            >
              How it Works For Universities
            </Typography>
            <Container align="center">
              <img
                src={require("../../../assets/landingpage_illustration2.svg")}
                alt="ColigouStudentDashboard"
                style={{
                  width: "231.78px",
                  paddingTop: "20px",
                  paddingBottom: "50px",
                }}
              />
            </Container>
            <img
              src={require("../../../assets/blue_circle.svg")}
              alt="Icon"
              style={{
                position: "absolute",
                marginTop: "-24px",
                marginLeft: "-16px",
                width: "150px",
              }}
            />
            <Card
              sx={{ width: "335px", height: "117px", position: "relative" }}
            >
              <CardContent>
                <Stack direction="row">
                  <Typography
                    sx={{ fontSize: "40px" }}
                    variant="h1"
                    color="#7640E7"
                  >
                    1
                  </Typography>
                  <Stack direction="column" sx={{ paddingLeft: "21px" }}>
                    <Typography variant="subtitle1" textAlign="left">
                      Book a Demo
                    </Typography>
                    <Typography
                      sx={{ paddingTop: "5px", width: "254px" }}
                      variant="body1"
                      textAlign="left"
                    >
                      Discover how our technology could help your university
                      with a free demo
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Container align="center">
              <img
                src={require("../../../assets/blue_arrow_down.svg")}
                alt="arrow"
                style={{
                  width: "16px",
                  paddingTop: "10px",
                  paddingBottom: "23px",
                }}
              />
            </Container>
            <img
              src={require("../../../assets/blue_circle.svg")}
              alt="Icon"
              style={{
                position: "absolute",
                marginTop: "-24px",
                marginLeft: "-16px",
                width: "150px",
              }}
            />
            <Card
              sx={{ width: "335px", height: "117px", position: "relative" }}
            >
              <CardContent>
                <Stack direction="row">
                  <Typography
                    sx={{ fontSize: "40px" }}
                    variant="h1"
                    color="#7640E7"
                  >
                    2
                  </Typography>
                  <Stack direction="column" sx={{ paddingLeft: "21px" }}>
                    <Typography variant="subtitle1" textAlign="left">
                      Integration{" "}
                    </Typography>
                    <Typography
                      sx={{ paddingTop: "5px", width: "254px" }}
                      variant="body1"
                      textAlign="left"
                    >
                      Our solutions integrate seamlessly into your university
                      website
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Container align="center">
              <img
                src={require("../../../assets/blue_arrow_down.svg")}
                alt="arrow"
                style={{
                  width: "16px",
                  paddingTop: "10px",
                  paddingBottom: "23px",
                }}
              />
            </Container>
            <img
              src={require("../../../assets/blue_circle.svg")}
              alt="Icon"
              style={{
                position: "absolute",
                marginTop: "-24px",
                marginLeft: "-16px",
                width: "150px",
              }}
            />
            <Card
              sx={{ width: "335px", height: "117px", position: "relative" }}
            >
              <CardContent>
                <Stack direction="row">
                  <Typography
                    sx={{ fontSize: "40px" }}
                    variant="h1"
                    color="#7640E7"
                  >
                    3
                  </Typography>
                  <Stack direction="column" sx={{ paddingLeft: "21px" }}>
                    <Typography variant="subtitle1" textAlign="left">
                      Engage and Recruit{" "}
                    </Typography>
                    <Typography
                      sx={{ paddingTop: "5px", width: "254px" }}
                      variant="body1"
                      textAlign="left"
                    >
                      Using AI and data science to increase prospect conversion,
                      improve engagement and boost recruitment
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Button
              style={{
                width: "335px",
                height: "43px",
                marginTop: "50px",
                borderRadius: "10px",
              }}
              variant="contained"
              onClick={handleOpen}
            >
              <Typography variant="button">Book a Demo</Typography>
            </Button>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography
              sx={{ paddingTop: "20px" }}
              variant="h2"
              color="#7640E7"
              textAlign="center"
            >
              How it Works For Students{" "}
            </Typography>
            <Container align="center">
              <img
                src={require("../../../assets/landingpage_illustration3.svg")}
                alt="ColigouStudentDashboard"
                style={{
                  width: "247.84px",
                  paddingTop: "20px",
                  paddingBottom: "50px",
                }}
              />
            </Container>
            <img
              src={require("../../../assets/blue_circle.svg")}
              alt="Icon"
              style={{
                position: "absolute",
                marginTop: "-24px",
                marginLeft: "-16px",
                width: "150px",
              }}
            />
            <Card
              sx={{ width: "335px", height: "117px", position: "relative" }}
            >
              <CardContent>
                <Stack direction="row">
                  <Typography
                    sx={{ fontSize: "40px" }}
                    variant="h1"
                    color="#7640E7"
                  >
                    1
                  </Typography>
                  <Stack direction="column" sx={{ paddingLeft: "21px" }}>
                    <Typography variant="subtitle1" textAlign="left">
                      Discover Courses
                    </Typography>
                    <Typography
                      sx={{ paddingTop: "5px", width: "254px" }}
                      variant="body1"
                      textAlign="left"
                    >
                      Explore courses from over 200 universities worldwide and
                      find you best-fit match
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Container align="center">
              <img
                src={require("../../../assets/blue_arrow_down.svg")}
                alt="arrow"
                style={{
                  width: "16px",
                  paddingTop: "10px",
                  paddingBottom: "23px",
                }}
              />
            </Container>
            <img
              src={require("../../../assets/blue_circle.svg")}
              alt="Icon"
              style={{
                position: "absolute",
                marginTop: "-24px",
                marginLeft: "-16px",
                width: "150px",
              }}
            />
            <Card
              sx={{ width: "335px", height: "117px", position: "relative" }}
            >
              <CardContent>
                <Stack direction="row">
                  <Typography
                    sx={{ fontSize: "40px" }}
                    variant="h1"
                    color="#7640E7"
                  >
                    2
                  </Typography>
                  <Stack direction="column" sx={{ paddingLeft: "21px" }}>
                    <Typography variant="subtitle1" textAlign="left">
                      Ask Questions{" "}
                    </Typography>
                    <Typography
                      sx={{ paddingTop: "5px", width: "254px" }}
                      variant="body1"
                      textAlign="left"
                    >
                      Chat with our counselors and students at every stage of
                      your application process
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Container align="center">
              <img
                src={require("../../../assets/blue_arrow_down.svg")}
                alt="arrow"
                style={{
                  width: "16px",
                  paddingTop: "10px",
                  paddingBottom: "23px",
                }}
              />
            </Container>
            <img
              src={require("../../../assets/blue_circle.svg")}
              alt="Icon"
              style={{
                position: "absolute",
                marginTop: "-24px",
                marginLeft: "-16px",
                width: "150px",
              }}
            />
            <Card
              sx={{ width: "335px", height: "117px", position: "relative" }}
            >
              <CardContent>
                <Stack direction="row">
                  <Typography
                    sx={{ fontSize: "40px" }}
                    variant="h1"
                    color="#7640E7"
                  >
                    3
                  </Typography>
                  <Stack direction="column" sx={{ paddingLeft: "21px" }}>
                    <Typography variant="subtitle1" textAlign="left">
                      Apply Easily{" "}
                    </Typography>
                    <Typography
                      sx={{ paddingTop: "5px", width: "254px" }}
                      variant="body1"
                      textAlign="left"
                    >
                      Apply easily to universities with a click of a button and
                      receive your offers
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Button
              style={{
                width: "335px",
                height: "43px",
                marginTop: "50px",
                borderRadius: "10px",
              }}
              variant="contained"
            >
              <Link to="/students" className={styles.linktext2}>
                Discover Courses
              </Link>{" "}
            </Button>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Container align="center">
              <Typography
                sx={{ paddingTop: "20px", width: "219px" }}
                variant="h2"
                color="#7640E7"
                textAlign="center"
              >
                How it works for Schools & Colleges
              </Typography>
              <img
                src={require("../../../assets/landingpage_illustration4.svg")}
                alt="ColigouStudentDashboard"
                style={{
                  width: "224.38px",
                  paddingTop: "20px",
                  paddingBottom: "50px",
                }}
              />
            </Container>
            <img
              src={require("../../../assets/blue_circle.svg")}
              alt="Icon"
              style={{
                position: "absolute",
                marginTop: "-24px",
                marginLeft: "-16px",
                width: "150px",
              }}
            />
            <Card
              sx={{ width: "335px", height: "117px", position: "relative" }}
            >
              <CardContent>
                <Stack direction="row">
                  <Typography
                    sx={{ fontSize: "40px" }}
                    variant="h1"
                    color="#7640E7"
                  >
                    1
                  </Typography>
                  <Stack direction="column" sx={{ paddingLeft: "21px" }}>
                    <Typography variant="subtitle1" textAlign="left">
                      Book a Demo
                    </Typography>
                    <Typography
                      sx={{ paddingTop: "5px", width: "254px" }}
                      variant="body1"
                      textAlign="left"
                    >
                      Discover how our technology could help your School or
                      College with a free demo.
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Container align="center">
              <img
                src={require("../../../assets/blue_arrow_down.svg")}
                alt="arrow"
                style={{
                  width: "16px",
                  paddingTop: "10px",
                  paddingBottom: "23px",
                }}
              />
            </Container>
            <img
              src={require("../../../assets/blue_circle.svg")}
              alt="Icon"
              style={{
                position: "absolute",
                marginTop: "-24px",
                marginLeft: "-16px",
                width: "150px",
              }}
            />
            <Card
              sx={{ width: "335px", height: "117px", position: "relative" }}
            >
              <CardContent>
                <Stack direction="row">
                  <Typography
                    sx={{ fontSize: "40px" }}
                    variant="h1"
                    color="#7640E7"
                  >
                    2
                  </Typography>
                  <Stack direction="column" sx={{ paddingLeft: "21px" }}>
                    <Typography variant="subtitle1" textAlign="left">
                      Customisation{" "}
                    </Typography>
                    <Typography
                      sx={{ paddingTop: "5px", width: "254px" }}
                      variant="body1"
                      textAlign="left"
                    >
                      We will work with you to setup your customised school
                      application to empower your students
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Container align="center">
              <img
                src={require("../../../assets/blue_arrow_down.svg")}
                alt="arrow"
                style={{
                  width: "16px",
                  paddingTop: "10px",
                  paddingBottom: "23px",
                }}
              />
            </Container>
            <img
              src={require("../../../assets/blue_circle.svg")}
              alt="Icon"
              style={{
                position: "absolute",
                marginTop: "-24px",
                marginLeft: "-16px",
                width: "150px",
              }}
            />
            <Card
              sx={{ width: "335px", height: "117px", position: "relative" }}
            >
              <CardContent>
                <Stack direction="row">
                  <Typography
                    sx={{ fontSize: "40px" }}
                    variant="h1"
                    color="#7640E7"
                  >
                    3
                  </Typography>
                  <Stack direction="column" sx={{ paddingLeft: "21px" }}>
                    <Typography variant="subtitle1" textAlign="left">
                      University Applications{" "}
                    </Typography>
                    <Typography
                      sx={{ paddingTop: "5px", width: "254px" }}
                      variant="body1"
                      textAlign="left"
                    >
                      Prepare your students for personalised university
                      admission with all they need in one place
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Button
              style={{
                width: "335px",
                height: "43px",
                marginTop: "50px",
                borderRadius: "10px",
              }}
              variant="contained"
              onClick={handleOpen}
            >
              <Typography variant="button">Book a Demo</Typography>
            </Button>
          </TabPanel>
        </Box>
        <Modal style={{ overflow: "scroll" }} open={open} onClose={handleClose}>
          <Box sx={{ pt: 8 }} align="center">
            <Container align="center">
              <ContactUs closeHandler={handleClose} />
            </Container>
          </Box>
        </Modal>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ width: "100%" }}>
          <Container sx={{ width: "779px" }}>
            <TabPanel value={value} index={0}>
              <Typography sx={{ width: "653px" }} variant="h1" gutterBottom>
                AI Personalised Engagement and Advanced Recruitment
              </Typography>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Typography variant="h1" gutterBottom>
                AI Matching to Best-Fit Courses and Simple University Admissions
              </Typography>
            </TabPanel>
            <TabPanel sx={{ width: "500px" }} value={value} index={2}>
              <Typography variant="h1" gutterBottom>
                Enabling Schools & Colleges to Empower Young Students{" "}
              </Typography>
            </TabPanel>
          </Container>

          <Box
            sx={{
              backgroundColor: "#7640E7",
              borderRadius: "10px",
              width: "850px",
              py: 2,
              marginBottom: "30px",
              marginTop: "50px",
            }}
          >
            <Tabs
              centered
              value={value}
              onChange={handleChange}
              textColor="rgba(255, 255, 255, 1)"
            >
              <Tab
                sx={{ fontSize: "18px" }}
                label="Universities"
                {...a11yProps(0)}
              />
              <Tab
                sx={{
                  fontSize: "18px",
                  marginLeft: "100px",
                }}
                label="Students"
                {...a11yProps(1)}
              />
              <Tab
                sx={{ fontSize: "18px", marginLeft: "100px" }}
                label="Schools & Colleges"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Container align="center">
              <Stack direction="column" alignItems="center">
                <Typography variant="h2" color="#7640E7">
                  How it Works For Universities
                </Typography>
                <img
                  src={require("../../../assets/landingpage_illustration2.svg")}
                  alt="ColigouStudentDashboard"
                  style={{
                    height: "300px",
                    paddingTop: "24px",
                    paddingBottom: "86px",
                  }}
                />
              </Stack>
              <Grid container align="left">
                <Grid item lg={4} md={4} sm={12}>
                  <Stack direction="row">
                    <Container>
                      <img
                        src={require("../../../assets/blue_circle.svg")}
                        alt="Icon"
                        style={{
                          position: "absolute",
                          marginTop: "-51px",
                          marginLeft: "-22px",
                          width: "202px",
                          height: "189px",
                        }}
                      />
                      <Card
                        sx={{
                          width: "267px",
                          height: "180px",
                          position: "relative",
                        }}
                      >
                        <CardContent>
                          <Stack direction="row">
                            <Typography
                              style={{ fontSize: "60px" }}
                              variant="h2"
                              color="#7640E7"
                            >
                              1
                            </Typography>
                            <Stack
                              sx={{ paddingLeft: "21px" }}
                              direction="column"
                            >
                              <Typography
                                variant="subtitle1"
                                textAlign="left"
                                gutterBottom
                              >
                                Book a Demo
                              </Typography>
                              <Typography
                                sx={{ paddingTop: "5px", width: "179px" }}
                                variant="body1"
                                textAlign="left"
                              >
                                Discover how our technology could help your
                                university with a free demo.
                              </Typography>
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Container>
                    {/* <img
                    src={require("../../../assets/blue_arrow.svg")}
                    alt="Icon"
                    style={{
                      position: "absolute",
                      marginLeft: "-180px",
                      
                    }}
                  /> */}
                    <img
                      src={require("../../../assets/blue_arrow.svg")}
                      alt="Icon"
                    />
                  </Stack>
                </Grid>
                <Grid item lg={4} md={4} sm={12} sx={{ paddingLeft: "20px" }}>
                  <Stack direction="row">
                    <Container>
                      <img
                        src={require("../../../assets/blue_circle.svg")}
                        alt="Icon"
                        style={{
                          position: "absolute",
                          marginTop: "-51px",
                          marginLeft: "-22px",

                          width: "202px",
                          height: "189px",
                        }}
                      />
                      <Card
                        sx={{
                          width: "267px",
                          height: "180px",
                          position: "relative",
                        }}
                      >
                        <CardContent>
                          <Stack direction="row" textAlign="left">
                            <Typography
                              variant="h2"
                              color="#7640E7"
                              style={{ fontSize: "60px" }}
                            >
                              2
                            </Typography>
                            <Stack
                              sx={{ paddingLeft: "21px" }}
                              direction="column"
                            >
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                textAlign="left"
                              >
                                Integration
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ paddingTop: "5px", width: "179px" }}
                                textAlign="left"
                              >
                                Our solutions integrate seamlessly into your
                                university website with a single line of code.
                              </Typography>
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Container>
                    <img
                      src={require("../../../assets/blue_arrow.svg")}
                      alt="Icon"
                    />
                  </Stack>
                </Grid>
                <Grid item lg={4} md={4} sm={12} sx={{ paddingLeft: "40px" }}>
                  <Container>
                    <img
                      src={require("../../../assets/blue_circle.svg")}
                      alt="Icon"
                      style={{
                        position: "absolute",
                        marginTop: "-51px",
                        marginLeft: "-22px",

                        width: "202px",
                        height: "189px",
                      }}
                    />
                    <Card
                      sx={{
                        width: "267px",
                        height: "180px",
                        position: "relative",
                      }}
                    >
                      <CardContent>
                        <Stack direction="row" textAlign="left">
                          <Typography
                            variant="h2"
                            color="#7640E7"
                            style={{ fontSize: "60px" }}
                          >
                            3
                          </Typography>
                          <Stack
                            sx={{ paddingLeft: "21px" }}
                            direction="column"
                          >
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              textAlign="left"
                            >
                              Engage and Recruit
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ paddingTop: "5px", width: "179px" }}
                              textAlign="left"
                            >
                              Leverage AI to drive prospect conversion, improve
                              engagement and boost recruitment.
                            </Typography>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Container>
                </Grid>
              </Grid>
              <Stack sx={{ alignItems: "center" }}>
                <Button
                  style={{
                    width: "266px",
                    height: "51px",
                    borderRadius: "10px",
                    marginTop: "50px",
                  }}
                  variant="contained"
                  onClick={handleOpen}
                >
                  <Typography variant="button">Book a Demo</Typography>
                </Button>
              </Stack>
            </Container>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Container align="center">
              <Stack direction="column" alignItems="center">
                <Typography variant="h2" color="#7640E7">
                  How it Works For Students
                </Typography>
                <img
                  src={require("../../../assets/landingpage_illustration3.svg")}
                  alt="ColigouStudentDashboard"
                  style={{
                    height: "300px",
                    paddingTop: "24px",
                    paddingBottom: "86px",
                  }}
                />
              </Stack>
              <Grid container align="left">
                <Grid item lg={4} md={4} sm={12}>
                  <Stack direction="row">
                    <Container>
                      <img
                        src={require("../../../assets/blue_circle.svg")}
                        alt="Icon"
                        style={{
                          position: "absolute",
                          marginTop: "-51px",
                          marginLeft: "-22px",

                          width: "202px",
                          height: "189px",
                        }}
                      />
                      <Card
                        sx={{
                          width: "267px",
                          height: "180px",
                          position: "relative",
                        }}
                      >
                        <CardContent>
                          <Stack direction="row" textAlign="left">
                            <Typography
                              style={{ fontSize: "60px" }}
                              variant="h2"
                              color="#7640E7"
                            >
                              1
                            </Typography>
                            <Stack
                              sx={{ paddingLeft: "21px" }}
                              direction="column"
                            >
                              <Typography
                                variant="subtitle1"
                                textAlign="left"
                                gutterBottom
                              >
                                Discover Courses{" "}
                              </Typography>
                              <Typography
                                sx={{ paddingTop: "5px", width: "179px" }}
                                variant="body1"
                                textAlign="left"
                              >
                                Explore courses from over 200 universities
                                worldwide and find you best-fit match.
                              </Typography>
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Card>
                      {/* <img
                    src={require("../../../assets/blue_arrow.svg")}
                    alt="Icon"
                    style={{
                      position: "absolute",
                      marginLeft: "-180px",
                      
                    }}
                  /> */}
                    </Container>
                    <img
                      src={require("../../../assets/blue_arrow.svg")}
                      alt="Icon"
                    />
                  </Stack>
                </Grid>
                <Grid item lg={4} md={4} sm={12} sx={{ paddingLeft: "20px" }}>
                  <Stack direction="row">
                    <Container>
                      <img
                        src={require("../../../assets/blue_circle.svg")}
                        alt="Icon"
                        style={{
                          position: "absolute",
                          marginTop: "-51px",
                          marginLeft: "-22px",

                          width: "202px",
                          height: "189px",
                        }}
                      />
                      <Card
                        sx={{
                          width: "267px",
                          height: "180px",
                          position: "relative",
                        }}
                      >
                        <CardContent>
                          <Stack direction="row" textAlign="left">
                            <Typography
                              variant="h2"
                              color="#7640E7"
                              style={{ fontSize: "60px" }}
                            >
                              2
                            </Typography>
                            <Stack
                              sx={{ paddingLeft: "21px" }}
                              direction="column"
                            >
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                textAlign="left"
                              >
                                Ask Questions
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ paddingTop: "5px", width: "179px" }}
                                textAlign="left"
                              >
                                Chat with our counselors and students at every
                                stage of your application process.
                              </Typography>
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Container>
                    <img
                      src={require("../../../assets/blue_arrow.svg")}
                      alt="Icon"
                    />
                  </Stack>
                </Grid>
                <Grid item lg={4} md={4} sm={12} sx={{ paddingLeft: "40px" }}>
                  <Container>
                    <img
                      src={require("../../../assets/blue_circle.svg")}
                      alt="Icon"
                      style={{
                        position: "absolute",
                        marginTop: "-51px",
                        marginLeft: "-22px",

                        width: "202px",
                        height: "189px",
                      }}
                    />
                    <Card
                      sx={{
                        width: "267px",
                        height: "180px",
                        position: "relative",
                      }}
                    >
                      <CardContent>
                        <Stack direction="row" textAlign="left">
                          <Typography
                            variant="h2"
                            color="#7640E7"
                            style={{ fontSize: "60px" }}
                          >
                            3
                          </Typography>
                          <Stack
                            sx={{ paddingLeft: "21px" }}
                            direction="column"
                          >
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              textAlign="left"
                            >
                              Apply Easily{" "}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ paddingTop: "5px", width: "179px" }}
                              textAlign="left"
                            >
                              Apply easily to universities with a click of a
                              button and receive your offers.
                            </Typography>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Container>
                </Grid>
              </Grid>
              <Stack sx={{ alignItems: "center" }}>
                <Button
                  style={{
                    width: "266px",
                    height: "51px",
                    borderRadius: "10px",
                    marginTop: "50px",
                  }}
                  variant="contained"
                >
                  <Link to="/students" className={styles.linktext2}>
                    Discover Courses
                  </Link>
                </Button>
              </Stack>
            </Container>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Container align="center">
              <Stack direction="column" alignItems="center">
                <Typography variant="h2" color="#7640E7">
                  How it Works For Schools & Colleges
                </Typography>
                <img
                  src={require("../../../assets/landingpage_illustration4.svg")}
                  alt="ColigouStudentDashboard"
                  style={{
                    height: "300px",
                    paddingTop: "24px",
                    paddingBottom: "86px",
                  }}
                />
              </Stack>
              <Grid container align="left">
                <Grid item lg={4} md={4} sm={12}>
                  <Stack direction="row">
                    <Container>
                      <img
                        src={require("../../../assets/blue_circle.svg")}
                        alt="Icon"
                        style={{
                          position: "absolute",
                          marginTop: "-51px",
                          marginLeft: "-22px",

                          width: "202px",
                          height: "189px",
                        }}
                      />
                      <Card
                        sx={{
                          width: "267px",
                          height: "180px",
                          position: "relative",
                        }}
                      >
                        <CardContent>
                          <Stack direction="row" textAlign="left">
                            <Typography
                              variant="h2"
                              color="#7640E7"
                              style={{ fontSize: "60px" }}
                            >
                              1
                            </Typography>
                            <Stack
                              sx={{ paddingLeft: "21px" }}
                              direction="column"
                            >
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                textAlign="left"
                              >
                                Book a Demo
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ paddingTop: "5px", width: "179px" }}
                                textAlign="left"
                              >
                                Discover how our technology could help your
                                School or College with a free demo.
                              </Typography>
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Card>
                      {/* <img
                    src={require("../../../assets/blue_arrow.svg")}
                    alt="Icon"
                    style={{
                      position: "absolute",
                      marginLeft: "-180px",
                      
                    }}
                  /> */}
                    </Container>
                    <img
                      src={require("../../../assets/blue_arrow.svg")}
                      alt="Icon"
                    />
                  </Stack>
                </Grid>
                <Grid item lg={4} md={4} sm={12} sx={{ paddingLeft: "20px" }}>
                  <Stack direction="row">
                    <Container>
                      <img
                        src={require("../../../assets/blue_circle.svg")}
                        alt="Icon"
                        style={{
                          position: "absolute",
                          marginTop: "-51px",
                          marginLeft: "-22px",

                          width: "202px",
                          height: "189px",
                        }}
                      />
                      <Card
                        sx={{
                          width: "267px",
                          height: "180px",
                          position: "relative",
                        }}
                      >
                        <CardContent>
                          <Stack direction="row" textAlign="left">
                            <Typography
                              variant="h2"
                              color="#7640E7"
                              style={{ fontSize: "60px" }}
                            >
                              2
                            </Typography>
                            <Stack
                              sx={{ paddingLeft: "21px" }}
                              direction="column"
                            >
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                textAlign="left"
                              >
                                Customised App
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ paddingTop: "5px", width: "179px" }}
                                textAlign="left"
                              >
                                We will work with you to setup your customised
                                school application to empower your students.
                              </Typography>
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Container>
                    <img
                      src={require("../../../assets/blue_arrow.svg")}
                      alt="Icon"
                    />
                  </Stack>
                </Grid>
                <Grid item lg={4} md={4} sm={12} sx={{ paddingLeft: "40px" }}>
                  <Container>
                    <img
                      src={require("../../../assets/blue_circle.svg")}
                      alt="Icon"
                      style={{
                        position: "absolute",
                        marginTop: "-51px",
                        marginLeft: "-22px",

                        width: "202px",
                        height: "189px",
                      }}
                    />
                    <Card
                      sx={{
                        width: "267px",
                        height: "180px",
                        position: "relative",
                      }}
                    >
                      <CardContent>
                        <Stack direction="row" textAlign="left">
                          <Typography
                            variant="h2"
                            color="#7640E7"
                            style={{ fontSize: "60px" }}
                          >
                            3
                          </Typography>
                          <Stack
                            sx={{ paddingLeft: "21px" }}
                            direction="column"
                          >
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              textAlign="left"
                            >
                              Univeristy Admission{" "}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ paddingTop: "5px", width: "179px" }}
                              textAlign="left"
                            >
                              Prepare your students for university admission
                              with everything they need in one place.
                            </Typography>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Container>
                </Grid>
              </Grid>
              <Stack sx={{ alignItems: "center" }}>
                <Button
                  style={{
                    width: "266px",
                    height: "51px",
                    borderRadius: "10px",
                    marginTop: "50px",
                  }}
                  variant="contained"
                  onClick={handleOpen}
                >
                  <Typography variant="button">Book a Demo</Typography>
                </Button>
              </Stack>
            </Container>
          </TabPanel>
        </Box>
        <Modal style={{ overflow: "scroll" }} open={open} onClose={handleClose}>
          <Box sx={{ pt: 8 }} align="center">
            <Container align="center">
              <ContactUs closeHandler={handleClose} />
            </Container>
          </Box>
        </Modal>
      </ThemeProvider>
    );
  }
};

export default TabPanelContent;
