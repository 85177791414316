import React from "react";
import { CardDef } from "../utils/Cards";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import { colors } from "../utils/_var";

const Wrapper = styled.div`
  background-color: ${colors.$colorBg};
  position: fixed;
  min-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ISTJ = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const ISFJ = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const INFJ = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const INTJ = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const ISTP = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const ISFP = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const INFP = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const INTP = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const ESTP = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const ESFP = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const ENFP = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const ENTP = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const ESTJ = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const ESFJ = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const ENFJ = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};

export const ENTJ = ({ title, content, onBackClick }) => {
  return (
    <Wrapper className="container">
      <CardDef>
        <h1>{title}</h1>
        <div className="close">
          <IconButton id="Close" onClick={onBackClick}>
            X
          </IconButton>
        </div>
        <p className="text-justify">{content}</p>
      </CardDef>
    </Wrapper>
  );
};
