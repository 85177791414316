import React, { Component } from "react";
import PropTypes from "prop-types";
import { Wrapper } from "../utils/ResultWrapper";
import {
  ISTJ,
  ISFJ,
  INFJ,
  INTJ,
  ISTP,
  ISFP,
  INFP,
  INTP,
  ESTP,
  ESFP,
  ENFP,
  ENTP,
  ESTJ,
  ESFJ,
  ENFJ,
  ENTJ,
} from "../definitions/BriggsDef";
class Briggs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showISTJ: false,
      showISFJ: false,
      showINFJ: false,
      showINTJ: false,
      showISTP: false,
      showISFP: false,
      showINFP: false,
      showINTP: false,
      showESTP: false,
      showESFP: false,
      showENFP: false,
      showENTP: false,
      showESTJ: false,
      showESFJ: false,
      showENFJ: false,
      showENTJ: false,
    };
    this.onISTJ_click = this.onISTJ_click.bind(this);
    this.onISFJ_click = this.onISFJ_click.bind(this);
    this.onINFJ_click = this.onINFJ_click.bind(this);
    this.onINTJ_click = this.onINTJ_click.bind(this);
    this.onISTP_click = this.onISTP_click.bind(this);
    this.onISFP_click = this.onISFP_click.bind(this);
    this.onINFP_click = this.onINFP_click.bind(this);
    this.onINTP_click = this.onINTP_click.bind(this);
    this.onESTP_click = this.onESTP_click.bind(this);
    this.onESFP_click = this.onESFP_click.bind(this);
    this.onENFP_click = this.onENFP_click.bind(this);
    this.onENTP_click = this.onENTP_click.bind(this);
    this.onESTJ_click = this.onESTJ_click.bind(this);
    this.onESFJ_click = this.onESFJ_click.bind(this);
    this.onENFJ_click = this.onENFJ_click.bind(this);
    this.onENTJ_click = this.onENTJ_click.bind(this);
  }
  renderISTJ() {
    return (
      <ISTJ
        title={"Introvert | Sensing | Thinking | Judging"}
        content={`You are a Logistician.
                You tend to be practical and logical above all else.
                your top 5 job matches are 
                Engineering , Finance , Accounting`}
        onBackClick={this.onISTJ_click}
      />
    );
  }
  renderISFJ() {
    return (
      <ISFJ
        title={"Introvert | Sensing | Feeling | Judging"}
        content={`You are a Protector.
                  You tend to be sympathetic and organized above all else.
                  Your top job matches 
                  Architect
                  Auditor 
                  teaching
                  pediatric medicine`}
        onBackClick={this.onISFJ_click}
      />
    );
  }
  renderINFJ() {
    return (
      <INFJ
        title={"Introvert | iNtuitive | Feeling | Judging"}
        content={`You are a Counselor.
                You tend to be sensitive and creative above all else.
                Your top job matches 
                Pharmacist
                Physician
                Dentist
                Dietitian
                Banker`}
        onBackClick={this.onINFJ_click}
      />
    );
  }
  renderINTJ() {
    return (
      <INTJ
        title={"Introvert | iNtuitive | Thinking | Judging"}
        content={`You are a Strategic Thinker.
                    You tend to be decisive and insightful above all else.
                    Your top 3 field matches are ; Biotechnology
                     Business analysis
                     Software development`}
        onBackClick={this.onINTJ_click}
      />
    );
  }
  renderISTP() {
    return (
      <ISTP
        title={"Introvert | Sensing | Thinking | Perceiving"}
        content={`You are a Carfter.
                  You tend to be analytical and practical above all else.
                  Your top 3 field matches are ; Computer engineering, interior design, agriculture `}
        onBackClick={this.onISTP_click}
      />
    );
  }
  renderISFP() {
    return (
      <ISFP
        title={"Introvert | Sensing | Feeling | Perceiving"}
        content={`You are a Composer.
                  You tend to be loyal and adaptable above all else.
                  Your top job matches are
                  Chemist
                  Computer programmer
                  System analyst
                  Lawyer
                  Teacher`}
        onBackClick={this.onISFP_click}
      />
    );
  }
  renderINFP() {
    return (
      <INFP
        title={"Introvert | iNtuitive | Feeling | Perceiving"}
        content={`You are a Healer.
                  You tend to be empathetic and inquisitive above all else.
                  Artist
                  Biologist
                  Designer
                  Engineer`}
        onBackClick={this.onINFP_click}
      />
    );
  }
  renderINTP() {
    return (
      <INTP
        title={"Introvert | iNtuitive | Thinking | Perceiving"}
        content={`You are an inventor.
                  You tend to be curious and analytical above all else.
                  Architect
                  Writer
                  Biomedical engineer
                  Economist`}
        onBackClick={this.onINTP_click}
      />
    );
  }
  renderESTP() {
    return (
      <ESTP
        title={"Extravert | Sensing | Thinking | Perceiving"}
        content={`You are a Promoter.
                  You tend to be energetic and realistic above all else.
                  your top job matches are
                  Desntist
                  Data analyst
                  Consultant
                  Fitness instructor
                  Sales manager`}
        onBackClick={this.onESTP_click}
      />
    );
  }
  renderESFP() {
    return (
      <ESFP
        title={"Extravert | Sensing | Feeling | Perceiving"}
        content={`You are a Performer.
                  You tend to be caring and resourceful above all else.
                  Your top job matches are
                  Teacher
                  public health worker
                  merchandise planner`}
        onBackClick={this.onESFP_click}
      />
    );
  }
  renderENFP() {
    return (
      <ENFP
        title={"Extravert | iNtuitive | Feeling | Perceiving"}
        content={`You are an inspirer.
                  You tend to be imaginative and insightful above all else.
                  Your top job matches are
                   Language specialist
                   Advertising creative director
                   Engineer
                   Entrepeneur
                   Human resources manager`}
        onBackClick={this.onENFP_click}
      />
    );
  }
  renderENTP() {
    return (
      <ENTP
        title={"Extravert | iNtuitive | Thinking | Perceiving"}
        content={`You are a Visionary.
                  You tend to be enthusiastic and theoretical above all else.
                  Your top job matches are
                  Biologist
                  Computer programmer
                  Enterpeneur
                  Financial planner
                  Healthcare worker`}
        onBackClick={this.onENTP_click}
      />
    );
  }
  renderESTJ() {
    return (
      <ESTJ
        title={"Extravert | Sensing | Thinking | Judging"}
        content={`You are an ExecutIve.
                  You tend to be logical and assertive above all else.
                  Your top job matches are
                  Chief information officer
                  Computer analyst
                  Database manager
                  Economics analyst
                  Franchise owner
                  General contractor
                  Health service administrator`}
        onBackClick={this.onESTJ_click}
      />
    );
  }
  renderESFJ() {
    return (
      <ESFJ
        title={"Extravert | Sensing | Feeling | Judging"}
        content={`You are an Aspirer.
                  You tend to be sociable and caring above all else.
                  Your top job matches are
                  Legal assistant
                  logistics adminstrator
                  Management Consultant
                  Medical technician`}
        onBackClick={this.onESFJ_click}
      />
    );
  }
  renderENFJ() {
    return (
      <ENFJ
        title={"Extravert | iNtuitive | Feeling | Judging"}
        content={`You are a Care giver.
                  You tend to be passionate and imaginative above all else.
                  your top job matches are
                  Teaching
                  Artists
                  Translator
                  Nurse 
                  Hospitality personal `}
        onBackClick={this.onENFJ_click}
      />
    );
  }
  renderENTJ() {
    return (
      <ENTJ
        title={"Extravert | iNtuitive | Thinking | Judging"}
        content={`You are a Leader.
                  You tend to be organized and logical above all else.
                  Your top job matches are
                  Lawyer
                  Consultant
                  Management
                  Politician`}
        onBackClick={this.onENTJ_click}
      />
    );
  }
  render() {
    let showISTJ = this.state.showISTJ;
    let showISFJ = this.state.showISFJ;
    let showINFJ = this.state.showINFJ;
    let showINTJ = this.state.showINTJ;
    let showISTP = this.state.showISTP;
    let showISFP = this.state.showISFP;
    let showINFP = this.state.showINFP;
    let showINTP = this.state.showINTP;
    let showESTP = this.state.showESTP;
    let showESFP = this.state.showESFP;
    let showENFP = this.state.showENFP;
    let showENTP = this.state.showENTP;
    let showESTJ = this.state.showESTJ;
    let showESFJ = this.state.showESFJ;
    let showENFJ = this.state.showENFJ;
    let showENTJ = this.state.showENTJ;
    if (showISTJ) {
      return this.renderISTJ();
    } else if (showISFJ) {
      return this.renderISFJ();
    } else if (showINFJ) {
      return this.renderINFJ();
    } else if (showINTJ) {
      return this.renderINTJ();
    } else if (showISTP) {
      return this.renderISTP();
    } else if (showISFP) {
      return this.renderISFP();
    } else if (showINFP) {
      return this.renderINFP();
    } else if (showINTP) {
      return this.renderINTP();
    } else if (showESTP) {
      return this.renderESTP();
    } else if (showESFP) {
      return this.renderESFP();
    } else if (showENFP) {
      return this.renderENFP();
    } else if (showENTP) {
      return this.renderENTP();
    } else if (showESTJ) {
      return this.renderESTJ();
    } else if (showESFJ) {
      return this.renderESFJ();
    } else if (showENFJ) {
      return this.renderENFJ();
    } else if (showENTJ) {
      return this.renderENTJ();
    }
    return (
      <Wrapper>
        <h1 className="display-3 title">Briggs Result</h1>
        <hr className="my-4" />
        <h2 className="display-6 resultTxt">{this.props.resultBriggs}</h2>
        <hr className="my-5" />
        <p className="lead">What does this result mean?</p>
        <ul className="list-group briggs">
          <li className="list-group-item" onClick={this.onISTJ_click}>
            ISTJ
          </li>
          <li className="list-group-item" onClick={this.onISFJ_click}>
            ISFJ
          </li>
          <li className="list-group-item" onClick={this.onINFJ_click}>
            INFJ
          </li>
          <li className="list-group-item" onClick={this.onINTJ_click}>
            INTJ
          </li>
        </ul>
        <ul className="list-group briggs">
          <li className="list-group-item" onClick={this.onISTP_click}>
            ISTP
          </li>
          <li className="list-group-item" onClick={this.onISFP_click}>
            ISFP
          </li>
          <li className="list-group-item" onClick={this.onINFP_click}>
            INFP
          </li>
          <li className="list-group-item" onClick={this.onINTP_click}>
            INTP
          </li>
        </ul>
        <ul className="list-group briggs">
          <li className="list-group-item" onClick={this.onESTP_click}>
            ESTP
          </li>
          <li className="list-group-item" onClick={this.onESFP_click}>
            ESFP
          </li>
          <li className="list-group-item" onClick={this.onENFP_click}>
            ENFP
          </li>
          <li className="list-group-item" onClick={this.onENTP_click}>
            ENTP
          </li>
        </ul>
        <ul className="list-group briggs">
          <li className="list-group-item" onClick={this.onESTJ_click}>
            ESTJ
          </li>
          <li className="list-group-item" onClick={this.onESFJ_click}>
            ESFJ
          </li>
          <li className="list-group-item" onClick={this.onENFJ_click}>
            ENFJ
          </li>
          <li className="list-group-item" onClick={this.onENTJ_click}>
            ENTJ
          </li>
        </ul>
      </Wrapper>
    );
  }
  onISTJ_click() {
    let showISTJ = this.state.showISTJ;
    this.setState({ showISTJ: !showISTJ });
  }
  onISFJ_click() {
    let showISFJ = this.state.showISFJ;
    this.setState({ showISFJ: !showISFJ });
  }
  onINFJ_click() {
    let showINFJ = this.state.showINFJ;
    this.setState({ showINFJ: !showINFJ });
  }
  onINTJ_click() {
    let showINTJ = this.state.showINTJ;
    this.setState({ showINTJ: !showINTJ });
  }
  onISTP_click() {
    let showISTP = this.state.showISTP;
    this.setState({ showISTP: !showISTP });
  }
  onISFP_click() {
    let showISFP = this.state.showISFP;
    this.setState({ showISFP: !showISFP });
  }
  onINFP_click() {
    let showINFP = this.state.showINFP;
    this.setState({ showINFP: !showINFP });
  }
  onINTP_click() {
    let showINTP = this.state.showINTP;
    this.setState({ showINTP: !showINTP });
  }
  onESTP_click() {
    let showESTP = this.state.showESTP;
    this.setState({ showESTP: !showESTP });
  }
  onESFP_click() {
    let showESFP = this.state.showESFP;
    this.setState({ showESFP: !showESFP });
  }
  onENFP_click() {
    let showENFP = this.state.showENFP;
    this.setState({ showENFP: !showENFP });
  }
  onENTP_click() {
    let showENTP = this.state.showENTP;
    this.setState({ showENTP: !showENTP });
  }
  onESTJ_click() {
    let showESTJ = this.state.showESTJ;
    this.setState({ showESTJ: !showESTJ });
  }
  onESFJ_click() {
    let showESFJ = this.state.showESFJ;
    this.setState({ showESFJ: !showESFJ });
  }
  onENFJ_click() {
    let showENFJ = this.state.showENFJ;
    this.setState({ showENFJ: !showENFJ });
  }
  onENTJ_click() {
    let showENTJ = this.state.showENTJ;
    this.setState({ showENTJ: !showENTJ });
  }
}
Briggs.propTypes = {
  resultBriggs: PropTypes.string,
};
export default Briggs;
