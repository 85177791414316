import {
  Box,
  Container,
  CssBaseline,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import Footer from "../Footer/Footer";
import theme from "./Theme";

function createData(name, use, lawful) {
  return { name, use, lawful };
}

const rows = [
  createData(
    "First name, surname, email, phone number",
    "To provide you with access to our Platform and any other information which you request from us and to notify you of changes to our Platform",
    "Contract performance, legitimate interest (to provide all users with updated information in the event the Platform change)"
  ),
  createData(
    "First name, surname, email, phone number",
    "To send automated product communications and re-engagement communications for inactive accounts as part of the Platform",
    "Legitimate interest (to ensure the database is accurate and up to date and communicate with users)"
  ),
  createData(
    "Information we collect about you (as detailed above)",
    "To administer and improve the Platform and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes in a pseudonymised manner. To conduct and facilitate user experience research, including to gauge feedback on new products and features in early stages of development, to determine whether those new products and features would be of sufficient value to users. To ensure the content on our Platform is presented in the most effective manner for you and your computer or mobile device",
    "Contract performance, legitimate interest (to improve the Platform and report internally on usage and gain insight on user basis at a statistical level)"
  ),
  createData(
    "Conversation history and information submitted when interacting with End Users (as applicable). Conversations can also be ‘tagged’ to group conversations under certain topics.",
    "To allow End Users to interact as part of the Platform. To enable the Customer to analyse conversations. Unless an exception applies, the conversation history will not identify the End User.",
    "Contract performance, legitimate interests (to allow the Customer to assess the type of questions being asked and the quality and appropriateness of the responses), (to the extent you provide any sensitive personal data in any free text box, you are consenting to the processing of such sensitive Personal Data)Legitimate interest (to understand the queries End Users are asking and compile more appropriate responses)"
  ),
  createData("Customer Staff and Other Customer End User: ", "", ""),
  createData(
    "First and last name email address, encrypted password; Mobile phone number;  Country; The degree level you are interested in, (undergraduate or postgraduate) and area of study; whether you have already applied to the university; and Chat conversations and interactions. Customers may request additional Personal Data to be processed for use of the Platform.",
    " To enable End Users to   engage with Customers.",
    "Contract performance, consent (to the extent you provide any sensitive personal data in any free text box, you are consenting to the processing of such sensitive Personal Data). Legitimate interest (to ensure the database is accurate and up to date and communicate with users)."
  ),
  createData(
    "First name, surname, email, the degree and level of study you are interested in and your country of origin. Customers may request additional Personal Data to be processed for use of the Platform.",
    "To enable Customers to market products and services that they believe will be of interest to you.",
    "Consent (you have the right to unsubscribe at any time by contacting the Customer)."
  ),
  createData(
    "Coligou Events – Customer staff email and names (presenter only)",
    "To enable the Customer to make use of various webinars created by the Customer as Coligou Event Recordings",
    "Consent (you have the right to request deletion of the Recording by contacting the Customer)"
  ),
];

function createData2(name, usecol) {
  return { name, usecol };
}

const rows2 = [
  createData2(
    "Coligou may share certain Personal Data with Customers and Partners where the applicable third party acts as a Joint Data Controller or Processor. Such Personal Data may include: Personal Data submitted in the creation of a Coligou account (such as name, email, etc); any Personal Data submitted via the Platform, services such as the widget, and Coligou Events, provided that conversation data on the Coligou widget will not normally identify an individual unless justified under exceptional legal grounds.",
    "Contract performance, legitimate interest (to allow our service providers to provide the necessary services)."
  ),
  createData2(
    "We may disclose your Personal Data to our service providers (to assist us in performing any contract we enter into with them or you, including providing the Website and the services it enables), analytics providers, and/or a member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006.",
    "Contract performance, legitimate interest (to allow our service providers to provide the necessary services)."
  ),
  createData2(
    "If Coligou or substantially all of its assets are acquired by a third party, personal information about our customers will be one of the transferred assets.",
    "Legitimate interest (to sell our Company or assets), Consent (for sensitive personal data)."
  ),
  createData2(
    "If we are under a duty to disclose or share your Personal Data in order to comply with any legal obligation or to protect the rights, property, or safety of Coligou, our Customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection. For End Users – in the event you are in a breach of our Acceptable Use Policy or where we are under a lawful obligation to share your Personal Data, we might share it with the university, including conversation history.",
    "Legal obligation."
  ),
  createData2(
    "We may disclose your personal information to third parties, the court service and/or regulators or law enforcement agencies in connection with proceedings or investigations anywhere in the world where compelled to do so. Where permitted, we will direct any such request to you or notify you before responding unless to do so would prejudice the prevention or detection of a crime.",
    "Legal obligation (to cooperate with law enforcement and regulatory authorities)."
  ),
];

function createData3(rights, details) {
  return { rights, details };
}

const rows3 = [
  createData3(
    "Right to access",
    "You have the right to access Personal Data held by the Controller(s), including a description of your data. Personal Data processed can be found in the profile section of your Coligou account, while further information is available on request."
  ),
  createData3(
    "Right to rectification",
    "You have the right to rectify any details to ensure that your personal information is accurate. In order to assist with this, you should notify the Data Controller of any changes to the personal information that you have provided by sending a request to rectify your personal data where you believe the Personal Data collected is inaccurate or incomplete."
  ),
  createData3(
    "Right to erasure/ ‘Right to be forgotten’",
    "Asking the Controller(s) to delete all of your Personal Data will result in the deletion of your personal data held by that Controller without undue delay (unless there is a legitimate and legal reason why the Controller is unable to delete certain Personal Data, in which case you will be informed of this in writing)."
  ),
  createData3(
    "Right to restriction of processing",
    "You have the right to ask the Controller to stop processing your Personal Data at any time in certain circumstances. Such a request will result in the Controller restricting its processing of such data."
  ),
  createData3(
    "Right to data portability",
    "You have the right to request that the Controller provides you with a copy of all of your Personal Data and to transmit your Personal Data to another Data Controller in a structured, commonly used and machine-readable format, where it is technically feasible to do so where such processing is based on consent or pursuant to contractual performance."
  ),
  createData3(
    "Right to complain",
    "You have the right to lodge a complaint to a supervisory authority such as the Information Commissioner’s Office in the UK (see www.ico.org.uk). Although we encourage our customers to engage with us in the event they have any concerns or complaints."
  ),
];

function createData4(rights, details) {
  return { rights, details };
}
const rows4 = [
  createData4(
    "Right to access",
    " You have the right to access personal data held by the Business, including a description of your data. Essential personal data held by the Business can be found in the profile section of your Coligou account, while further information is available on request. The categories of sources from which Personal Data was collected. The business or commercial purpose for collecting your Personal Data. The categories of third parties with whom we have shared your Personal Data. The specific pieces of Personal Data that we have collected about you. If we have disclosed your Personal Data for a business purpose over the past 12 months, we will identify the categories of Personal Data shared with each category of third party recipient."
  ),
  createData4(
    "Right to correct inaccurate information",
    "You have the right to rectify any details to ensure that your personal information is accurate. In order to assist with this, you should notify the Business of any changes to the personal information that you have provided by sending a request to rectify your personal data where you believe the personal data collected is inaccurate or incomplete."
  ),
  createData4(
    "Right of deletion",
    "Asking the Business to delete all of your personal data will result in the deletion of Personal Data held by that Business without undue delay (unless your Personal Data is required to provide you with the Platform or complete a transaction or other action you have requested). If your deletion request is subject to one of these exceptions, your deletion request may be denied, in which case you will be informed of this in writing."
  ),
  createData4(
    "Right to know what data is being sold  or shared and to whom",
    "We do not sell personal information to any third parties."
  ),
  createData4(
    "Right to Opt-out of Sale and data sharing",
    "You have the right to request that the Business does not sell or share your Personal Data to third parties."
  ),
  createData4(
    "Right to limit disclosure and use of sensitive information",
    "You have the right, at any time, to direct the Business that collects sensitive personal information about you to limit its use of your sensitive personal information to that use which is necessary to perform the services or provide the goods reasonably expected by an average consumer who requests such goods or services."
  ),
  createData4(
    "Right of no retaliation",
    "We will not discriminate against you for exercising your rights. We will not deny you our goods or services, charge you different prices or rates, or provide you a lower quality of goods and services if you exercise your rights under the CCPA."
  ),
];

function PrivacyPolicy() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ paddingTop: "150px", pb: 6 }}>
        <Container align="left">
          <Typography variant="h1" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="body1" gutterBottom>
            Last modified: 05 Sep 2022
          </Typography>
          <Typography sx={{ py: 4 }} variant="h2">
            Content:
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            <a href="#section1"> 1. HOW INFORMATION IS PROVIDED TO US</a>
            <br />
            <a href="#section2">2. INFORMATION WE COLLECT ABOUT YOU</a>
            <br />

            <a href="#section3">3. INFORMATION WE RECEIVE FROM OTHER SOURCES</a>
            <br />

            <a href="#section4">
              4. HOW WE USE YOUR INFORMATION AND JUSTIFICATION OF USE
            </a>
            <br />

            <a href="#section5">5. WHERE WE STORE YOUR PERSONAL INFORMATION</a>
            <br />

            <a href="#section6">6. DISCLOSURE OF YOUR INFORMATION</a>
            <br />

            <a href="#section7">7. HOW LONG WE RETAIN YOUR PERSONAL DATA</a>
            <br />

            <a href="#section8">8. YOUR RIGHTS</a>
            <br />

            <a href="#section9">9. COOKIES</a>
            <br />

            <a href="#section10">10. THIRD PARTY WEBSITES</a>
            <br />

            <a href="#section11">11. CHANGES TO THIS POLICY</a>
            <br />

            <a href="#section12">12. CONTACT</a>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Protecting your data, privacy and personal information is very
            important to Unibuddy Limited, registered in England and Wales,
            having its registered office address at 5 New Street Square, London,
            United Kingdom, EC4A 3TW, and registered in the USA, having its
            registered office at Corporation Trust Center, 1209 Orange Street,
            in the City of Wilmington, County of New Castle, Delaware 19801
            (“our”, “us” or “we” or “Coligou”).
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            This Privacy Policy (“Policy“) relates to services provided through
            our website at http://coligou.com/ (our “Website”) or using any of
            the services offered via the Website, such as the Coligou widget on
            a customer website or the Coligou Mobile App (the “Platform”). You
            will be asked to indicate that you acknowledge and consent to the
            practices described in this policy that set out the basis on which
            the Personal Data is collected from you, or that you provide to us
            will be processed by us.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Definitions</b>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b> “End User”</b> means any individual that has access to or
            engages with the Coligou Platform, including Customer Staff.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>“Consumer”</b> means any End User, website visitor or job
            applicant.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>“Controller”</b> has the meaning under the GDPR. Where indicated
            “Controller” also refers to a “Business” under the CCPA.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>“Customer”</b> means an entity that subscribes for a licence to
            use the Coligou Platform.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>“Customer Staff”</b> means any employee or authorised
            representative of the Customer who has access to the Coligou
            Platform.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>“Partner” </b>means a third party that hosts the Coligou widget
            on their website or an entity that has an established commercial
            relationship with Coligou
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b> “Personal Data”</b> means any information that identifies or
            relates to a particular individual and also includes information
            referred to as “personally identifiable information” or “personal
            information” under applicable data privacy laws, rules, or
            regulations (collectively the “Data Protection Laws”).
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b> “Coligou Community”</b> means a feature of the Platform that
            enables End Users, counsellors and Customer Staff to engage in
            communications in an open forum, coordinated by the Customer.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b> “Coligou Events”</b> means a feature of the Platform that
            enables the Customer to stream video content over the internet to
            recipients that have a Coligou account and the ability to host live
            streams with or without multiple chat channels, when applicable,
            stream live events in a conference configuration.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b> “Coligou Events Recording”</b> means a feature of Coligou Event
            that will allow for Customers to record their events livestream and
            live sessions, and download them to share and reuse as they see fit.
            For avoidance of doubt, the recording will only contain the
            presenter/s video and other presentation material when the
            presenter’s screen is shared.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>
              For the purpose of the General Data Protection Regulation (the
              “GDPR”) and the California Consumer Privacy Act of 2018 (the
              “CCPA”):
            </b>
            <br />
            <b>Platform and Website Users</b>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Where you are using your Coligou account or Coligou Platform
            directly, Coligou acts as the “Data Controller” of such personal
            Data (as defined in the <b>GDPR</b>) or “Business” (as defined under
            the
            <b>CCPA</b>) and for some of our products, we act as Joint
            Controller, together with the Customer as described below in the
            section titled
            <b>“2.Information we collect about you”.</b>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Where Customers jointly agree with Coligou to collect additional
            Personal Data in the creation of Coligou accounts, we may act as a
            Joint Controller, together with the Customer. Where this applies,
            this will be specifically agreed with Customers on a case by case
            basis and made clear to End Users.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Where users are accessing the Platform via the Customer, the
            Customer acts as the Data Controller. Coligou shall process personal
            information as a Data Processor on behalf of the Customer, who uses
            our services (see “Information We Receive from Other Sources”
            below). For all respective occasions above we ensure that we comply
            with existing Data Protection Laws.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Job Applicants</b>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            In respect of Personal Data of individuals/consumers collected where
            you apply for a role at Coligou, we will be the Data Controller and
            certain aspects of this Policy will apply to our processing of your
            personal information.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>Minimum User Age </b>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Our Platform is only intended for use by persons above the age of
            consent in their respective jurisdiction. End Users are required to
            submit their date of birth for age verification purposes. Where
            there is a justifiable reason to use the Platform below the relevant
            age of consent, parental consent will be required for such use.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>How to contact us</b>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            If you wish to access an older version of our{" "}
            <a href="/privacypolicy">Privacy Policy</a>, please click here or
            alternatively please contact us{" "}
            <a href="mailto:legal.team@coligou.com">legal.team@coligou.com</a>.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Please contact the Controller/Business party in the first instance
            to address your rights with respect to your data.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            This policy (together with our{" "}
            <a href="/cookiepolicy">Cookie Policy</a>,{" "}
            <a href="/termsofuse">Terms of Use</a> and
            <a href="/usepolicy">Acceptable Use Policy</a> and any other
            documents referred to therein), sets out the basis on which any
            Personal Data we collect from you, or that you provide to us, will
            be processed by Coligou and supplements, where applicable, the
            Customer’s privacy policy.
          </Typography>
          <Typography sx={{ pb: 6 }} variant="body1" gutterBottom>
            Please read this privacy policy carefully to understand the types of
            information we collect from you, how we use that information, the
            circumstances under which we will share it with third parties, and
            your rights in relation to the personal data you provide to us.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            <div id="section1">1. HOW INFORMATION IS PROVIDED TO US</div>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            You will be asked to provide us with your information when you:
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • fill in forms on our Website, Platform, or correspond with us by
            phone, email or otherwise;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • register to use our Platform (creating a Coligou account);
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • use the Platform;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • report a problem with our Platform; or
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • complete any surveys we ask you to fill in (although you do not
            have to respond to these if you do not want to).
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Where you enter any details relating to a Third party, you must have
            obtained clear permission from the individuals whose data you
            provide us with before sharing that data with us.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            For the avoidance of any doubt, any reference in this Privacy Policy
            to your data shall include data about other individuals that you
            have provided us with.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            <div id="section2">2. INFORMATION WE COLLECT ABOUT YOU</div>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Coligou acting as a Data Controller or Joint Controller
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            In respect of all End Users, with regard to each of your visits to
            our Website or use of the Coligou Platform, we may automatically
            collect the following information:
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • device-specific information, such as your hardware model,
            operating system version, unique device identifiers, and mobile
            network information;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • technical information about your computer, including where
            available, your IP address, operating system and browser type, for
            system administration and analytical purposes; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • details of your visits to our Website, including the full Uniform
            Resource Locators (URL) clickstream to, through and from our Website
            (including date and time), length of visits to certain pages, and
            page interaction information (such as scrolling, clicks, and
            mouse-overs), details of whether you are using the Website or the
            Coligou Platform.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Data Controller
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            In respect of End Users of the Platform we collect contact
            information set out below in the table in section 4{" "}
            <b>(HOW WE USE YOUR INFORMATION AND JUSTIFICATION OF USE)</b>. We
            work with our Customers to determine what Personal Data we will
            collect from End Users and how it will be used. We operate as either
            Data Processors of the Personal Data collected where the Customer is
            the Data Controller, or as Joint Controllers with our Customers in
            relation to the Personal Data as described in the next paragraph.
            For other processing activities and to the extent that we determine
            how the Personal Data should be used for the purposes set out in
            section 4, we are the Data Controller of the Personal Data.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Joint Controller
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            In addition, Customers may request and agree with Coligou to collect
            certain additional Personal Data from End Users, in which case
            Coligou acts as a Joint Controller of such Personal Data. Such
            Personal Data may include but is not limited to:
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Additional contact information such as telephone numbers or
            addresses;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • IP address;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Country of origin;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Field of study;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Personal interests;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Education level; or
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Any other relevant Personal Data requested by the Customer;
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Coligou acting as a Processor
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Where Customers provide Personal Data of their End Users, Coligou
            acts as a Processor of such Personal Data. Such Personal Data may
            include but is not limited to:
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Contact information such as telephone numbers or addresses;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • IP address;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Country of origin;
          </Typography>
          <Typography
            sx={{ pb: 1, pl: 3 }}
            variant="body1"
            gutterBottom
          ></Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Field of study;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Personal interests;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Education level;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Any relevant Personal Data collected and provided by the Customer;
            or
          </Typography>{" "}
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Any other Personal Data, including conversation data, voluntarily
            provided by End Users through the use of the Platform services
            including but not limited to the widget, and Coligou Events;
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Types of Information Collected by End User
                  </TableCell>
                  <TableCell align="left">Use Justification</TableCell>
                  <TableCell align="left">Lawful Basis</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.use}</TableCell>
                    <TableCell align="left">{row.lawful}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography sx={{ pb: 1, pt: 6 }} variant="h3" gutterBottom>
            <div id="section3">
              3. INFORMATION WE RECEIVE FROM OTHER SOURCES
            </div>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            When using our Platform, we will be in contact with third parties
            who may provide us with certain information about you in order to
            enable your use of the Platform:
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Customers. Coligou receives the Personal Data as set out below to
            enable Customer’s End Users to access the Coligou Platform.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Service providers. For example, we may use analytics service
            providers to analyze how you interact and engage with the Platform,
            or third parties may help us provide you with customer support. We
            also use customer experience companies who conduct user interviews,
            for example, who may share your personal data with us when we want
            to find out more about your opinion and experience of using our
            services. You will be notified by those third parties before they
            share your personal data with us.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Social networks connected to the services. If you provide your
            social network account credentials to us via the bio field or
            otherwise sign in to the Platform through a third-party site or
            service, you understand some content and/or information in those
            accounts may be transmitted into your Account with us.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Partners. If you sign up to access Coligou through a third party
            partner website, Coligou and/or our partner may indicate that
            certain data will be shared with that third party.
          </Typography>
          <Typography sx={{ pb: 1, pt: 6 }} variant="h3" gutterBottom>
            <div id="section4">
              4. HOW WE USE YOUR INFORMATION AND JUSTIFICATION OF USE{" "}
            </div>
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Use of personal information under certain data protection laws
            must be justified under one of a number of legal “grounds” and the
            Customer is required to set out the ground in respect of each use of
            your Personal Data in their Privacy Policy. These are the principal
            grounds that justify the use of your Personal Data:
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Consent: where you have consented to the use of your information
            (you are providing explicit, informed, freely given consent, in
            relation to any such use and may withdraw your consent in the
            circumstance detailed below by notifying Us or the Customer);
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Contract performance: where your information is necessary to enter
            into or perform a contract with you;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Legal obligation: where we need to use your information to comply
            with legal obligations;
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Legitimate interests: where we use your information to achieve the
            University’s legitimate interest and the reasons for using it
            outweigh any prejudice to your data protection rights; and
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Legal claims: where your information is necessary to defend,
            prosecute or make a claim against you or a third party.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            In respect of the use of the Platform, Coligou (whether acting as
            Controller, Joint Controller or Processor) uses information held
            about you (and information about others that you have provided us
            with) in the following ways:
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>
              We will not sell your personal data (or any other data you provide
              us with) to third-parties; however, we reserve the right to share
              any data which has been anonymised to improve the Platform.{" "}
            </b>
          </Typography>
          <Typography sx={{ pb: 1, pt: 6 }} variant="h3" gutterBottom>
            <div id="section5">5. WHERE WE STORE YOUR PERSONAL INFORMATION</div>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Your Personal Data is maintained, processed and stored by us and our
            authorized Service Providers (defined below) in USA, Europe, UK and
            Israel. We may also retain your Personal Data in other locations as
            reasonably necessary for the proper performance and delivery of our
            Platform, or as may be required by law.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            While privacy laws may vary between jurisdictions, Coligou and its
            affiliates and Service Providers are each committed to protect
            Personal Data in accordance with this Privacy Policy and customary
            industry standards, and such appropriate lawful mechanisms and
            contractual terms requiring adequate data protection, regardless of
            any lesser legal requirements that may apply in their jurisdiction.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Customers based outside the EEA will be subject to local data
            privacy legislation. A full list of our third party Service
            providers/Sub-processors and details of their privacy policies can
            be found here, which will be updated from time to time. By using our
            Platform you consent and agree with the Sub-processors we use above.
            Where we have made changes and have provided you with an updated
            list of our Sub-processors, we will provide you with a notification
            and provide 30 days’ notice to determine whether you agree with the
            changes and continue to use our Platform or alternatively you can
            discontinue using our Platform.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            You acknowledge and accept that countries outside the EEA may not
            provide the same level of adequate protection for the rights and
            freedoms of data subjects in relation to the processing of personal
            data.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Your passwords are stored on Coligou’s servers in encrypted form. We
            do not disclose your account details to anyone except when legally
            required to do so. It is your responsibility to keep your password
            secure. Unfortunately, the transmission of information via the
            internet is not completely secure. Although Coligou will do its best
            to protect your personal data, we cannot guarantee the security of
            your data transmitted to our Website, any transmission is at your
            own risk. Once we have received your information, we will use strict
            procedures and security features to try to prevent any unauthorised
            access.
          </Typography>
          <Typography sx={{ pb: 1, pt: 6 }} variant="h3" gutterBottom>
            <div id="section6">6. DISCLOSURE OF YOUR INFORMATION</div>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            We may also disclose your personal information to third parties in
            the following circumstances:
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Purpose of disclosure and third party(s) to which disclosure
                    might be made{" "}
                  </TableCell>
                  <TableCell align="left">Use Justification</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.usecol}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography sx={{ pb: 1, pt: 6 }} variant="h3" gutterBottom>
            <div id="section7">7. HOW LONG WE RETAIN YOUR PERSONAL DATA </div>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            We will hold the above information for as long as is necessary in
            order to provide you with the ability to use our Platform, deal with
            any specific issues that may arise, or otherwise as is required by
            law or any relevant regulatory body. For End Users for which Coligou
            acts as a Data Controller, you may delete your account at any time,
            following which your Personal Data will be immediately securely
            deleted.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Some Personal Data may need to be retained for longer to ensure
            Coligou can comply with applicable laws and internal compliance
            procedures, including retaining your email address for marketing
            communication suppression if you have opted not to receive any
            further marketing. If information is used for two purposes, we will
            retain it until the purpose with the latest period expires but we
            will stop using it for the purpose with a shorter period when that
            period expires.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            We restrict access to your personal information to those persons who
            need to use it for the relevant purpose(s). Our retention periods
            are based on business needs and your information that is no longer
            needed is either irreversibly anonymized (and the anonymized
            information may be retained) or securely destroyed.
          </Typography>
          <Typography sx={{ pb: 1, pt: 6 }} variant="h3" gutterBottom>
            <div id="section8">8. YOUR RIGHTS</div>
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            <b>• General Data Protection Regulation (GDPR and GDPR UK)</b>
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • Under the General Data Protection Regulation (EU) 2017/676 and
            General Data Protection Regulation (UK) and Data Protection Act
            2018, you have various rights in relation to your Personal Data.{" "}
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            <b>
              • Customer employees and Students should contact the Customer in
              the first instance as their Data Controller.{" "}
            </b>
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            <b>
              • All other End Users should contact Coligou in the first instance
              as their Controller or Joint Controller at{" "}
              <a href="mailto:legal.team@coligou.com">legal.team@coligou.com</a>
              .
            </b>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            The Data Controller, is primarily responsible for ensuring you can
            exercise these rights and the data Processor, shall assist the Data
            Controller to effectively exercise your rights.
          </Typography>
          <Typography sx={{ pb: 1, pl: 3 }} variant="body1" gutterBottom>
            • You have the following rights in relation to your Personal Data.
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Rights</TableCell>
                  <TableCell align="left">Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows3.map((row) => (
                  <TableRow
                    key={row.rights}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.rights}
                    </TableCell>
                    <TableCell align="left">{row.details}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography sx={{ pb: 1, pt: 3 }} variant="body1" gutterBottom>
            Coligou will not ordinarily charge you in respect of any requests we
            receive to exercise any of your rights detailed above; however, if
            you make excessive, repetitive or manifestly unfounded requests, we
            may charge you an administration fee in order to process such
            requests or refuse to act on such requests. Where we are required to
            provide a copy of the Personal Data undergoing processing this will
            be free of charge; however, any further copies requested may be
            subject to reasonable fees based on administrative costs.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Requests to stop processing your Personal Data or deleting your
            Personal Data will likely mean that you are no longer able to use
            Coligou’s Platform, or at least those aspects of the Platform which
            require the processing of the types of Personal Data you have asked
            us to delete, which may result in you no longer being able to use
            the Platform.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Where you exercise any of your rights above, the Controller may
            notify third parties to whom such Personal Data has been disclosed
            of such request. However, such third party may have the right to
            retain and continue to process such personal data in its own right.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            <b>State of California, USA – Resident Rights</b>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            If you are a resident of the State of California, USA, you have the
            rights outlined in this section. Please see the “Exercising Your
            Rights” section below for instructions regarding how to exercise
            these rights. If there are any conflicts between this section and
            any other provision of this Policy and you are a California
            resident, the portion that is more protective of Personal Data shall
            control to the extent of such conflict. If you have any questions
            about this section or whether any of the following applies to you,
            please contact us at{" "}
            <a href="mailto:legal.team@coligou.com">legal.team@coligou.com</a>.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            You have the following rights under the CCPA legislation:
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Rights</TableCell>
                  <TableCell align="left">Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows4.map((row) => (
                  <TableRow
                    key={row.rights}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.rights}
                    </TableCell>
                    <TableCell align="left">{row.details}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography sx={{ pb: 1, pt: 4 }} variant="body1" gutterBottom>
            <i>Subject Access Request/Valid Request</i>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            To exercise the rights described above, you must send us a Subject
            Access Request/Valid Request that (1) provides sufficient
            information to allow us to verify that you are the person about whom
            we have collected Personal Data, and (2) describes your request in
            sufficient detail to allow us to understand, evaluate, and respond
            to it. Each request that meets both of these criteria will be
            considered a “Valid Request.” We may not respond to requests that do
            not meet these criteria. We will only use Personal Data provided in
            a Valid Request to verify you and complete your request. You do not
            need an account to submit a Valid Request.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            We will work to respond to your Valid Request within a 30 day period
            of receipt for all Valid requests under GDPR and 45 days of receipt
            under CPPA. We will not charge you a fee for making a Valid Request
            unless your Valid Request(s) is excessive, repetitive, or manifestly
            unfounded. If we determine that your Valid Request warrants a fee,
            we will notify you of the fee and explain that decision before
            completing your request.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            You may submit a Valid Request using the following methods:
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            By sending an email to{" "}
            <u>
              <a href="mailto:legal.team@coligou.com">legal.team@coligou.com</a>
            </u>
          </Typography>
          <Typography sx={{ pb: 1, pt: 6 }} variant="h3" gutterBottom>
            <div id="section9">9. COOKIES</div>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Coligou and our third-party partners, such as our analytics
            partners, use cookies and other tracking technologies (e.g., web
            beacons, device identifiers and pixels) to provide functionality and
            to recognize you across different Services and devices. For more
            information, please see our{" "}
            <a href="/cookiepolicy">Cookie Policy</a>, which includes
            information on how to control or opt out of these cookies and
            tracking technologies.
          </Typography>
          <Typography sx={{ pb: 1, pt: 6 }} variant="h3" gutterBottom>
            <div id="section10">10. THIRD PARTY WEBSITES</div>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Our Website may, from time to time, contain links to third party
            websites. If you follow a link to any of those third party websites,
            please note that they have their own Privacy Policies and that we do
            not accept any responsibility or liability for their policies or
            processing of your personal information. Please check these policies
            before you submit any personal information to such third party
            websites.
          </Typography>
          <Typography sx={{ pb: 1, pt: 6 }} variant="h3" gutterBottom>
            <div id="section11">11. CHANGES TO THIS POLICY</div>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            Any changes we make to our privacy policy in the future will be
            posted on this page, and where appropriate, notified to you by email
            or notifications via the Coligou Platform. We therefore encourage
            you to review the Website from time to time to stay informed of how
            we are processing your information. Your continued use of the
            Platform and the Website shall be deemed your acceptance of the
            varied Privacy Policy.
          </Typography>
          <Typography sx={{ pb: 1, pt: 6 }} variant="h3" gutterBottom>
            <div id="section12">12. CONTACT</div>
          </Typography>
          <Typography sx={{ pb: 1 }} variant="body1" gutterBottom>
            If you have any questions and comments about this Privacy Policy or
            want to report a potential data breach please address these to
            <a href="mailto:legal.team@coligou.com">legal.team@coligou.com</a>
          </Typography>
        </Container>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default PrivacyPolicy;
