import React, { lazy, Suspense } from "react";
import styles from "./LoadingSpinner.module.css";

const BlogContent = lazy(() => import("../components/Blog/BlogContent"));

function BlogPage() {
  return (
    <Suspense
      fallback={
        <div className={styles.centered}>
          <div className={styles.spinner}></div>
        </div>
      }
    >
      <BlogContent />
    </Suspense>
  );
}

export default BlogPage;
