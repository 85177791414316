import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#7640E7",
    },
  },
  typography: {
    h1: {
      fontFamily: ["CircularStd-book", "serif"].join(","),
      fontSize: "65px",
      color: "white",
      fontStyle: "normal",
      fontWeight: "400",
      textAlign: "center",
    },
    h2: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "40px",
      color: "white",
      fontStyle: "normal",
      fontWeight: "400",
    },
    h3: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "40px",
      color: "#000000",
      fontStyle: "normal",
      fontWeight: "400",
    },
    h4: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "20px",
      color: "white",
      fontStyle: "normal",
      fontWeight: "500",
      textAlign: "left",
    },
    h5: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "20px",
      color: "#A9A8A8",
      fontStyle: "normal",
      fontWeight: "500",
      textAlign: "left",
    },

    body1: {
      color: "#797979",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "450",
      textTransform: "none",
    },
    body2: {
      color: "white",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "700",
      textTransform: "none",
    },
    subtitle1: {
      color: "#404040",
      fontSize: "18px",
      letterSpacing: "0px",
    },
    subtitle2: {
      color: "white",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: "450",
    },

    button: {
      fontFamily: ["CircularStd-book", "serif"].join(","),
      fontSize: "24px",
      color: "white",
      textDecoration: "none",
      fontWeight: "500",
      textTransform: "none",
    },
    fontFamily: ["CircularStd-book", "serif"].join(","),
  },
});

theme = responsiveFontSizes(theme);

export default theme;
