import {
  Box,
  Container,
  CssBaseline,
  Stack,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";
import styles from "./BlogContent.module.css";
import imagerUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import { Fade } from "react-reveal";
import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#7640E7",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            borderRadius: "10px",
            border: "none",
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: ["CircularStd-black", "serif"].join(","),
      fontSize: "60px",
      color: "#000000",
      fontWeight: "900",
      fontStyle: "normal",
    },
    h2: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "40px",
      color: "#000000",
      fontWeight: "900",
      fontStyle: "normal",
      textTransform: "none",
    },
    body1: {
      color: "#000000",
      fontSize: "18px",
      fontWeight: "450",
      fontStyle: "normal",
      textTransform: "none",
    },
    subtitle1: {
      color: "#000000",
      fontSize: "20px",
      fontWeight: "450",
      fontStyle: "normal",
    },
    subtitle2: {
      color: "#797979",
      fontSize: "14px",
      fontWeight: "450",
      fontStyle: "normal",
    },
    fontFamily: ["CircularStd-book", "serif"].join(","),
  },
});

theme = responsiveFontSizes(theme);
const builder = imagerUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}
function SinglePost() {
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();
  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
           title,
           _id,
           slug,
            mainImage{
                asset->{
                    _id,
                    url
                },
            },
            body,
            description,
            tags,
            publishedAt,
            layout,
            "name": author->name,
            "authorImage": author->image
          }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);
  if (!singlePost) return <div>Loading...</div>;

  if (window.innerWidth <= 550) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          className={styles.bgpostmobile}
          sx={{ paddingTop: "40px", paddingBottom: "50px" }}
        >
          <Container>
            <Fade>
              <Container>
                <img
                  src={require("../../assets/Yellow_Vector.svg")}
                  alt="Icon"
                  style={{
                    position: "absolute",
                    marginLeft: "-20px",
                    zIndex: "-1",
                  }}
                />
                <Typography variant="h1" sx={{ pt: 3 }} alignItems="left">
                  {singlePost.title}
                </Typography>
                <Stack
                  sx={{ pt: 2, pb: 2 }}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <img
                    src={urlFor(singlePost.authorImage).url()}
                    alt={singlePost.name}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                  <Stack direction="column">
                    <Typography variant="subtitle1">
                      {singlePost.name}
                    </Typography>
                    <Typography variant="subtitle2" color="#797979">
                      Published at:{" "}
                      {new Date(singlePost.publishedAt).toLocaleDateString()}
                    </Typography>
                  </Stack>
                </Stack>
                {singlePost.tags > 0 &&
                  singlePost.tags.map((post) => (
                    <Typography
                      variant="subtitle2"
                      style={{ display: "inline" }}
                    >
                      #{post}
                      {"  "}
                    </Typography>
                  ))}
              </Container>
            </Fade>
            <Container>
              <Typography sx={{ pt: 3, pb: 3 }} variant="h3" gutterBottom>
                {singlePost.description}
              </Typography>
            </Container>
            <Container align="center">
              <img
                src={singlePost.mainImage.asset.url}
                alt={singlePost.title}
                style={{
                  width: "100%",
                  boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Container>
            <Container sx={{ pt: 5 }}>
              <Fade>
                <BlockContent
                  blocks={singlePost.body}
                  projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                  dataset="production"
                />
              </Fade>
            </Container>
          </Container>
        </Box>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {singlePost.layout === "1" && (
          <Box
            className={styles.bgpost}
            sx={{ paddingTop: "130px", paddingBottom: "130px" }}
          >
            <Container>
              <Fade>
                <Container>
                  <img
                    src={require("../../assets/Yellow_Vector.svg")}
                    alt="Icon"
                    style={{
                      position: "absolute",
                      marginLeft: "-20px",
                      zIndex: "-1",
                    }}
                  />
                  <Typography variant="h1" sx={{ pt: 3 }} alignItems="left">
                    {singlePost.title}
                  </Typography>
                  <Stack
                    sx={{ pt: 2, pb: 2 }}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <img
                      src={urlFor(singlePost.authorImage).url()}
                      alt={singlePost.name}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                    <Stack direction="column">
                      <Typography variant="subtitle1">
                        {singlePost.name}
                      </Typography>
                      <Typography variant="subtitle2" color="#797979">
                        Published at:{" "}
                        {new Date(singlePost.publishedAt).toLocaleDateString()}
                      </Typography>
                    </Stack>
                  </Stack>
                  {singlePost.tags > 0 &&
                    singlePost.tags.map((post) => (
                      <Typography
                        variant="subtitle2"
                        style={{ display: "inline" }}
                      >
                        #{post}
                        {"  "}
                      </Typography>
                    ))}
                </Container>
              </Fade>
              <Container>
                <Typography sx={{ pt: 3, pb: 3 }} variant="h2" gutterBottom>
                  {singlePost.description}
                </Typography>
              </Container>
              <Container align="center">
                <img
                  src={singlePost.mainImage.asset.url}
                  alt={singlePost.title}
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Container>
              <Container sx={{ pt: 5 }}>
                <Fade>
                  <BlockContent
                    blocks={singlePost.body}
                    projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                    dataset="production"
                  />
                </Fade>
              </Container>
            </Container>
          </Box>
        )}
        {singlePost.layout === "2" && (
          <Box
            className={styles.bgpost}
            sx={{ paddingTop: "130px", paddingBottom: "130px" }}
          >
            <Container>
              <Grid container alignItems="center">
                <Grid item lg={6} md={6} sm={12}>
                  <Fade>
                    <Container>
                      <img
                        src={require("../../assets/Yellow_Vector.svg")}
                        alt="Icon"
                        style={{
                          position: "absolute",
                          marginLeft: "-20px",
                          zIndex: "-1",
                        }}
                      />
                      <Typography variant="subtitle2" color="#797979">
                        Published at:{" "}
                        {new Date(singlePost.publishedAt).toLocaleDateString()}
                      </Typography>
                      <Typography variant="h1" sx={{ pt: 2 }} alignItems="left">
                        {singlePost.title}
                      </Typography>
                      <Typography
                        sx={{ pt: 3, pb: 3 }}
                        style={{ fontSize: "24px" }}
                        variant="h2"
                      >
                        {singlePost.description}
                      </Typography>
                      <Stack
                        sx={{ pt: 2, pb: 2 }}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <img
                          src={urlFor(singlePost.authorImage).url()}
                          alt={singlePost.name}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                        <Typography variant="subtitle1">
                          {singlePost.name}
                        </Typography>
                      </Stack>
                      {singlePost.tags > 0 &&
                        singlePost.tags.map((post) => (
                          <Typography
                            variant="subtitle2"
                            style={{ display: "inline" }}
                          >
                            #{post}
                            {"  "}
                          </Typography>
                        ))}
                    </Container>
                  </Fade>
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <img
                    src={singlePost.mainImage.asset.url}
                    alt={singlePost.title}
                    style={{
                      width: "100%",
                      boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                </Grid>
              </Grid>

              <Container sx={{ pt: 5 }}>
                <Fade>
                  <BlockContent
                    blocks={singlePost.body}
                    projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                    dataset="production"
                  />
                </Fade>
              </Container>
            </Container>
          </Box>
        )}
      </ThemeProvider>
    );
  }
}

export default SinglePost;
