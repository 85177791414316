import { createTheme, responsiveFontSizes } from "@mui/material";

let themeMobile = createTheme({
  palette: {
    primary: {
      main: "#7640E7",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#7640E7",
            borderBottom: "4px solid #7640E7",
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: ["CircularStd-black", "serif"].join(","),
      fontSize: "45px",
      color: "#000000",
      fontWeight: "900",
      fontStyle: "normal",
    },
    h2: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "35px",
      color: "#000000",
      fontWeight: "900",
      fontStyle: "normal",
    },
    h3: {
      color: "#000000",
      fontSize: "35px",
      fontWeight: "900",
      fontStyle: "normal",
    },
    h4: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "32px",
      color: "white",
      fontWeight: "900",
      fontStyle: "normal",
    },

    body1: {
      color: "#797979",
      fontSize: "14px",
      fontWeight: "450",
      fontStyle: "normal",
      textTransform: "none",
    },
    body2: {
      color: "white",
      fontSize: "18px",
      fontWeight: "900",
      fontStyle: "normal",
      textTransform: "none",
    },
    subtitle1: {
      color: "#404040",
      fontSize: "16px",
      fontWeight: "450",
      fontStyle: "normal",
    },
    subtitle2: {
      color: "#000000",
      fontSize: "14px",
      fontWeight: "450",
      fontStyle: "normal",
    },

    button: {
      fontFamily: ["CircularStd-book", "serif"].join(","),
      fontSize: "14px",
      color: "white",
      textDecoration: "none",
      fontWeight: "500",
      lineHeight: "20px",
      textTransform: "none",
    },
    fontFamily: ["CircularStd-book", "serif"].join(","),
  },
});

themeMobile = responsiveFontSizes(themeMobile);

export default themeMobile;
