import {
  Button,
  Container,
  CssBaseline,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
  Box,
  Tabs,
  Tab,
  Modal,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import ContactUs from "../ContactForm/ContactForm";
import styles from "./LandingPageContent.module.css";
import theme from "./Theme.js";
import themeMobile from "./ThemeMobile";
import React from "react";
import { Fade } from "react-reveal";
//import FloatingBtn from "../FloatingBtn/FloatingBtn";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import TabPanelContent from "./TabPanel/TabPanelContent";
// import StudentCard from "./StudentCard/StudentCard";
// import BlogCard from "./BlogCard/BlogCard";
// import { Swiper, SwiperSlide } from "swiper/react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function LandingPageContent() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (window.innerWidth <= 550) {
    return (
      <ThemeProvider theme={themeMobile}>
        <CssBaseline />
        <Box className={styles.sectionmobile} sx={{ paddingTop: "40px" }}>
          <Container align="center">
            <Fade>
              <Container align="left">
                <img
                  src={require("../../assets/Yellow_Vector.svg")}
                  alt="Icon"
                  style={{
                    position: "absolute",
                    marginLeft: "-18px",
                    height: "141px",
                    zIndex: "-1",
                  }}
                />
                <Typography variant="h1" align="left">
                  AI-Powered Engagement and Recruitment for{" "}
                  <div style={{ color: "#7640E7", display: "inline" }}>
                    Higher Education
                  </div>
                </Typography>
              </Container>
            </Fade>

            <Container align="center">
              <img
                src={require("../../assets/Coligou_Student_Dashboard_home.jpg")}
                alt="ColigouStudentDashboard"
                style={{
                  marginTop: "20px",
                  width: "311px",
                }}
              />
            </Container>
            <Container sx={{ width: "335px" }}>
              <Typography
                sx={{ paddingTop: "43px" }}
                variant="body1"
                textAlign="left"
              >
                We believe data-driven choices are the foundation for success. 8
                in 10 university-bound students are undecided on a course. We
                think this should change, so we created Coligou.
              </Typography>
              <Fade bottom>
                <Button
                  sx={{ marginTop: "20px" }}
                  style={{
                    width: "100%",
                    height: "43px",
                    borderRadius: "10px",
                  }}
                  variant="contained"
                  onClick={handleOpen}
                >
                  <Typography variant="button">Get Started</Typography>
                </Button>
              </Fade>
            </Container>
          </Container>
          <Container align="center" sx={{ paddingTop: "50px" }}>
            <Fade>
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                color="#7640E7"
                sx={{ width: "313px" }}
              >
                Powering Student Success
              </Typography>
            </Fade>
            <Container
              align="center"
              sx={{ paddingTop: "25px", width: "100%" }}
            >
              <Box sx={{ width: "183px" }}>
                <Tabs
                  orientation="vertical"
                  centered
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  indicatorColor="none"
                >
                  <Tab label="Universities" {...a11yProps(0)} />
                  <Tab label="Students" {...a11yProps(1)} />
                  <Tab label="Schools & Colleges" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Stack direction="column" alignItems="center">
                  <img
                    src={require("../../assets/university-tab.jpg")}
                    alt="ColigouStudentDashboard"
                    style={{
                      width: "245px",
                      marginBottom: "22px",
                      marginTop: "25px",
                      borderRadius: "10px",
                      boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.1)",
                    }}
                  />

                  <Typography
                    component={"span"}
                    variant={"subtitle2"}
                    gutterbottom="true"
                    textAlign="center"
                    sx={{ width: "335px", paddingBottom: "25px" }}
                  >
                    Student on average apply to 5-8 universities, Coligou allows
                    you to be at the forefront using AI and data science. Our
                    engagement and recruitment solutions are built to helps
                    universities increase prospect conversion, boost enrolment
                    and enhance engagement.
                  </Typography>
                  <br />
                  <Button
                    style={{
                      width: "335px",
                      height: "43px",
                      borderRadius: "10px",
                    }}
                    variant="outlined"
                  >
                    <Link to="/uni" className={styles.linktext}>
                      View Products
                    </Link>
                  </Button>
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Stack direction="column" alignItems="center">
                  <img
                    src={require("../../assets/students-tab.jpg")}
                    alt="ColigouStudentDashboard"
                    style={{
                      width: "245px",
                      marginBottom: "22px",
                      marginTop: "25px",
                      borderRadius: "10px",
                      boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                  <Typography
                    component={"span"}
                    variant={"subtitle2"}
                    gutterbottom="true"
                    textAlign="center"
                    sx={{ width: "335px", paddingBottom: "25px" }}
                  >
                    Coligou focuses on guiding you on the path to
                    self-discovery, empowering you with knowledge to make
                    confident decisions. We help in determining what to study,
                    where to study and how to make it happen. Find your best-fit
                    course now.
                  </Typography>
                  <br />
                  <Button
                    style={{
                      width: "335px",
                      height: "43px",
                      borderRadius: "10px",
                    }}
                    variant="outlined"
                  >
                    <Link to="/students" className={styles.linktext}>
                      Explore More
                    </Link>
                  </Button>
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Stack direction="column" alignItems="center">
                  <img
                    src={require("../../assets/schools-tab.jpg")}
                    alt="ColigouStudentDashboard"
                    style={{
                      width: "245px",
                      marginBottom: "22px",
                      marginTop: "25px",
                      borderRadius: "10px",
                      boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                  <Typography
                    component={"span"}
                    variant={"subtitle2"}
                    gutterbottom="true"
                    textAlign="center"
                    sx={{ width: "335px", paddingBottom: "25px" }}
                  >
                    We work with school & colleges to provide young students
                    with guidance, valuable insights and access to information.
                    We help your students prepare well before the university
                    admission process and assist as they transition to
                    university and beyond.
                  </Typography>
                  <br />
                  <Button
                    style={{
                      width: "335px",
                      height: "43px",
                      borderRadius: "10px",
                    }}
                    variant="outlined"
                  >
                    <Link to="/elevate" className={styles.linktext}>
                      View Products
                    </Link>
                  </Button>
                </Stack>
              </TabPanel>
            </Container>
          </Container>
          {/* <Fade bottom>
              <Button
                sx={{ mt: 4, px: 7 }}
                style={{
                  minHeight: "62px",
                  maxWidth: "300px",
                }}
                variant="contained"
              >
                <a
                  className={styles.linktext}
                  href="https://youengage.me/p/621cda47441d41010005f7f9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get started
                </a>
              </Button>
            </Fade> */}
          <Box sx={{ marginTop: "40px", backgroundColor: "#7640E7" }}>
            <Stack
              direction="column"
              alignItems="center"
              sx={{ paddingTop: "30px" }}
            >
              <Fade>
                <Typography
                  sx={{ width: "276px" }}
                  variant="body2"
                  align="center"
                >
                  Engage and Recruit Best-Fit Students Now
                </Typography>
              </Fade>
              <Fade bottom>
                <Button
                  style={{
                    width: "335px",
                    height: "43px",
                    borderRadius: "10px",
                    backgroundColor: "#FFEF00",
                    marginBottom: "29px",
                    marginTop: "15px",
                  }}
                  variant="contained"
                  onClick={handleOpen}
                >
                  <Typography variant="button" color="#000000">
                    Book a Demo
                  </Typography>
                </Button>
              </Fade>
            </Stack>
          </Box>
          <TabPanelContent />
          {/* <Container sx={{ pt: 15, pb: 6, px: 2 }} align="center">
            <Fade>
              <Typography
                className={styles.titlecontainer2}
                variant="h2"
                align="center"
                sx={{ px: 3, mb: 5 }}
                gutterBottom
              >
                What Students Are Saying
              </Typography>
            </Fade>
            <Swiper className="mySwiper" spaceBetween={10} slidesPerView={1}>
              <SwiperSlide>
                <StudentCard />
              </SwiperSlide>
              <SwiperSlide>
                <StudentCard />
              </SwiperSlide>
              <SwiperSlide>
                <StudentCard />
              </SwiperSlide>
            </Swiper>
          </Container> */}
          <Container sx={{ paddingTop: "50px" }} align="center">
            <Fade>
              <Typography
                className={styles.titlecontainer2}
                variant="h2"
                align="center"
                sx={{ paddingBottom: "17px", paddingTop: "5px" }}
              >
                Book a Demo
              </Typography>
            </Fade>

            <img
              src={require("../../assets/Illustration5.svg")}
              alt="illustration"
              style={{ width: "284px", paddingTop: "32px" }}
            />

            <Fade>
              <Typography
                sx={{
                  width: "335px",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
                textAlign="left"
                variant="subtitle1"
              >
                Our technology helps higher education providers engage and
                recruit prospective students more successfully. See how our
                solutions can help your university with a free 30 minute demo.
                We'll demonstrate how our products works and assist you in
                selecting the best solution for your needs.
              </Typography>
            </Fade>
            <Fade bottom>
              <Button
                style={{
                  width: "335px",
                  height: "43px",
                  borderRadius: "10px",
                  marginBottom: "50px",
                }}
                variant="contained"
                onClick={handleOpen}
              >
                <Typography variant="button">See in Action</Typography>
              </Button>
            </Fade>
          </Container>
          {/* <Container sx={{ pt: 15, pb: 6, px: 2 }} align="center">
            <Fade>
              <Typography
                className={styles.titlecontainer2}
                variant="h2"
                align="center"
                sx={{ px: 3, mb: 4 }}
                gutterBottom
              >
                Blogs{" "}
              </Typography>
            </Fade>
            <Swiper className="mySwiper" spaceBetween={10} slidesPerView={1}>
              <SwiperSlide>
                <BlogCard />
              </SwiperSlide>
              <SwiperSlide>
                <BlogCard />
              </SwiperSlide>
              <SwiperSlide>
                <BlogCard />
              </SwiperSlide>
            </Swiper>
          </Container> */}
          <Box sx={{ backgroundColor: "#7640E7" }}>
            <Container sx={{ paddingTop: "50px" }} align="center">
              <Fade>
                <Typography variant="h4" gutterBottom>
                  A Glimpse at Coligou
                </Typography>
                <Typography
                  sx={{ paddingTop: "7px", paddingBottom: "20px" }}
                  variant="body1"
                  color="rgba(255, 255, 255, 1)"
                >
                  See what our team has been up to
                </Typography>
              </Fade>
              <Container align="center">
                <img
                  src={require("../../assets/landingpage_image_mobile.png")}
                  alt="illustration"
                  style={{ width: "278px", paddingBottom: "20px" }}
                />
              </Container>
              <Fade bottom>
                <Button
                  style={{
                    width: "335px",
                    height: "43px",
                    borderRadius: "10px",
                    backgroundColor: "#FFEF00",
                    marginBottom: "15px",
                  }}
                  variant="contained"
                  onClick={handleOpen}
                >
                  <Typography variant="button" color="#000000">
                    Learn More
                  </Typography>
                </Button>
              </Fade>
              <Fade bottom>
                <Stack
                  sx={{ paddingBottom: "50px" }}
                  direction="row"
                  spacing={"10px"}
                  justifyContent="center"
                >
                  <a
                    href="https://www.facebook.com/Coligou/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../assets/instagram.svg")}
                      alt="instagram"
                      style={{
                        width: "32px",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/Coligou/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../assets/facebook.svg")}
                      alt="facebook"
                      style={{
                        width: "32px",
                      }}
                    />{" "}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/coligou/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../assets/linkedin.svg")}
                      alt="linkedin"
                      style={{
                        width: "32px",
                      }}
                    />{" "}
                  </a>
                  {/* <a href="" target="_blank" rel="noopener noreferrer">
                    <img
                      src={require("../../assets/twitter.svg")}
                      alt="twitter"
                    />{" "}
                  </a> */}
                  <a
                    href="https://www.youtube.com/channel/UCWeDCuu6s296EVFEMrB8WDg/featured"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../assets/youtube.svg")}
                      alt="youtube"
                      style={{
                        width: "32px",
                      }}
                    />{" "}
                  </a>
                </Stack>
              </Fade>
            </Container>
          </Box>
        </Box>
        <Modal style={{ overflow: "scroll" }} open={open} onClose={handleClose}>
          <Box sx={{ pt: 8 }} align="center">
            <Container align="center">
              <ContactUs closeHandler={handleClose} />
            </Container>
          </Box>
        </Modal>
        <Footer />
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className={styles.section1} sx={{ paddingTop: "130px" }}>
          <Container>
            <Grid container>
              <Grid item lg={6} md={6} sm={12}>
                <Fade>
                  <Container>
                    <img
                      src={require("../../assets/Yellow_Vector.svg")}
                      alt="Icon"
                      style={{
                        position: "absolute",
                        marginLeft: "-20px",
                        zIndex: "-1",
                      }}
                    />
                    <Typography sx={{ pt: 3 }} variant="h1" align="left">
                      AI-Powered Engagement and Recruitment for{" "}
                      <div style={{ color: "#7640E7", display: "inline" }}>
                        Higher Education
                      </div>
                    </Typography>
                  </Container>
                </Fade>
                <Container>
                  <Fade>
                    <Typography
                      sx={{
                        paddingTop: "14px",
                        width: "551px",
                      }}
                      variant="body1"
                      align="left"
                    >
                      We believe data-driven choices are the foundation for
                      success.
                    </Typography>
                    <Typography
                      sx={{
                        width: "551px",
                      }}
                      variant="body1"
                      align="left"
                    >
                      8 in 10 university-bound students are undecided on a
                      course.
                    </Typography>
                    <Typography
                      sx={{
                        width: "551px",
                        paddingBottom: "20px",
                      }}
                      variant="body1"
                      align="left"
                    >
                      We think this should change, so we created Coligou.
                    </Typography>
                  </Fade>
                  <Fade bottom>
                    <Stack sx={{ alignItems: "left" }}>
                      <Button
                        sx={{ px: 1, py: 2 }}
                        style={{
                          minHeight: "40px",
                          maxWidth: "200px",
                          borderRadius: "10px",
                        }}
                        variant="contained"
                        onClick={handleOpen}
                      >
                        <Typography variant="button">Get Started</Typography>
                      </Button>
                    </Stack>
                  </Fade>
                </Container>
              </Grid>
              <Grid item lg={6} md={6} sm={12} style={{ paddingLeft: "80px" }}>
                <img
                  src={require("../../assets/Coligou_Student_Dashboard_home.jpg")}
                  alt="ColigouStudentDashboard"
                  style={{
                    width: "500px",
                    boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
          <Container sx={{ paddingTop: "97px" }} align="center">
            <Fade>
              <Typography
                sx={{ paddingBottom: "50px" }}
                variant="h2"
                align="center"
                gutterBottom
              >
                Powering Student Success
              </Typography>
            </Fade>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", width: "900px" }}
              >
                <Tabs
                  centered
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  indicatorColor="primary"
                  TabIndicatorProps={{ style: { height: "5px" } }}
                >
                  <Tab
                    sx={{ fontSize: "20px" }}
                    label="Universities"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{
                      marginLeft: "138px",
                      fontSize: "20px",
                    }}
                    label="Students"
                    {...a11yProps(1)}
                  />
                  <Tab
                    sx={{ marginLeft: "138px", fontSize: "20px" }}
                    label="Schools & Colleges"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid container alignItems="center" sx={{ paddingTop: "30px" }}>
                  <Grid item lg={6} md={6} sm={12}>
                    <img
                      src={require("../../assets/university-tab.jpg")}
                      alt="ColigouStudentDashboard"
                      style={{
                        width: "445.9px",
                        height: "273px",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.1)",
                        marginLeft: "100px",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    sx={{ paddingLeft: "80px" }}
                    align="left"
                  >
                    <Typography
                      component={"div"}
                      textAlign="left"
                      variant={"h3"}
                      gutterBottom
                    >
                      For Universities
                    </Typography>
                    <Typography
                      component={"span"}
                      textAlign="left"
                      variant={"subtitle2"}
                      sx={{ width: "548px" }}
                    >
                      Student on average apply to 5-8 universities, Coligou
                      allows you to be at the forefront using AI and data
                      science. Our engagement and recruitment solutions are
                      built to helps universities increase prospect conversion,
                      boost enrolment and enhance engagement.
                    </Typography>
                    <br />
                    <Button
                      sx={{ marginTop: "20px" }}
                      style={{
                        width: "197px",
                        height: "51px",
                      }}
                      variant="outlined"
                    >
                      <Link to="/uni" className={styles.linktext}>
                        View Products
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container alignItems="center" sx={{ paddingTop: "30px" }}>
                  <Grid item lg={6} md={6} sm={12}>
                    <img
                      src={require("../../assets/students-tab.jpg")}
                      alt="ColigouStudentDashboard"
                      style={{
                        width: "445.9px",
                        height: "273px",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.1)",
                        marginLeft: "100px",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    sx={{ paddingLeft: "80px" }}
                    align="left"
                  >
                    <Typography
                      component={"div"}
                      textAlign="left"
                      variant={"h3"}
                      gutterBottom
                    >
                      For Students
                    </Typography>
                    <Typography
                      component={"span"}
                      textAlign="left"
                      variant={"subtitle2"}
                      sx={{ width: "548px" }}
                    >
                      Coligou focuses on guiding you on the path to
                      self-discovery, empowering you with knowledge to make
                      confident decisions. We help in determining what to study,
                      where to study and how to make it happen. Find your
                      best-fit course now.
                    </Typography>
                    <br />
                    <Button
                      sx={{ marginTop: "20px" }}
                      style={{
                        width: "197px",
                        height: "51px",
                      }}
                      variant="outlined"
                    >
                      <Link to="/students" className={styles.linktext}>
                        Explore More
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid container alignItems="center" sx={{ paddingTop: "30px" }}>
                  <Grid item lg={6} md={6} sm={12}>
                    <img
                      src={require("../../assets/schools-tab.jpg")}
                      alt="ColigouStudentDashboard"
                      style={{
                        width: "445.9px",
                        height: "273px",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.1)",
                        marginLeft: "100px",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    sx={{ paddingLeft: "80px" }}
                    align="left"
                  >
                    <Typography
                      component={"div"}
                      textAlign="left"
                      variant={"h3"}
                      gutterBottom
                    >
                      Schools & Colleges
                    </Typography>
                    <Typography
                      component={"span"}
                      textAlign="left"
                      variant={"subtitle2"}
                      sx={{ width: "548px" }}
                    >
                      We work with school & colleges to provide young students
                      with guidance, valuable insights and access to
                      information. We help your students prepare well before the
                      university admission process and assist as they transition
                      to university and beyond.
                    </Typography>
                    <br />
                    <Button
                      sx={{ marginTop: "20px" }}
                      style={{
                        width: "197px",
                        height: "51px",
                      }}
                      variant="outlined"
                    >
                      <Link to="/elevate" className={styles.linktext}>
                        View Products
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </Container>
          {/* <Fade bottom>
              <Button
                sx={{ mt: 4, px: 7 }}
                style={{
                  minHeight: "62px",
                  maxWidth: "300px",
                }}
                variant="contained"
              >
                <a
                  className={styles.linktext}
                  href="https://youengage.me/p/621cda47441d41010005f7f9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get started
                </a>
              </Button>
            </Fade> */}
          <Box sx={{ marginTop: "188px", backgroundColor: "#7640E7" }}>
            <Container
              align="center"
              sx={{ paddingTop: "18px", paddingBottom: "19px" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Fade>
                  <Typography
                    sx={{ width: "390px" }}
                    variant="body2"
                    align="center"
                  >
                    Engage and Recruit Best-Fit Students Now
                  </Typography>
                </Fade>
                <Fade bottom>
                  <Button
                    style={{
                      width: "194px",
                      height: "51px",
                      borderRadius: "10px",
                      backgroundColor: "#FFEF00",
                      marginLeft: "66px",
                    }}
                    variant="contained"
                    onClick={handleOpen}
                  >
                    <Typography variant="button" color="#000000">
                      Book a Demo
                    </Typography>
                  </Button>
                </Fade>
              </Stack>
            </Container>
          </Box>
          <Container sx={{ paddingTop: "70px" }} align="center">
            <TabPanelContent />
          </Container>
          {/* <Container sx={{ pt: 15, pb: 6, px: 2 }} align="center">
            <Fade>
              <Typography
                className={styles.titlecontainer2}
                variant="h2"
                align="center"
                sx={{ px: 3 }}
                gutterBottom
              >
                What Students Are Saying
              </Typography>
            </Fade>
            <Grid container sx={{ pt: 8 }}>
              <Grid item lg={4} md={4} sm={12}>
                <StudentCard />
              </Grid>
              <Grid item lg={4} md={4} sm={12}>
                <StudentCard />
              </Grid>
              <Grid item lg={4} md={4} sm={12}>
                <StudentCard />
              </Grid>
            </Grid>
          </Container> */}
          <Container sx={{ paddingTop: "105px" }} align="center">
            <Fade>
              <Typography
                className={styles.titlecontainer2}
                variant="h2"
                align="center"
                sx={{ paddingTop: "5px", marginBottom: "34px" }}
              >
                Book a Demo
              </Typography>
            </Fade>
            <Grid container alignItems="center">
              <Grid item lg={6} md={6} sm={12}>
                <img
                  src={require("../../assets/Illustration5.svg")}
                  alt="illustration"
                  style={{ width: "517px" }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} sx={{ paddingLeft: "32px" }}>
                <Stack direction="column" alignItems="left">
                  <Fade>
                    <Typography textAlign="left" variant="subtitle1">
                      Our technology helps higher education providers engage and
                      recruit prospective students more successfully. See how
                      our solutions can help your university with a free 30
                      minute demo. We'll demonstrate how our products works and
                      assist you in selecting the best solution for your needs.
                    </Typography>
                  </Fade>
                  <Fade bottom>
                    <Stack sx={{ paddingTop: "25px", alignItems: "left" }}>
                      <Button
                        style={{
                          height: "51px",
                          width: "203px",
                          borderRadius: "10px",
                        }}
                        variant="contained"
                        onClick={handleOpen}
                      >
                        <Typography variant="button">See in Action</Typography>
                      </Button>
                    </Stack>
                  </Fade>
                </Stack>
              </Grid>
            </Grid>
          </Container>
          {/* <Container sx={{ pt: 15, pb: 6, px: 2 }} align="center">
            <Fade>
              <Typography
                className={styles.titlecontainer2}
                variant="h2"
                align="center"
                sx={{ px: 3 }}
                gutterBottom
              >
                Blogs{" "}
              </Typography>
            </Fade>
            <Grid container sx={{ pt: 8 }}>
              <Grid item lg={4} md={4} sm={12}>
                <BlogCard />
              </Grid>
              <Grid item lg={4} md={4} sm={12}>
                <BlogCard />
              </Grid>
              <Grid item lg={4} md={4} sm={12}>
                <BlogCard />
              </Grid>
            </Grid>
          </Container> */}
          <Box sx={{ backgroundColor: "#7640E7", marginTop: "100px" }}>
            <Container
              align="center"
              sx={{ paddingTop: "50px", paddingBottom: "50px" }}
            >
              <Grid container alignItems="center">
                <Grid item lg={6} md={6} sm={12}>
                  <Stack direction="column" alignItems="left">
                    <Fade>
                      <Typography textAlign="left" variant="h4" gutterBottom>
                        A Glimpse at Coligou
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="body1"
                        sx={{
                          fontSize: "16px",
                          color: "rgba(255, 255, 255, 1)",
                          paddingTop: "10px",
                          paddingBottom: "25px",
                        }}
                      >
                        See what our team has been up to
                      </Typography>
                    </Fade>
                    <Fade bottom>
                      <Stack sx={{ alignItems: "left" }}>
                        <Button
                          style={{
                            height: "51px",
                            width: "203px",
                            borderRadius: "10px",
                            backgroundColor: "#FFEF00",
                          }}
                          variant="contained"
                        >
                          <Link
                            to="/aboutus"
                            className={styles.linktext}
                            style={{ color: "#000000" }}
                          >
                            Learn More
                          </Link>
                        </Button>
                      </Stack>
                    </Fade>
                    <Fade bottom>
                      <Stack
                        direction="row"
                        spacing={"12px"}
                        sx={{ marginTop: "25px" }}
                        justifyContent="left"
                      >
                        <a
                          href="https://www.facebook.com/Coligou/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={require("../../assets/instagram.svg")}
                            alt="instagram"
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/Coligou/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={require("../../assets/facebook.svg")}
                            alt="facebook"
                          />{" "}
                        </a>
                        <a
                          href="https://www.linkedin.com/company/coligou/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={require("../../assets/linkedin.svg")}
                            alt="linkedin"
                          />{" "}
                        </a>
                        {/* <a href="" target="_blank" rel="noopener noreferrer">
                        <img
                          src={require("../../assets/twitter.svg")}
                          alt="twitter"
                        />{" "}
                      </a> */}
                        <a
                          href="https://www.youtube.com/channel/UCWeDCuu6s296EVFEMrB8WDg/featured"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={require("../../assets/youtube.svg")}
                            alt="youtube"
                          />{" "}
                        </a>
                      </Stack>
                    </Fade>
                  </Stack>
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <img
                    src={require("../../assets/landingpage_image.svg")}
                    alt="illustration"
                    style={{ maxWidth: "597px", minWidth: "500px" }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Modal style={{ overflow: "scroll" }} open={open} onClose={handleClose}>
          <Box sx={{ pt: 8 }} align="center">
            <Container align="center">
              <ContactUs closeHandler={handleClose} />
            </Container>
          </Box>
        </Modal>
        <Footer />
      </ThemeProvider>
    );
  }
}

export default LandingPageContent;
