import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import BookDemoPage from "./pages/BookDemoPage";
import Layout from "./components/Header/Layout";
import ElevatePage from "./pages/ElevatePage";
import FacebookFormPage from "./pages/FacebookFormPage";
import Landingpage from "./pages/LandingPage";
import UniEngagementPage from "./pages/UniEngagementPage";
import PersonalInfoPage from "./pages/PersonalInfoPage";
import SurveyPage from "./pages/SurveyPage";
import StudentsPage from "./pages/StudentsPage";
import AboutUsPage from "./pages/AboutUsPage";
import ReactGA from "react-ga";
import BlogPage from "./pages/BlogPage";
import SinglePost from "./components/Blog/SinglePost";
import TermsOfUse from "./components/PrivacyPages/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPages/PrivacyPolicy";
import UsePolicy from "./components/PrivacyPages/UsePolicy";
import CookiePolicy from "./components/PrivacyPages/CookiePolicy";
import PlatformLicenceAgree from "./components/PrivacyPages/PlatformLicenceAgree";
import PlansContent from "./components/Plans/PlansContent";

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route path="" element={<Landingpage />} />
          <Route path="/uni" element={<UniEngagementPage />} />
          <Route path="/plans" element={<PlansContent />} />
          <Route path="/elevate" element={<ElevatePage />} />
          <Route path="/students" element={<StudentsPage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/survey" element={<PersonalInfoPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/bookademo" element={<BookDemoPage />} />
          <Route path="/blog/:slug" element={<SinglePost />} />
          <Route path="/survey/start" element={<SurveyPage />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/usepolicy" element={<UsePolicy />} />
          <Route path="/cookiepolicy" element={<CookiePolicy />} />
          <Route
            path="/platform-license-agreement"
            element={<PlatformLicenceAgree />}
          />
          <Route
            path="/utm_source=fb&utm_medium=form&utm_campain=fall+2022&utm_id=find+your+course"
            element={<FacebookFormPage />}
          />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
