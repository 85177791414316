import styles from "./LoadingSpinner.module.css";
import React, { lazy, Suspense } from "react";

const ForStudentsContent = lazy(() =>
  import("../components/Students/ForStudentsContent")
);
function StudentsPage() {
  return (
    <Suspense
      fallback={
        <div className={styles.centered}>
          <div className={styles.spinner}></div>
        </div>
      }
    >
      <ForStudentsContent />
    </Suspense>
  );
}

export default StudentsPage;
