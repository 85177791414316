import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  createTheme,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  responsiveFontSizes,
  Stack,
  TextareaAutosize,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import emailjs from "emailjs-com";
import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
let theme = createTheme({
  palette: {
    primary: {
      main: "#7640E7",
    },
  },
  typography: {
    h1: {
      fontFamily: ["CircularStd-black", "serif"].join(","),
      fontSize: "24px",
      color: "#7640E7",
    },
    button: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "12px",
      color: "white",
    },
    fontFamily: ["CircularStd-book", "serif"].join(","),
  },
});
theme = responsiveFontSizes(theme);

export default function ContactUs(props) {
  const [userType, setUserType] = useState("");

  const handleChangeType = (event) => {
    setUserType(event.target.value);
  };

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6o4h7it",
        "template_023792q",
        e.target,
        "HzU_PScWu-fwNJpUM"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  if (window.innerWidth <= 550) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          component="form"
          onSubmit={sendEmail}
          autoComplete="off"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
        >
          <Card sx={{ maxWidth: "400px", borderRadius: 10 }}>
            <CardContent>
              <Container align="right">
                <Button id="Close" onClick={props.closeHandler}>
                  <HighlightOffIcon color="#7640E7" />
                </Button>
              </Container>

              <Typography sx={{ pt: 2 }} variant="h1">
                Send us a message
              </Typography>

              <Stack
                sx={{ pt: 6 }}
                direction="column"
                spacing={3}
                alignItems="center"
                maxWidth="md"
              >
                <TextField
                  required
                  id="outlined-required"
                  label="Name"
                  name="name"
                />
                <TextField
                  required
                  id="outlined-required"
                  label="Email"
                  type="email"
                  maxwidth="md"
                  name="email"
                />
                <TextField
                  required
                  id="outlined"
                  label="Subject"
                  name="subject"
                />
                <FormLabel id="gender-radio-button">
                  Tell us who you are
                </FormLabel>
                <RadioGroup
                  required
                  value={userType}
                  aria-labelledby="gender-radio-button"
                  name="radio-buttons-group"
                  onChange={handleChangeType}
                >
                  <FormControlLabel
                    value="sudent"
                    control={<Radio />}
                    label="Student"
                  />
                  <FormControlLabel
                    value="school"
                    control={<Radio />}
                    label="School & college"
                  />
                  <FormControlLabel
                    value="university"
                    control={<Radio />}
                    label="University"
                  />
                </RadioGroup>
                <TextareaAutosize
                  name="message"
                  maxRows={20}
                  aria-label="textarea"
                  placeholder="Your Message"
                  style={{
                    width: 300,
                    height: 150,
                    borderRadius: 10,
                    marginLeft: 10,
                    paddingTop: 5,
                  }}
                ></TextareaAutosize>

                <Button
                  sx={{ mt: 5, mx: 2, px: 2, py: 1 }}
                  type="submit"
                  style={{
                    maxWidth: "200px",
                    borderRadius: "10px",
                  }}
                  variant="contained"
                >
                  Send Message
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          component="form"
          onSubmit={sendEmail}
          autoComplete="off"
          sx={{
            "& .MuiTextField-root": { mr: 2 },
          }}
        >
          <Card sx={{ maxWidth: "700px", borderRadius: "10px", px: 4, pb: 6 }}>
            <CardContent>
              <Container align="right" style={{ marginLeft: "30px" }}>
                <Button id="Close" onClick={props.closeHandler}>
                  <HighlightOffIcon color="#7640E7" />
                </Button>
              </Container>

              <Typography sx={{ pb: 2 }} gutterBottom variant="h1">
                Send us a message
              </Typography>

              <Stack
                sx={{ pt: 1 }}
                direction="column"
                spacing={1}
                alignItems="left"
              >
                <Stack direction="row">
                  <TextField
                    required
                    id="outlined-required"
                    label="Name"
                    name="name"
                  />
                  <TextField
                    required
                    id="outlined-required"
                    label="Email"
                    type="email"
                    maxwidth="md"
                    name="email"
                  />
                  <TextField
                    required
                    id="outlined"
                    label="Subject"
                    name="subject"
                  />
                </Stack>
                <FormLabel id="gender-radio-button">
                  <Typography textAlign="left">Tell us who you are</Typography>
                </FormLabel>
                <RadioGroup
                  row
                  required
                  value={userType}
                  aria-labelledby="gender-radio-button"
                  name="radio-buttons-group"
                  onChange={handleChangeType}
                >
                  <FormControlLabel
                    value="sudent"
                    control={<Radio />}
                    label="Student"
                  />
                  <FormControlLabel
                    value="school"
                    control={<Radio />}
                    label="School & college"
                  />
                  <FormControlLabel
                    value="university"
                    control={<Radio />}
                    label="University"
                  />
                </RadioGroup>
                <Stack
                  direction="row"
                  spacing={5}
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <TextareaAutosize
                    name="message"
                    maxRows={20}
                    aria-label="textarea"
                    placeholder="Your Message"
                    style={{
                      width: 300,
                      height: 150,
                      borderRadius: 10,
                      paddingTop: 5,
                    }}
                  />
                  <Button
                    type="submit"
                    style={{
                      width: "200px",
                      height: "52px",
                      borderRadius: "10px",
                      marginRight: "13px",
                    }}
                    variant="contained"
                  >
                    Send Message
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </ThemeProvider>
    );
  }
}
