const fonts = {
  $titleFont: "CircularStd-Bold, sans-serif",
  $mainFont: "CircularStd-book, sans-serif",
  $latoFont: "CircularStd-Black, sans-serif",
};

const colors = {
  $colorBg: "#7E3FF1",
  $colorCardBg: "#DCACFF",
  $colorGold: "white",
};

export { fonts, colors };
