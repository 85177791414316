import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrownDef, GreenDef, BlueDef, RedDef } from "../definitions/ColorsDef";
import { Wrapper } from "../utils/ResultWrapper";
import { NextBtn } from "../utils/Buttons";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

class Colors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBrownDef: false,
      showGreenDef: false,
      showBlueDef: false,
      showRedDef: false,
    };
    this.onBrownDefClick = this.onBrownDefClick.bind(this);
    this.onGreenDefClick = this.onGreenDefClick.bind(this);
    this.onBlueDefClick = this.onBlueDefClick.bind(this);
    this.onRedDefClick = this.onRedDefClick.bind(this);
  }

  renderNextBtn() {
    return (
      <NextBtn onClick={this.props._onNextClick}>
        <span>Next</span>
        <div className="icon" style={{ paddingTop: "6px" }}>
          <ArrowCircleRightIcon />
        </div>
      </NextBtn>
    );
  }

  renderBrownDef() {
    return (
      <BrownDef
        title={"Brown Type Definition"}
        content={`Positive keywords include: down-to-earth, wholesome, practical, approachable, friendly, stable, structured,
                  supportive, comforting, reliable, protective, strength, quietly confident, sensual, sensitive, warm, reassured,
                  honest, sincere, quality.
​
                  Negative keywords include: dull, boring, frugal, materialistic, lack of humor, lack of sophistication, predictable,
                  cheap and stingy`}
        onBackClick={this.onBrownDefClick}
      />
    );
  }

  renderGreenDef() {
    return (
      <GreenDef
        title={"Green Type Definition"}
        content={`Positive keywords include: growth and vitality, renewal and restoration, self-reliance,
                  reliability and dependability, being tactful, emotionally balanced and calm, nature lover and family oriented,
                  practical and down to earth, sympathetic, compassionate and nurturing, generous,
                  kind and loyal with a high moral sense, adaptable, encourages 'social joining' of clubs and other groups,
                  a need to belong.

                  Negative keywords include: being possessive and materialistic, indifferent and over-cautious, envious,
                  selfish, greedy and miserly, devious with money, inconsiderate, inexperienced, a hypochondriac and a do-gooder.`}
        onBackClick={this.onGreenDefClick}
      />
    );
  }

  renderBlueDef() {
    return (
      <BlueDef
        title={"Blue Type Definition"}
        content={`Positive keywords include: loyalty, trust and integrity, tactful, reliability and responsibility,
                  conservatism and perseverance, caring and concern, idealistic and orderly, authority, devotion and contemplation,
                  peaceful and calm.
                  Negative keywords include: being rigid, deceitful and spiteful, depressed and sad, too passive, self-righteous,
                  superstitious and emotionally unstable, too conservative and old-fashioned, predictable and weak, unforgiving,
                  aloof and frigid. It can also indicate manipulation, unfaithfulness and being untrustworthy.`}
        onBackClick={this.onBlueDefClick}
      />
    );
  }

  renderRedDef() {
    return (
      <RedDef
        title={"Red Type Definition"}
        content={`Positive keywords include: action, energy and speed, attention-getting, assertive and confident, energizing,
                  stimulating, exciting, powerful, passionate, stimulating and driven, courageous and strong, spontaneous and determined.

                  Negative keywords include: aggressive and domineering, over-bearing, tiring, angry and quick-tempered, ruthless,
                  fearful and intolerant, rebellious and obstinate, resentful, violent and brutal.`}
        onBackClick={this.onRedDefClick}
      />
    );
  }

  render() {
    let showBrownDef = this.state.showBrownDef;
    let showGreenDef = this.state.showGreenDef;
    let showBlueDef = this.state.showBlueDef;
    let showRedDef = this.state.showRedDef;
    if (showBrownDef) {
      return this.renderBrownDef();
    } else if (showGreenDef) {
      return this.renderGreenDef();
    } else if (showBlueDef) {
      return this.renderBlueDef();
    } else if (showRedDef) {
      return this.renderRedDef();
    }
    return (
      <Wrapper className="jumbotron">
        <h1 className="display-3 title">Colors Result</h1>
        <hr className="my-4" />
        <h2 className="display-6 resultTxt">{this.props.resultColors}</h2>
        <hr className="my-5" />
        <p className="lead">What does this result mean?</p>
        <ul className="list-group">
          <li className="list-group-item" onClick={this.onBrownDefClick}>
            Brown
            <div className="icon">
              <i className="fa fa-arrow-right" />
            </div>
          </li>
          <li className="list-group-item" onClick={this.onGreenDefClick}>
            Green
            <div className="icon">
              <i className="fa fa-arrow-right" />
            </div>
          </li>
          <li className="list-group-item" onClick={this.onBlueDefClick}>
            Blue
            <div className="icon">
              <i className="fa fa-arrow-right" />
            </div>
          </li>
          <li className="list-group-item" onClick={this.onRedDefClick}>
            Red
            <div className="icon">
              <i className="fa fa-arrow-right" />
            </div>
          </li>
        </ul>
        {this.renderNextBtn()}
      </Wrapper>
    );
  }

  onBrownDefClick() {
    let showBrownDef = this.state.showBrownDef;
    this.setState({ showBrownDef: !showBrownDef });
  }

  onGreenDefClick() {
    let showGreenDef = this.state.showGreenDef;
    this.setState({ showGreenDef: !showGreenDef });
  }

  onBlueDefClick() {
    let showBlueDef = this.state.showBlueDef;
    this.setState({ showBlueDef: !showBlueDef });
  }

  onRedDefClick() {
    let showRedDef = this.state.showRedDef;
    this.setState({ showRedDef: !showRedDef });
  }
}

Colors.propTypes = {
  resultColors: PropTypes.string,
  resultBrown: PropTypes.string,
  resultGreen: PropTypes.string,
  resultBlue: PropTypes.string,
  resultRed: PropTypes.string,
};

export default Colors;
