import React, { Component } from "react";
import anime from "animejs";
import SurveyContent from "../components/Survey/SurveyConent";
import QuestionContent from "../components/Survey/QuestionContent";

class SurveyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showQuestion: false,
    };
    this._onStartClick = this._onStartClick.bind(this);
  }

  renderIntro() {
    return <SurveyContent _onStartClick={this._onStartClick} title="Coligou" />;
  }

  renderQuestion() {
    return <QuestionContent />;
  }

  render() {
    let showQuestion = this.state.showQuestion;
    if (showQuestion) {
      return this.renderQuestion();
    }
    return this.renderIntro();
  }

  _onStartClick() {
    this.animateOut();
    setTimeout(
      () => this.setState({ showQuestion: !this.state.showQuestion }),
      1000
    );
  }

  animateOut() {
    setTimeout(
      () =>
        anime({
          targets: ".card",
          translateX: "150%",
          elasticity: function (el, i, l) {
            return 200 + i * 200;
          },
        }),
      200
    );
  }
}

export default SurveyPage;
