import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./Footer.module.css";
import theme from "./Theme.js";
import { Link } from "react-router-dom";

function Footer() {
  if (window.innerWidth <= 550) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ pt: 6, backgroundColor: "#0B0022" }}>
          <Container maxwidth="md" minwidth="sm" sx={{ px: 4 }} align="center">
            <Stack direction="column" spacing={3} justifyContent="left">
              <img
                className={styles.logomobile}
                src={require("../../assets/LOGO.svg")}
                alt="logo"
              />
              <Typography
                sx={{ pt: 2 }}
                variant="body2"
                align="left"
                gutterBottom
              >
                Coligou is a Manchester-based technology company. Our solutions
                use AI technology to unlock insights and drive data-driven
                decision making.
              </Typography>
            </Stack>

            <Stack
              spacing={3}
              sx={{ pt: 3 }}
              direction="column"
              justifyContent="center"
            >
              <Typography variant="h2" textAlign="left" gutterBottom>
                Navigation
              </Typography>
              <Typography variant="body2" textAlign="left" gutterBottom>
                <Link to="/" className={styles.tabs}>
                  Home
                </Link>
              </Typography>

              <Typography variant="body2" textAlign="left" gutterBottom>
                <Link to="/uni" className={styles.tabs}>
                  Universities
                </Link>
              </Typography>
              <Typography variant="body2" textAlign="left" gutterBottom>
                <Link to="/students" className={styles.tabs}>
                  Students
                </Link>
              </Typography>
              <Typography variant="body2" textAlign="left" gutterBottom>
                <Link to="/elevate" className={styles.tabs}>
                  Schools & Colleges
                </Link>
              </Typography>
              <Typography variant="body2" textAlign="left" gutterBottom>
                <Link to="/aboutus" className={styles.tabs}>
                  About Us
                </Link>
              </Typography>
              <Typography variant="body2" textAlign="left" gutterBottom>
                <Link to="/blog" className={styles.tabs}>
                  Blog
                </Link>
              </Typography>
            </Stack>

            <Stack
              spacing={3}
              sx={{ pt: 3, pb: 4 }}
              direction="column"
              justifyContent="center"
            >
              <Typography variant="h2" textAlign="left" gutterBottom>
                Terms of Use
              </Typography>
              <Typography variant="body2" textAlign="left" gutterBottom>
                <Link to="/termsofuse" className={styles.tabs}>
                  Terms of Use
                </Link>
              </Typography>

              <Typography variant="body2" textAlign="left" gutterBottom>
                <Link to="/privacypolicy" className={styles.tabs}>
                  Privacy Policy
                </Link>
              </Typography>
              <Typography variant="body2" textAlign="left" gutterBottom>
                <Link to="/usepolicy" className={styles.tabs}>
                  Acceptable Use Policy
                </Link>
              </Typography>
              <Typography variant="body2" textAlign="left" gutterBottom>
                <Link to="/cookiepolicy" className={styles.tabs}>
                  Cookie Policy
                </Link>
              </Typography>
            </Stack>

            <Stack sx={{ pt: 3, pb: 4 }} direction="column" spacing={3}>
              <Typography variant="h2" textAlign="left" gutterBottom>
                Contacts
              </Typography>
              <Stack direction="row" spacing={2}>
                <img
                  src={require("../../assets/location.svg")}
                  alt="location"
                />
                <Typography variant="body2" align="left" gutterBottom>
                  Manchester, United Kingdom
                </Typography>
              </Stack>
              {/* <Stack direction="row" spacing={2}>
                <img src={require("../../assets/phone.svg")} alt="location" />
                <Typography variant="body2" align="left" gutterBottom>
                  +44 758 500 9234
                </Typography>
              </Stack> */}
              <Stack direction="row" spacing={2}>
                <img
                  src={require("../../assets/location.svg")}
                  alt="location"
                />
                <Typography variant="body2" align="left" gutterBottom>
                  Alexandria, Egypt
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2}>
                <img src={require("../../assets/phone.svg")} alt="location" />
                <Typography variant="body2" align="left" gutterBottom>
                  +44 758 500 9234
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <img src={require("../../assets/mail.svg")} alt="location" />
                <Typography variant="body2" align="left" gutterBottom>
                  hello@coligou.com
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="center"
              maxWidth="md"
            >
              <a
                href="https://www.instagram.com/Coligou/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../assets/instagram.svg")}
                  alt="instagram"
                />{" "}
              </a>
              <a
                href="https://www.facebook.com/Coligou/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../assets/facebook.svg")}
                  alt="facebook"
                />{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/coligou/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../assets/linkedin.svg")}
                  alt="linkedin"
                />{" "}
              </a>
            </Stack>

            <Stack
              direction="column"
              spacing={3}
              sx={{ pt: 4, pb: 4 }}
              style={{
                borderTop: "1px solid rgba(255, 255, 255,0.1)",
              }}
            >
              {/* <Typography variant="body2" textAlign="center">
                Privacy Policy
              </Typography>
              <Typography variant="body2" textAlign="center">
                Terms of Sale
              </Typography> */}
              <Typography variant="body2" textAlign="center">
                © 2022 - Coligou. All rights reserved.{" "}
              </Typography>
            </Stack>
          </Container>
        </Box>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ pt: 6, backgroundColor: "#0B0022" }}>
          <Container maxwidth="md" minwidth="sm" sx={{ px: 2 }} align="center">
            <Grid container spacing={12} justifyContent="center" sx={{ pb: 5 }}>
              <Grid item md={3} sx={{ mt: 2 }}>
                <Stack direction="column" spacing={3} justifyContent="left">
                  <img
                    className={styles.logo}
                    src={require("../../assets/LOGO.svg")}
                    alt="logo"
                  />
                  <Typography
                    sx={{ pt: 2 }}
                    variant="body2"
                    align="left"
                    gutterBottom
                  >
                    Coligou is a Manchester-based technology company. Our
                    solutions use AI technology to unlock insights and drive
                    data-driven decision making.
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={3}
                    justifyContent="left"
                    maxWidth="md"
                  >
                    <a
                      href="https://www.instagram.com/Coligou/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../assets/instagram.svg")}
                        alt="instagram"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/Coligou/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../assets/facebook.svg")}
                        alt="facebook"
                      />{" "}
                    </a>
                    <a
                      href="https://www.linkedin.com/company/coligou/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../assets/linkedin.svg")}
                        alt="linkedin"
                      />{" "}
                    </a>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={3} sx={{ mt: 2 }}>
                <Typography
                  sx={{ ml: 6 }}
                  variant="h2"
                  textAlign="left"
                  gutterBottom
                >
                  Navigation
                </Typography>
                <Stack
                  sx={{ ml: 6, pt: 3 }}
                  direction="column"
                  justifyContent="center"
                >
                  <Typography variant="body2" textAlign="left" gutterBottom>
                    <Link to="/" className={styles.tabs}>
                      Home
                    </Link>
                  </Typography>

                  <Typography variant="body2" textAlign="left" gutterBottom>
                    <Link to="/uni" className={styles.tabs}>
                      Universities
                    </Link>
                  </Typography>
                  <Typography variant="body2" textAlign="left" gutterBottom>
                    <Link to="/students" className={styles.tabs}>
                      Students
                    </Link>
                  </Typography>
                  <Typography variant="body2" textAlign="left" gutterBottom>
                    <Link to="/elevate" className={styles.tabs}>
                      Schools & Colleges
                    </Link>
                  </Typography>
                  <Typography variant="body2" textAlign="left" gutterBottom>
                    <Link to="/aboutus" className={styles.tabs}>
                      About Us
                    </Link>
                  </Typography>
                  <Typography variant="body2" textAlign="left" gutterBottom>
                    <Link to="/blog" className={styles.tabs}>
                      Blog
                    </Link>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={3} sx={{ mt: 2 }}>
                <Typography variant="h2" textAlign="left" gutterBottom>
                  Contacts
                </Typography>
                <Stack sx={{ pt: 3 }} direction="column" spacing={3}>
                  <Stack direction="row" spacing={2}>
                    <img
                      src={require("../../assets/location.svg")}
                      alt="location"
                    />
                    <Typography variant="body2" align="left" gutterBottom>
                      Manchester, United Kingdom
                    </Typography>
                  </Stack>
                  {/* <Stack direction="row" spacing={2}>
                    <img
                      src={require("../../assets/phone.svg")}
                      alt="location"
                    />
                    <Typography variant="body2" align="left" gutterBottom>
                      +44 758 500 9234
                    </Typography>
                  </Stack> */}
                  <Stack direction="row" spacing={2}>
                    <img
                      src={require("../../assets/location.svg")}
                      alt="location"
                    />
                    <Typography variant="body2" align="left" gutterBottom>
                      Alexandria, Egypt
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <img
                      src={require("../../assets/phone.svg")}
                      alt="location"
                    />
                    <Typography variant="body2" align="left" gutterBottom>
                      +44 758 500 9234
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <img
                      src={require("../../assets/mail.svg")}
                      alt="location"
                    />
                    <Typography variant="body2" align="left" gutterBottom>
                      hello@coligou.com
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={3} sx={{ mt: 2 }}>
                <Typography variant="h2" textAlign="left" gutterBottom>
                  Terms of use
                </Typography>
                <Stack
                  sx={{ pt: 3 }}
                  direction="column"
                  justifyContent="center"
                >
                  <Typography variant="body2" textAlign="left" gutterBottom>
                    <Link to="/termsofuse" className={styles.tabs}>
                      Terms of Use
                    </Link>
                  </Typography>

                  <Typography variant="body2" textAlign="left" gutterBottom>
                    <Link to="/privacypolicy" className={styles.tabs}>
                      Privacy Policy
                    </Link>
                  </Typography>
                  <Typography variant="body2" textAlign="left" gutterBottom>
                    <Link to="/usepolicy" className={styles.tabs}>
                      Acceptable Use Policy
                    </Link>
                  </Typography>
                  <Typography variant="body2" textAlign="left" gutterBottom>
                    <Link to="/cookiepolicy" className={styles.tabs}>
                      Cookie Policy
                    </Link>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Container
              align="center"
              sx={{ pt: 4, pb: 4 }}
              style={{
                borderTop: "1px solid rgba(255, 255, 255,0.1)",
              }}
            >
              <Typography variant="body2" textAlign="center">
                © 2022 - Coligou. All rights reserved.{" "}
              </Typography>
            </Container>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }
}

export default Footer;
