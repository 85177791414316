import React from "react";
import styled from "styled-components";
import { StartBtn } from "./utils/Buttons";
import { IntroCard } from "./utils/Cards";
import { fonts, colors } from "./utils/_var";
import { media } from "./utils/_media-queries";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const Wrapper = styled.div`
  position: fixed;
  min-height: 100%;
  max-width: 100%;
  background: ${colors.$colorBg};
  h1 {
    position: relative;
    font-family: ${fonts.$titleFont};
    font-size: 1.1em;
    color: ${colors.$colorGold};
    text-align: center;
    padding-top: 2em;
    ${media.tablet`font-size: 1.5em; letter-spacing: 1.5px;`};
    ${media.laptop`font-size: 2em; letter-spacing: 2px;`};
  }
  .list-group {
    padding: 0 2em;
    .list-group-item {
      background: transparent;
      padding: 1em 1.25em;
      font-family: ${fonts.$latoFont};
      border: 0;
      margin-bottom: 0;
      color: ${colors.$colorGold};
      ${media.tablet`font-size: 1.3em`};
      text-align: center;
    }
  }
`;

const SurveyContent = ({ title, _onStartClick }) => {
  return (
    <Wrapper className="container">
      <IntroCard>
        <div className="corner" />
        <div className="corner" />
        <div className="corner" />
        <div className="corner" />
        <h1>{title}</h1>
        {/* TODO: WILL CHANGE THIS THING DOWN HERE */}
        <ul className="list-group">
          <li className="list-group-item">
            Tell us what are your interests and strengths and we will match you
            with your best-fit careers, occupations, degrees, majors and
            universities in Egypt and abroad.
          </li>{" "}
        </ul>

        <StartBtn onClick={_onStartClick}>
          <span>Start Now</span>
          <div className="icon" style={{ paddingTop: "6px" }}>
            <ArrowCircleRightIcon />
          </div>
        </StartBtn>
      </IntroCard>
    </Wrapper>
  );
};

export default SurveyContent;
