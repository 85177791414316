import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#2A2044",
    },
  },
  typography: {
    h2: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "24px",
      color: "white",
      letterSpacing: "0px",
    },
    body1: {
      color: "#404040",
      fontSize: "24px",
      letterSpacing: "0px",
      textTransform: "none",
    },
    body2: {
      fontFamily: ["CircularStd-book", "serif"].join(","),
      color: "rgba(255, 255, 255,0.6)",
      fontSize: "16px",
      letterSpacing: "0px",
      textTransform: "none",
    },
    button: {
      fontFamily: ["CircularStd-bold", "serif"].join(","),
      fontSize: "18px",
      color: "white",
    },
    fontFamily: ["CircularStd-book", "serif"].join(","),
  },
});

theme = responsiveFontSizes(theme);

export default theme;
