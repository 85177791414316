import {
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import Footer from "../Footer/Footer";
import theme from "./Theme";
function UsePolicy() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ paddingTop: "150px", pb: 6 }}>
        <Container align="left">
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Acceptable Use Policy
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            This acceptable use policy sets out the terms between you and us
            under which you may access our website coligou.com and any Coligou
            widget launched from a website of one of our university partners
            (Coligou). This acceptable use policy applies to all users of, and
            visitors to, Coligou.
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            Your use of Coligou means that you accept, and agree to abide by,
            all the policies in this acceptable use policy, which supplement our
            Terms of Use.
          </Typography>
          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Prohibited uses
          </Typography>
          <Typography variant="body1" gutterBottom>
            You may use Coligou only for lawful purposes. You may not use
            Coligou:
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • In any way that breaches any applicable local, national or
            international law or regulation.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • In any way that is unlawful or fraudulent, or has any unlawful or
            fraudulent purpose or effect.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • For the purpose of harming or attempting to harm minors in any
            way.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • To send, knowingly receive, upload, download, use or re-use any
            material which does not comply with our{" "}
            <a href="#/"> Content Standards</a>.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • To transmit, or procure the sending of, any unsolicited or
            unauthorised advertising or promotional material or any other form
            of similar solicitation (spam).
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • To knowingly transmit any data, send or upload any material that
            contains viruses, Trojan horses, worms, time-bombs, keystroke
            loggers, spyware, adware or any other harmful programs or similar
            computer code designed to adversely affect the operation of any
            computer software or hardware.
          </Typography>
          <Typography variant="body1" gutterBottom>
            You also agree:
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Not to reproduce, duplicate, copy or re-sell any part of Coligou
            in contravention of the provisions of our{" "}
            <a href="/termsofuse">Terms Of Use</a>.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Not to access without authority, interfere with, damage or
            disrupt:
          </Typography>
          <Typography sx={{ pl: 5 }} variant="body1" gutterBottom>
            o any part of Coligou;
          </Typography>
          <Typography sx={{ pl: 5 }} variant="body1" gutterBottom>
            o any equipment or network on which Coligou is stored;
          </Typography>
          <Typography sx={{ pl: 5 }} variant="body1" gutterBottom>
            o any software used in the provision of Coligou; or
          </Typography>
          <Typography sx={{ pb: 4, pl: 5 }} variant="body1" gutterBottom>
            o any equipment or network or software owned or used by any third
            party.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Minimum User Age{" "}
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            Our Platform is only intended for use by persons above the age of
            consent in their respective jurisdiction. End Users are required to
            submit their date of birth for age verification purposes. Where
            there is a justifiable reason to use the Platform below the relevant
            age of consent, parental consent will be required for such use.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Interactive services{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Coligou provides the ability for prospective students, university
            staff, student ambassadors and counsellors to communicate and
            converse with one another. This communication is not moderated by
            us.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We are under no obligation to oversee, monitor or moderate any
            interactive service we provide on Coligou, and we expressly exclude
            our liability for any loss or damage arising from the use of any
            interactive service by a user in contravention of our content
            standards.
          </Typography>

          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            The use of any of our interactive services by a minor is subject to
            the consent of their parent or guardian. We advise parents who
            permit their children to use an interactive service that it is
            important that they communicate with their children about their
            safety online, as moderation is not foolproof. Minors who are using
            any interactive service should be made aware of the potential risks
            to them.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Content standards{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            These content standards apply to any and all material which you
            contribute to Coligou (contributions), and to any communication you
            have with any other user on Coligou.
          </Typography>
          <Typography variant="body1" gutterBottom>
            You must comply with the spirit and the letter of the following
            standards. The standards apply to each part of any contribution as
            well as to its whole.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Contributions must:
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Be accurate (where they state facts).
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Be genuinely held (where they state opinions).
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Comply with applicable law in the UK and in any country from which
            they are posted.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Contributions must not:
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Contain any material which is defamatory of any person.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Contain any material which is obscene, offensive, hateful or
            inflammatory.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Promote sexually explicit material.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Promote violence.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Promote discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Infringe any copyright, database right or trade mark of any other
            person.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Be likely to deceive any person.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Be made in breach of any legal duty owed to a third party, such as
            a contractual duty or a duty of confidence.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Promote any illegal activity.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Be threatening, abuse or invade another’s privacy, or cause
            annoyance, inconvenience or needless anxiety.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Be likely to harass, upset, embarrass, alarm or annoy any other
            person.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Be likely to harass, upset, embarrass, alarm or annoy any other
            person.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Be used to impersonate any person, or to misrepresent your
            identity or affiliation with any person.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Give the impression that they emanate from us, if this is not the
            case.
          </Typography>
          <Typography sx={{ pb: 4, pl: 3 }} variant="body1" gutterBottom>
            • Advocate, promote or assist any unlawful act such as (by way of
            example only) copyright infringement or computer misuse.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Suspension and termination
          </Typography>
          <Typography variant="body1" gutterBottom>
            e will determine, in our discretion, whether there has been a breach
            of this acceptable use policy through your use of Coligou. When a
            breach of this policy has occurred, we may take such action as we
            deem appropriate.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Failure to comply with this acceptable use policy constitutes a
            material breach of the <a href="/termsofuse">Terms Of Use</a> upon
            which you are permitted to use Coligou, and may result in our taking
            all or any of the following actions:
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Immediate, temporary or permanent withdrawal of your right to use
            Coligou.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Immediate, temporary or permanent removal of any posting or
            material uploaded by you to Coligou.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Issue of a warning to you.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Legal proceedings against you for reimbursement of all costs on an
            indemnity basis (including, but not limited to, reasonable
            administrative and legal costs) resulting from the breach.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Further legal action against you.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1" gutterBottom>
            • Disclosure of such information to law enforcement authorities as
            we reasonably feel is necessary.
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            We exclude liability for actions taken in response to breaches of
            this acceptable use policy. The responses described in this policy
            are not limited, and we may take any other action we reasonably deem
            appropriate.
          </Typography>

          <Typography sx={{ pb: 1 }} variant="h3" gutterBottom>
            Changes to the acceptable use policy{" "}
          </Typography>
          <Typography sx={{ pb: 4 }} variant="body1" gutterBottom>
            We may revise this acceptable use policy at any time by amending
            this page. You are expected to check this page from time to time to
            take notice of any changes we make, as they are legally binding on
            you. Some of the provisions contained in this acceptable use policy
            may also be superseded by provisions or notices published elsewhere
            on Coligou.
          </Typography>
        </Container>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default UsePolicy;
