import React, { lazy, Suspense } from "react";
import styles from "./LoadingSpinner.module.css";

const AboutUsContent = lazy(() =>
  import("../components/AboutUs/AboutUsContent")
);

function AboutUsPage() {
  return (
    <Suspense
      fallback={
        <div className={styles.centered}>
          <div className={styles.spinner}></div>
        </div>
      }
    >
      <AboutUsContent />
    </Suspense>
  );
}

export default AboutUsPage;
