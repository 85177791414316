import styles from "./LoadingSpinner.module.css";
import React, { lazy, Suspense } from "react";

const UniEngagement = lazy(() =>
  import("../components/UniEngagement/UniEngagement")
);
function UniEngagementPage() {
  return (
    <Suspense
      fallback={
        <div className={styles.centered}>
          <div className={styles.spinner}></div>
        </div>
      }
    >
      <UniEngagement />
    </Suspense>
  );
}

export default UniEngagementPage;
