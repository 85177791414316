import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Grid,
  Modal,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ContactUs from "../ContactForm/ContactForm.js";
import theme from "./ThemePlan.js";
import React from "react";
import styles from "./PlansContent.module.css";
import { Fade } from "react-reveal";

import Footer from "../Footer/Footer.js";

function PlansContent() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  if (window.innerWidth <= 550) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className={styles.section1mobile}>
          <Box
            className={styles.plansBanner}
            sx={{ paddingTop: "60px", paddingBottom: "41px" }}
          >
            <Stack sx={{ alignItems: "center" }}>
              <Typography
                style={{ textAlign: "center" }}
                color="white"
                variant="h1"
                gutterBottom
              >
                Choose the Best Plan for Your University
              </Typography>
            </Stack>
          </Box>

          <Container align="center" sx={{ paddingTop: "54px" }}>
            <Fade>
              <Typography
                className={styles.titlecontainer2}
                variant="h3"
                align="center"
                sx={{
                  width: "316px",
                  paddingBottom: "17px",
                  paddingTop: "5px",
                }}
              >
                Our Plans{" "}
              </Typography>
            </Fade>
            {/* <Fade>
              <Typography
                sx={{ paddingTop: "25px", paddingBottom: "50px" }}
                variant="body1"
                textAlign="center"
                paragraph
              >
                Every plan starts with a 3 month free trial
              </Typography>
            </Fade> */}

            <Stack
              sx={{
                paddingTop: "50px",
              }}
              align="center"
              spacing={2}
            >
              <Card className={styles.planCard1}>
                <CardContent>
                  <Stack sx={{ alignItems: "center" }}>
                    <Typography color="#7640E7" variant="h2" align="center">
                      STANDARD
                    </Typography>
                    <hr className={styles.line1} />
                  </Stack>

                  <div className={styles.planList}>
                    <Typography color="#7640E7" variant="h4">
                      Course Matcher
                    </Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography color="#7640E7" variant="h4">
                      Eligibility Checker
                    </Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h5">Application Manager</Typography>
                    <img
                      src={require("../../assets/in-active-plan.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h5">Student Connect</Typography>
                    <img
                      src={require("../../assets/in-active-plan.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h5">Events & Webinars</Typography>
                    <img
                      src={require("../../assets/in-active-plan.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h5">Content Hub</Typography>
                    <img
                      src={require("../../assets/in-active-plan.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <Stack sx={{ alignItems: "center" }}>
                    <Button
                      style={{
                        width: "235px",
                        height: "51px",
                        borderRadius: "10px",
                        marginTop: "50px",
                      }}
                      variant="contained"
                      onClick={handleOpen}
                    >
                      <Typography variant="button">Get Started</Typography>
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
              <Card className={styles.planCard2}>
                <CardContent>
                  <Stack sx={{ alignItems: "center" }}>
                    <Typography variant="h2" align="center">
                      PREMIUM
                    </Typography>
                    <hr className={styles.line2} />
                  </Stack>

                  <div className={styles.planList}>
                    <Typography variant="h4">Course Matcher</Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h4">Eligibility Checker</Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h4">Application Manager</Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h4">Student Connect</Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h5">Events & Webinars</Typography>
                    <img
                      src={require("../../assets/in-active-plan.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h5">Content Hub</Typography>
                    <img
                      src={require("../../assets/in-active-plan.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <Stack sx={{ alignItems: "center" }}>
                    <Button
                      style={{
                        width: "235px",
                        height: "51px",
                        borderRadius: "10px",
                        marginTop: "50px",
                        backgroundColor: "white",
                      }}
                      variant="contained"
                      onClick={handleOpen}
                    >
                      <Typography color="#9A71F1" variant="button">
                        Get Started
                      </Typography>
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
              <Card className={styles.planCard3}>
                <CardContent>
                  <Stack sx={{ alignItems: "center" }}>
                    <Typography variant="h2" align="center">
                      PLATINUM
                    </Typography>
                    <hr className={styles.line2} />
                  </Stack>

                  <div className={styles.planList}>
                    <Typography variant="h4">Course Matcher</Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h4">Eligibility Checker</Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h4">Application Manager</Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h4">Student Connect</Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h4">Events & Webinars</Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <div className={styles.planList}>
                    <Typography variant="h4">Content Hub</Typography>
                    <img
                      src={require("../../assets/check.svg")}
                      alt="check"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                  <Stack sx={{ alignItems: "center" }}>
                    <Button
                      style={{
                        width: "235px",
                        height: "51px",
                        borderRadius: "10px",
                        marginTop: "50px",
                        backgroundColor: "white",
                      }}
                      variant="contained"
                      onClick={handleOpen}
                    >
                      <Typography color="#9A71F1" variant="button">
                        Get Started
                      </Typography>
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          </Container>
          <Box sx={{ marginTop: "50px", backgroundColor: "#7640E7" }}>
            <Container align="center" sx={{ paddingTop: "50px" }}>
              <Fade>
                <Typography
                  color={"white"}
                  textAlign="center"
                  variant="h3"
                  gutterBottom
                >
                  Book a Demo
                </Typography>
              </Fade>
              <Stack
                direction="column"
                sx={{ paddingTop: "15px" }}
                justifyContent="center"
              >
                <Container align="center">
                  <img
                    src={require("../../assets/Illustration5.svg")}
                    alt="illustration"
                    style={{
                      maxWidth: "284px",
                    }}
                  />
                </Container>

                <Fade>
                  <Typography
                    sx={{
                      paddingTop: "20px",
                      width: "335px",
                      paddingBottom: "20px",
                    }}
                    variant="body1"
                    color="white"
                    align="center"
                  >
                    See how our solutions can help your university with a free
                    product demo. We'll demonstrate how our products works and
                    assist you in selecting the best solution for your needs.
                  </Typography>
                </Fade>
                <Fade bottom>
                  <Button
                    sx={{ px: 3, py: 1 }}
                    style={{
                      width: "335px",
                      borderRadius: "10px",
                      backgroundColor: "#FFEF00",
                      marginBottom: "50px",
                    }}
                    variant="contained"
                    onClick={handleOpen}
                  >
                    <Typography variant="button" color="#000000">
                      See in Action
                    </Typography>
                  </Button>
                </Fade>
              </Stack>
            </Container>
          </Box>
        </Box>

        <Modal style={{ overflow: "scroll" }} open={open} onClose={handleClose}>
          <Box sx={{ pt: 8 }} align="center">
            <Container align="center">
              <ContactUs closeHandler={handleClose} />
            </Container>
          </Box>
        </Modal>
        <Footer />
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <Box className={styles.section1} sx={{ paddingTop: "130px" }}>
          <Box
            className={styles.plansBanner}
            sx={{ paddingTop: "112px", paddingBottom: "41px" }}
          >
            <Stack sx={{ alignItems: "center" }}>
              <Typography
                sx={{ maxWidth: "680px" }}
                color="white"
                variant="h1"
                gutterBottom
              >
                Choose the Best Plan for Your University
              </Typography>
            </Stack>
          </Box>

          <Container align="center" sx={{ paddingTop: "54px" }}>
            <Fade>
              <Typography
                className={styles.titlecontainer2}
                variant="h3"
                align="center"
                sx={{
                  width: "316px",
                  paddingBottom: "17px",
                  paddingTop: "5px",
                }}
              >
                Our Plans{" "}
              </Typography>
            </Fade>
           

            <Grid
              sx={{
                paddingTop: "50px",
              }}
              align="center"
              container
              spacing={2}
            >
              <Grid item lg={4} md={6} sm={12}>
                <Card className={styles.planCard1}>
                  <CardContent>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography color="#7640E7" variant="h2" align="center">
                        STANDARD
                      </Typography>
                      <hr className={styles.line1} />
                    </Stack>

                    <div className={styles.planList}>
                      <Typography color="#7640E7" variant="h4">
                        Course Matcher
                      </Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography color="#7640E7" variant="h4">
                        Eligibility Checker
                      </Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h5">Application Manager</Typography>
                      <img
                        src={require("../../assets/in-active-plan.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h5">Student Connect</Typography>
                      <img
                        src={require("../../assets/in-active-plan.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h5">Events & Webinars</Typography>
                      <img
                        src={require("../../assets/in-active-plan.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h5">Content Hub</Typography>
                      <img
                        src={require("../../assets/in-active-plan.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <Stack sx={{ alignItems: "center" }}>
                      <Button
                        style={{
                          width: "235px",
                          height: "51px",
                          borderRadius: "10px",
                          marginTop: "50px",
                        }}
                        variant="contained"
                        onClick={handleOpen}
                      >
                        <Typography variant="button">Get Started</Typography>
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <Card className={styles.planCard2}>
                  <CardContent>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="h2" align="center">
                        PREMIUM
                      </Typography>
                      <hr className={styles.line2} />
                    </Stack>

                    <div className={styles.planList}>
                      <Typography variant="h4">Course Matcher</Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h4">Eligibility Checker</Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h4">Application Manager</Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h4">Student Connect</Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h5">Events & Webinars</Typography>
                      <img
                        src={require("../../assets/in-active-plan.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h5">Content Hub</Typography>
                      <img
                        src={require("../../assets/in-active-plan.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <Stack sx={{ alignItems: "center" }}>
                      <Button
                        style={{
                          width: "235px",
                          height: "51px",
                          borderRadius: "10px",
                          marginTop: "50px",
                          backgroundColor: "white",
                        }}
                        variant="contained"
                        onClick={handleOpen}
                      >
                        <Typography color="#9A71F1" variant="button">
                          Get Started
                        </Typography>
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={4} md={6} sm={12}>
                <Card className={styles.planCard3}>
                  <CardContent>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography variant="h2" align="center">
                        PLATINUM
                      </Typography>
                      <hr className={styles.line2} />
                    </Stack>

                    <div className={styles.planList}>
                      <Typography variant="h4">Course Matcher</Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h4">Eligibility Checker</Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h4">Application Manager</Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h4">Student Connect</Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h4">Events & Webinars</Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <div className={styles.planList}>
                      <Typography variant="h4">Content Hub</Typography>
                      <img
                        src={require("../../assets/check.svg")}
                        alt="check"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                    <Stack sx={{ alignItems: "center" }}>
                      <Button
                        style={{
                          width: "235px",
                          height: "51px",
                          borderRadius: "10px",
                          marginTop: "50px",
                          backgroundColor: "white",
                        }}
                        variant="contained"
                        onClick={handleOpen}
                      >
                        <Typography color="#9A71F1" variant="button">
                          Get Started
                        </Typography>
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>

          <Box sx={{ backgroundColor: "#7640E7", marginTop: "100px" }}>
            <Container sx={{ paddingTop: "40px", paddingBottom: "53px" }}>
              <Fade>
                <Stack sx={{ alignItems: "center" }}>
                  <Typography color="white" variant="h2" gutterBottom>
                    Book a Demo
                  </Typography>
                </Stack>
              </Fade>
              <Fade>
                <Grid container spacing={6}>
                  <Grid sx={{ pt: 6 }} item lg={6} md={6} sm={6}>
                    <Container align="center">
                      <img
                        src={require("../../assets/Illustration5.svg")}
                        alt="illustration"
                        style={{
                          maxWidth: "500px",
                          minWidth: "500px",
                        }}
                        className={styles.illustration}
                      />
                    </Container>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Stack direction="column" sx={{ paddingTop: "50px" }}>
                      <Typography
                        sx={{ width: "591px" }}
                        variant="subtitle2"
                        align="left"
                        paragraph
                      >
                        See how our solutions can help your university with a
                        free product demo. We'll demonstrate how our products
                        works and assist you in selecting the best solution for
                        your needs.
                      </Typography>
                      <Button
                        sx={{ px: "46px", py: "14px" }}
                        style={{
                          width: "203px",
                          height: "51px",
                          borderRadius: "10px",
                          backgroundColor: "#FFEF00",
                          marginTop: "22px",
                        }}
                        variant="contained"
                        onClick={handleOpen}
                      >
                        <Typography
                          style={{ fontSize: "14px" }}
                          variant="button"
                          color="#000000"
                        >
                          See in Action
                        </Typography>
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Fade>
            </Container>
          </Box>
        </Box> */}

        <Modal style={{ overflow: "scroll" }} open={open} onClose={handleClose}>
          <Box sx={{ pt: 8 }} align="center">
            <Container align="center">
              <ContactUs closeHandler={handleClose} />
            </Container>
          </Box>
        </Modal>
        <Footer />
      </ThemeProvider>
    );
  }
}
export default PlansContent;
